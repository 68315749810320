import React, { useState, useEffect } from 'react';
import { Input } from '../../../../components/atoms/Input/Input';
import { Button } from '../../../../components/atoms/Button/Button';
import { ImageUploader } from '../../../molecules/ImageUploader/ImageUploader';
import { EditPopup } from '../EditPopup/EditPopup';
import { LessonFormData } from './LessonTypes';
import styles from './Lessons.module.scss';

interface Teacher {
  _id: string;
  name: string;
  surname: string;
  specializations?: string[];
}

interface EditLessonFormProps {
  lessonId: string;
  onSubmit: (lessonId: string, lessonData: LessonFormData) => void;
  onCancel: () => void;
  onDelete: (lessonId: string) => void;
  onCancelLesson: (lessonId: string) => void;
}

export const EditLessonForm: React.FC<EditLessonFormProps> = ({ 
  lessonId, 
  onSubmit, 
  onCancel,
  onDelete,
  onCancelLesson
}) => {
  const [teachers, setTeachers] = useState<Teacher[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingTeachers, setLoadingTeachers] = useState(true);
  const [fetchError, setFetchError] = useState<string | null>(null);
  const [lessonFetchError, setLessonFetchError] = useState<string | null>(null);
  const [errors, setErrors] = useState<Record<string, string | null>>({});
  
  // Состояние для отображения времени в формате ЧЧ:ММ
  const [startTimeInput, setStartTimeInput] = useState('');
  const [endTimeInput, setEndTimeInput] = useState('');
  
  // Состояние для работы с подтверждениями
  const [editState, setEditState] = useState<{
    isOpen: boolean;
    type: 'delete' | 'cancel' | null;
    reason: string;
  }>({
    isOpen: false,
    type: null,
    reason: ''
  });
  
  const [formData, setFormData] = useState<LessonFormData>({
    name: '',
    photo: ['https://placehold.co/200x200?text=Нет+фото'], // Default placeholder
    description: '',
    date: '',
    ageFrom: '',
    ageTo: '',
    startTimeHours: 0,
    startTimeMinutes: 0,
    endTimeHours: 0,
    endTimeMinutes: 0,
    teacherId: '',
    participantsLimit: 8, // Default value
    price: 300, // Default price
    status: 'scheduled'
  });

  // Загрузка данных занятия
  useEffect(() => {
    const fetchLessonData = async () => {
      try {
        setIsLoading(true);
        setLessonFetchError(null);
        
        const response = await fetch(`/api/lessons/admin/lessons/${lessonId}`);
        
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        
        const data = await response.json();
        
        if (data.success) {
          const lesson = data.lesson;
          
          // Преобразуем дату из ISO формата в DD.MM.YYYY
          const dateObj = new Date(lesson.date);
          const formattedDate = `${String(dateObj.getDate()).padStart(2, '0')}.${String(dateObj.getMonth() + 1).padStart(2, '0')}.${dateObj.getFullYear()}`;
          
          // Форматируем время для отображения
          setStartTimeInput(`${String(lesson.startTimeHours).padStart(2, '0')}:${String(lesson.startTimeMinutes).padStart(2, '0')}`);
          setEndTimeInput(`${String(lesson.endTimeHours).padStart(2, '0')}:${String(lesson.endTimeMinutes).padStart(2, '0')}`);
          
          setFormData({
            name: lesson.name,
            photo: lesson.photo,
            description: lesson.description,
            groupId: lesson.groupId,
            date: formattedDate,
            ageFrom: String(lesson.ageFrom),
            ageTo: String(lesson.ageTo),
            startTimeHours: lesson.startTimeHours,
            startTimeMinutes: lesson.startTimeMinutes,
            endTimeHours: lesson.endTimeHours,
            endTimeMinutes: lesson.endTimeMinutes,
            teacherId: lesson.teacherId,
            participantsLimit: lesson.participantsLimit,
            price: lesson.price,
            teacherPrice: lesson.teacherPrice,
            status: lesson.status
          });
        } else {
          throw new Error(data.error || 'Ошибка загрузки данных занятия');
        }
      } catch (error) {
        console.error('Error fetching lesson data:', error);
        setLessonFetchError('Не удалось загрузить данные занятия. Пожалуйста, попробуйте позже.');
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchLessonData();
  }, [lessonId]);

  // Загрузка списка преподавателей при монтировании компонента
  useEffect(() => {
    const fetchTeachers = async () => {
      try {
        setLoadingTeachers(true);
        setFetchError(null);
        
        const response = await fetch('/api/lessons/teachers');
        
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        
        const data = await response.json();
        
        if (data.success) {
          setTeachers(data.teachers);
        } else {
          throw new Error(data.error || 'Не удалось загрузить список преподавателей');
        }
      } catch (error) {
        console.error('Error fetching teachers:', error);
        setFetchError('Не удалось загрузить список преподавателей');
      } finally {
        setLoadingTeachers(false);
      }
    };

    fetchTeachers();
  }, []);

  // Форматирование времени для ввода
  const formatTimeInput = (value: string): string => {
    // Удаляем все нечисловые символы
    const digits = value.replace(/\D/g, '');
    
    // Ограничиваем до 4 цифр
    const truncated = digits.slice(0, 4);
    
    // Если меньше или равно 2 цифрам, просто возвращаем
    if (truncated.length <= 2) {
      return truncated;
    }
    
    // Вставляем двоеточие между часами и минутами
    return `${truncated.slice(0, 2)}:${truncated.slice(2)}`;
  };

  // Обработка ввода времени и установка часов и минут
  const handleTimeInputChange = (field: 'start' | 'end', value: string) => {
    const formattedValue = formatTimeInput(value);
    
    if (field === 'start') {
      setStartTimeInput(formattedValue);
      
      // Обновляем часы и минуты в данных формы
      if (formattedValue.includes(':')) {
        const [hours, minutes] = formattedValue.split(':').map(Number);
        setFormData(prev => ({
          ...prev,
          startTimeHours: hours,
          startTimeMinutes: minutes
        }));
      } else if (formattedValue.length > 0) {
        const hours = parseInt(formattedValue);
        setFormData(prev => ({
          ...prev,
          startTimeHours: hours,
          startTimeMinutes: 0
        }));
      }

      if (errors.startTimeHours || errors.startTimeMinutes) {
        setErrors({
          ...errors,
          startTimeHours: null,
          startTimeMinutes: null
        });
      }
    } else {
      setEndTimeInput(formattedValue);
      
      // Обновляем часы и минуты в данных формы
      if (formattedValue.includes(':')) {
        const [hours, minutes] = formattedValue.split(':').map(Number);
        setFormData(prev => ({
          ...prev,
          endTimeHours: hours,
          endTimeMinutes: minutes
        }));
      } else if (formattedValue.length > 0) {
        const hours = parseInt(formattedValue);
        setFormData(prev => ({
          ...prev,
          endTimeHours: hours,
          endTimeMinutes: 0
        }));
      }

      if (errors.endTimeHours || errors.endTimeMinutes) {
        setErrors({
          ...errors,
          endTimeHours: null,
          endTimeMinutes: null
        });
      }
    }
  };

  // Проверка, что время окончания позже времени начала
  const validateEndTime = (): boolean => {
    const { startTimeHours, startTimeMinutes, endTimeHours, endTimeMinutes } = formData;
    
    const startTotalMinutes = startTimeHours * 60 + startTimeMinutes;
    const endTotalMinutes = endTimeHours * 60 + endTimeMinutes;
    
    // Если время окончания меньше времени начала, считаем что занятие переходит на следующий день
    if (endTotalMinutes < startTotalMinutes) {
      return true;
    }
    
    return endTotalMinutes > startTotalMinutes;
  };

  const handleInputChange = (field: keyof LessonFormData, value: any) => {
    setFormData({ ...formData, [field]: value });
    // Очищаем ошибку при редактировании поля
    if (errors[field]) {
      setErrors({ ...errors, [field]: null });
    }
  };

  // Обработчик загрузки изображения
  const handleImageUpload = (imageUrl: string) => {
    setFormData({
      ...formData,
      photo: [imageUrl]
    });
  };

  const validateForm = (): boolean => {
    const newErrors: Record<string, string | null> = {};
    
    // Валидация названия
    if (!formData.name.trim()) {
      newErrors.name = 'Введите название занятия';
    }
    
    // Валидация описания
    if (!formData.description.trim()) {
      newErrors.description = 'Введите описание занятия';
    }
    
    // Валидация даты
    if (!formData.date) {
      newErrors.date = 'Выберите дату занятия';
    }
    
    // Валидация времени
    if (formData.startTimeHours < 0 || formData.startTimeHours > 23) {
      newErrors.startTimeHours = 'Часы должны быть от 0 до 23';
    }
    
    if (formData.startTimeMinutes < 0 || formData.startTimeMinutes > 59) {
      newErrors.startTimeMinutes = 'Минуты должны быть от 0 до 59';
    }
    
    if (formData.endTimeHours < 0 || formData.endTimeHours > 23) {
      newErrors.endTimeHours = 'Часы должны быть от 0 до 23';
    }
    
    if (formData.endTimeMinutes < 0 || formData.endTimeMinutes > 59) {
      newErrors.endTimeMinutes = 'Минуты должны быть от 0 до 59';
    }
    
    if (!validateEndTime()) {
      newErrors.endTimeHours = 'Время окончания должно быть позже времени начала';
    }
    
    // Валидация возраста - проверяем, что это числа
    if (!formData.ageFrom) {
      newErrors.ageFrom = 'Укажите минимальный возраст';
    } else if (isNaN(parseFloat(formData.ageFrom))) {
      newErrors.ageFrom = 'Возраст должен быть числом';
    } else if (parseFloat(formData.ageFrom) < 0) {
      newErrors.ageFrom = 'Возраст не может быть отрицательным';
    }
    
    if (!formData.ageTo) {
      newErrors.ageTo = 'Укажите максимальный возраст';
    } else if (isNaN(parseFloat(formData.ageTo))) {
      newErrors.ageTo = 'Возраст должен быть числом';
    } else if (parseFloat(formData.ageTo) < parseFloat(formData.ageFrom)) {
      newErrors.ageTo = 'Максимальный возраст должен быть больше минимального';
    }
    
    // Валидация преподавателя
    if (!formData.teacherId) {
      newErrors.teacherId = 'Выберите преподавателя';
    }
    
    // Валидация лимита участников
    if (formData.participantsLimit <= 0) {
      newErrors.participantsLimit = 'Укажите лимит участников';
    }
    
    // Валидация цены
    if (formData.price <= 0) {
      newErrors.price = 'Укажите цену занятия';
    }
    
    setErrors(newErrors);
    return !Object.values(newErrors).some(error => error !== null);
  };

  const handleSubmit = () => {
    if (!validateForm()) {
      return;
    }
    
    setIsLoading(true);
    
    // Форматируем данные перед отправкой
    // Конвертируем дату из формата DD.MM.YYYY в ISO формат для API
    const dateParts = formData.date.split('.');
    if (dateParts.length === 3) {
      const [day, month, year] = dateParts;
      const isoDate = `${year}-${month}-${day}`;
      
      const formattedData = {
        ...formData,
        date: isoDate
      };
      
      onSubmit(lessonId, formattedData);
    } else {
      setErrors({ ...errors, date: 'Неверный формат даты' });
      setIsLoading(false);
    }
  };

  // Обработчики для отмены/удаления урока
  const handleOpenDeleteConfirm = () => {
    setEditState({
      isOpen: true,
      type: 'delete',
      reason: ''
    });
  };

  const handleOpenCancelConfirm = () => {
    setEditState({
      isOpen: true,
      type: 'cancel',
      reason: ''
    });
  };

  const handleClosePopup = () => {
    setEditState({
      isOpen: false,
      type: null,
      reason: ''
    });
  };

  const handleSaveConfirm = (reason: string) => {
    if (editState.type === 'delete') {
      onDelete(lessonId);
    } else if (editState.type === 'cancel') {
      onCancelLesson(lessonId);
    }
    
    handleClosePopup();
  };

  if (lessonFetchError) {
    return (
      <div className={styles.errorContainer}>
        <p className={styles.errorMessage}>{lessonFetchError}</p>
        <Button
          state="secondary"
          label="Вернуться"
          onClick={onCancel}
        />
      </div>
    );
  }

  // Определение заголовка для диалога подтверждения в зависимости от типа операции
  const getConfirmTitle = () => {
    if (editState.type === 'delete') {
      return 'Подтверждение удаления';
    } else if (editState.type === 'cancel') {
      return 'Подтверждение отмены';
    }
    return '';
  };

  return (
    <div className={styles.createLessonForm}>
      <h2 className={styles.formTitle}>Редактирование занятия</h2>
      
      {fetchError && (
        <div className={styles.formError}>
          {fetchError}
          <Button
            state="secondary"
            label="Обновить страницу"
            onClick={() => window.location.reload()}
            className={styles.refreshButton}
          />
        </div>
      )}
      
      <div className={styles.formMainSection}>
          <div className={styles.formRow}>
            <ImageUploader
              onImageUpload={handleImageUpload}
              currentImage={formData.photo[0]}
              className={styles.imageUploader}
            />
          </div>
        
        <div className={styles.formColumn}>
          <h3 className={styles.sectionTitle}>Основная информация</h3>
          
          <div className={styles.formRow}>
            <div className={styles.formColumn}>
              <Input
                type="text"
                value={formData.name}
                onChange={(value) => handleInputChange('name', value)}
                label="Название занятия"
                upperLabel="Название занятия"
                state={errors.name ? 'error' : 'inactive'}
                error={errors.name}
                disabled={isLoading}
              />
            </div>
          </div>

          <div className={styles.formRow}>
            <div className={styles.formColumn}>
              <textarea
                className={styles.textarea}
                value={formData.description}
                onChange={(e) => handleInputChange('description', e.target.value)}
                placeholder="Описание занятия"
                disabled={isLoading}
              />
              {errors.description && (
                <div className={styles.errorMessage}>{errors.description}</div>
              )}
            </div>
          </div>
        </div>
      </div>
      
      <div className={styles.formMainSection}>
        <div className={styles.formHalfColumn}>
          <h3 className={styles.sectionTitle}>Параметры занятия</h3>
          
          <div className={styles.formRow}>
            <div className={styles.formColumn}>
              <Input
                type="date"
                value={formData.date}
                onChange={(value) => handleInputChange('date', value)}
                label="Дата занятия"
                upperLabel="Дата занятия"
                state={errors.date ? 'error' : 'inactive'}
                error={errors.date}
                disabled={isLoading}
              />
            </div>
          </div>

          <div className={styles.formRow}>
            <div className={styles.formColumn}>
              <Input
                type="text"
                value={startTimeInput}
                onChange={(value) => handleTimeInputChange('start', value)}
                label="Начало (ЧЧ:ММ)"
                upperLabel="Время начала"
                state={errors.startTimeHours || errors.startTimeMinutes ? 'error' : 'inactive'}
                error={errors.startTimeHours || errors.startTimeMinutes}
                disabled={isLoading}
              />
            </div>
            <div className={styles.formColumn}>
              <Input
                type="text"
                value={endTimeInput}
                onChange={(value) => handleTimeInputChange('end', value)}
                label="Конец (ЧЧ:ММ)"
                upperLabel="Время окончания"
                state={errors.endTimeHours || errors.endTimeMinutes ? 'error' : 'inactive'}
                error={errors.endTimeHours || errors.endTimeMinutes}
                disabled={isLoading}
              />
            </div>
          </div>

          <div className={styles.formRow}>
            <div className={styles.formColumn}>
              <Input
                type="text"
                value={formData.ageFrom}
                onChange={(value) => handleInputChange('ageFrom', value)}
                label="Возраст от"
                upperLabel="Возраст от"
                state={errors.ageFrom ? 'error' : 'inactive'}
                error={errors.ageFrom}
                disabled={isLoading}
              />
            </div>
            <div className={styles.formColumn}>
              <Input
                type="text"
                value={formData.ageTo}
                onChange={(value) => handleInputChange('ageTo', value)}
                label="Возраст до"
                upperLabel="Возраст до"
                state={errors.ageTo ? 'error' : 'inactive'}
                error={errors.ageTo}
                disabled={isLoading}
              />
            </div>
          </div>
        </div>

        <div className={styles.formHalfColumn}>
          <h3 className={styles.sectionTitle}>Организация</h3>
          
          <div className={styles.formRow}>
            <div className={styles.formColumn}>
              <label className={styles.selectLabel}>Преподаватель</label>
              <select
                className={styles.select}
                value={formData.teacherId}
                onChange={(e) => handleInputChange('teacherId', e.target.value)}
                disabled={isLoading || loadingTeachers}
              >
                <option value="">
                  {loadingTeachers ? 'Загрузка преподавателей...' : 'Выберите преподавателя'}
                </option>
                {teachers.map((teacher) => (
                  <option key={teacher._id} value={teacher._id}>
                    {teacher.surname} {teacher.name} {teacher.specializations?.length ? `(${teacher.specializations.join(', ')})` : ''}
                  </option>
                ))}
              </select>
              {errors.teacherId && (
                <div className={styles.errorMessage}>{errors.teacherId}</div>
              )}
            </div>
          </div>

          <div className={styles.formRow}>
            <div className={styles.formColumn}>
              <Input
                type="text"
                value={formData.participantsLimit.toString()}
                onChange={(value) => handleInputChange('participantsLimit', parseInt(value) || 0)}
                label="Лимит участников"
                upperLabel="Лимит участников"
                state={errors.participantsLimit ? 'error' : 'inactive'}
                error={errors.participantsLimit}
                disabled={isLoading}
              />
            </div>
            <div className={styles.formColumn}>
              <Input
                type="text"
                value={formData.price.toString()}
                onChange={(value) => handleInputChange('price', parseInt(value) || 0)}
                label="Цена занятия (руб.)"
                upperLabel="Цена занятия"
                state={errors.price ? 'error' : 'inactive'}
                error={errors.price}
                disabled={isLoading}
              />
            </div>
          </div>

          <div className={styles.formRow}>
            <div className={styles.formColumn}>
              <Input
                type="text"
                value={formData.teacherPrice?.toString() || ''}
                onChange={(value) => handleInputChange('teacherPrice', parseInt(value) || 0)}
                label="Выплата преподавателю (руб.)"
                upperLabel="Выплата преподавателю"
                disabled={isLoading}
              />
            </div>
            <div className={styles.formColumn}>
              <label className={styles.selectLabel}>Статус</label>
              <select
                className={styles.select}
                value={formData.status}
                onChange={(e) => handleInputChange('status', e.target.value)}
                disabled={isLoading}
              >
                <option value="scheduled">Запланировано</option>
                <option value="completed">Завершено</option>
                <option value="cancelled">Отменено</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.formButtons}>
        <div className={styles.leftButtons}>
          <Button
            state="agressive"
            label="Удалить"
            onClick={handleOpenDeleteConfirm}
            disabled={isLoading}
          />
          <Button
            state="agressive"
            label="Отменить занятие"
            onClick={handleOpenCancelConfirm}
            disabled={isLoading || formData.status === 'cancelled'}
          />
        </div>
        <div className={styles.rightButtons}>
          <Button
            state="secondary"
            label="Вернуться назад"
            onClick={onCancel}
            disabled={isLoading}
          />
          <Button
            state="active"
            label={isLoading ? "Сохранение..." : "Сохранить"}
            onClick={handleSubmit}
            disabled={isLoading}
          />
        </div>
      </div>

      {/* Диалог подтверждения для удаления или отмены урока */}
      <EditPopup
        title={getConfirmTitle()}
        isOpen={editState.isOpen}
        onClose={handleClosePopup}
        onSave={handleSaveConfirm}
      >
        <div>
          {editState.type === 'delete' && (
            <p>
              Вы собираетесь полностью удалить занятие из системы. Это действие необратимо.
              Все данные об этом занятии будут потеряны.
            </p>
          )}
          {editState.type === 'cancel' && (
            <p>
              Вы собираетесь отменить это занятие. Отмененное занятие останется в системе,
              но не будет проводиться. Статус занятия изменится на "Отменено".
            </p>
          )}
        </div>
      </EditPopup>
    </div>
  );
};