import React from 'react';
import { AddChild } from '../../roles/parent/AddChild';
import { ChildContent } from '../../roles/parent/ChildContent/ChildIndividualContent';
import { History } from '../../roles/parent/History';

interface ParentContentProps {
  activeTab: string;
  children: Array<{id: string; name: string}>;
}

export const ParentContent: React.FC<ParentContentProps> = ({ activeTab, children }) => {
  // If no children and not on add-child tab, redirect to add-child
  if (children.length === 0 && activeTab !== 'add-child' && activeTab !== 'history') {
    return <AddChild />;
  }
  
  // If activeTab matches a child ID, show that child's content
  const selectedChild = children.find(child => child.id === activeTab);
  
  if (selectedChild) {
    return <ChildContent childId={activeTab} />;
  }

  // Otherwise, show the appropriate tab content
  switch (activeTab) {
    case 'add-child':
      return <AddChild />;
    case 'history':
      return <History />;
    default:
      // If no tab matches but we have children, show the first child
      if (children.length > 0) {
        return <ChildContent childId={children[0].id} />;
      }
      
      // Fallback to AddChild if nothing else matches
      return <AddChild />;
  }
};