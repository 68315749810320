import React from 'react';
import classNames from 'classnames';
import styles from './Switch.module.scss';

interface Option {
  id: string | number;
  label: string;
}

interface SwitchProps {
  options: Option[];
  value: string | number;
  onChange: (value: string | number) => void;
  className?: string;
  disabled?: boolean;
}

export const Switch: React.FC<SwitchProps> = ({
  options,
  value,
  onChange,
  className,
  disabled = false
}) => {
  if (options.length < 2) {
    console.warn('Switch component requires at least 2 options');
    return null;
  }

  const handleClick = (optionId: string | number) => {
    if (!disabled && optionId !== value) {
      onChange(optionId);
    }
  };

  const activeIndex = options.findIndex(option => option.id === value);

  return (
    <div 
      className={classNames(
        styles.switch,
        { [styles.disabled]: disabled },
        className
      )}
    >
      <div 
        className={styles.slider} 
        style={{ 
          transform: `translateX(${activeIndex * 100}%)`,
          width: `${100 / options.length}%`
        }}
      />
      
      {options.map((option) => (
        <button
          key={option.id}
          className={classNames(
            styles.option,
            { [styles.active]: option.id === value }
          )}
          onClick={() => handleClick(option.id)}
          disabled={disabled}
          type="button"
          style={{ width: `${100 / options.length}%` }}
        >
          {option.label}
        </button>
      ))}
    </div>
  );
};