import React from 'react';
import { Button } from '../../../atoms/Button/Button';
import styles from './ChildContent.module.scss';

interface LessonData {
  _id: string;
  name: string;
  photo: string[];
  description: string;
  date: string;
  ageFrom: string | number;
  ageTo: string | number;
  startTimeHours: number;
  startTimeMinutes: number;
  endTimeHours: number;
  endTimeMinutes: number;
  teacherId: string;
  participantsLimit: number;
  price: number;
  teacherPrice?: number;
  studentsIds?: Record<string, any>;
  status: string;
  teacher?: {
    name: string;
    surname: string;
  };
}

interface LessonDetailViewProps {
  lesson: LessonData;
  onBack: () => void;
  onRegister: () => void;
}

export const LessonDetailView: React.FC<LessonDetailViewProps> = ({ 
  lesson, 
  onBack, 
  onRegister 
}) => {
  // Format date as "DD Month YYYY"
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('ru-RU', {
      day: '2-digit',
      month: 'long',
      year: 'numeric'
    });
  };
  
  // Format time as "HH:MM"
  const formatTime = (hours: number, minutes: number) => {
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
  };

  // Calculate available spots
  const enrolledStudents = lesson.studentsIds ? Object.keys(lesson.studentsIds).length : 0;
  const availableSpots = lesson.participantsLimit - enrolledStudents;

  return (
    <div className={styles.lessonDetailCard}>
      <h2 className={styles.lessonTitle}>{lesson.name}</h2>
      
      <div className={styles.lessonImageContainer}>
        {lesson.photo && lesson.photo.length > 0 ? (
          <img src={lesson.photo[0]} alt={lesson.name} className={styles.lessonImage} />
        ) : (
          <div className={styles.imagePlaceholder}>Нет фото</div>
        )}
      </div>
      
      <div className={styles.lessonDetailsGrid}>
        <div className={styles.detailItem}>
          <p className={styles.detailLabel}>Дата:</p>
          <p className={styles.detailValue}>{formatDate(lesson.date)}</p>
        </div>
        
        <div className={styles.detailItem}>
          <p className={styles.detailLabel}>Время:</p>
          <p className={styles.detailValue}>
            {formatTime(lesson.startTimeHours, lesson.startTimeMinutes)} - 
            {formatTime(lesson.endTimeHours, lesson.endTimeMinutes)}
          </p>
        </div>
        
        <div className={styles.detailItem}>
          <p className={styles.detailLabel}>Возраст:</p>
          <p className={styles.detailValue}>{lesson.ageFrom} - {lesson.ageTo} лет</p>
        </div>
        
        <div className={styles.detailItem}>
          <p className={styles.detailLabel}>Преподаватель:</p>
          <p className={styles.detailValue}>
            {lesson.teacher ? 
              `${lesson.teacher.surname} ${lesson.teacher.name}` : 
              'Не указан'}
          </p>
        </div>
        
        <div className={styles.detailItem}>
          <p className={styles.detailLabel}>Цена:</p>
          <p className={styles.detailValue}>{lesson.price}₽</p>
        </div>
        
        <div className={styles.detailItem}>
          <p className={styles.detailLabel}>Свободных мест:</p>
          <p className={styles.detailValue}>
            {availableSpots} из {lesson.participantsLimit}
          </p>
        </div>
      </div>
      
      <div className={styles.lessonDescription}>
        <h3>Описание:</h3>
        <p>{lesson.description}</p>
      </div>
      
      <div className={styles.actionButtons}>
        <Button 
          state="secondary"
          label="Вернуться назад"
          onClick={onBack}
        />
        <Button 
          state="active"
          label="Записаться"
          onClick={onRegister}
        />
      </div>
    </div>
  );
};