import React, { useState, useEffect, useCallback } from 'react';
import { Button } from '../../../atoms/Button/Button';
import { LessonCard } from '../../../molecules/LessonCard/LessonCard';
import { LessonDetailView } from './LessonDetailView';
import styles from './ChildContent.module.scss';
import { useNavigate } from 'react-router-dom';

interface ChildData {
  _id: string;
  firstName: string;
  lastName: string;
  birthDate: string;
  gender: string;
  phoneNumber?: string;
  address: string;
  school?: string;
  photoPermission: boolean;
  photoPostingPermission: string;
  independentVisitPermission: boolean;
  medicalConditions?: string[];
  allergies?: string[];
  additionalInfo?: string;
  status: string;
}

interface LessonData {
  _id: string;
  name: string;
  photo: string[];
  description: string;
  groupId?: string;
  date: string;
  ageFrom: string | number;
  ageTo: string | number;
  startTime?: string;
  endTime?: string;
  startTimeHours: number;
  startTimeMinutes: number;
  endTimeHours: number;
  endTimeMinutes: number;
  teacherId: string;
  participantsLimit: number;
  price: number;
  teacherPrice?: number;
  studentsIds?: Record<string, any>;
  status: string;
  teacher?: {
    name: string;
    surname: string;
  };
}

interface ChildContentProps {
  childId: string;
}

export const ChildContent: React.FC<ChildContentProps> = ({ childId }) => {
  const navigate = useNavigate();
  const [child, setChild] = useState<ChildData | null>(null);
  const [lessons, setLessons] = useState<LessonData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLessonsLoading, setIsLessonsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  // Removed expanded state since we're removing ChildInfo
  const [selectedLessonId, setSelectedLessonId] = useState<string | null>(null);
  const [selectedLesson, setSelectedLesson] = useState<LessonData | null>(null);

  const fetchChildData = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    
    try {
      const response = await fetch(`/api/lessons/children/details/${childId}`);
      const data = await response.json();
      
      if (data.success) {
        setChild(data.child);
      } else {
        setError(data.error || 'Не удалось загрузить данные ребенка');
      }
    } catch (err) {
      console.error('Error fetching child data:', err);
      setError('Не удалось загрузить данные ребенка');
    } finally {
      setIsLoading(false);
    }
  }, [childId]);
  
  // Fetch lessons suitable for the child based on age
  const fetchLessons = useCallback(async () => {
    if (!child) return;
    
    setIsLessonsLoading(true);
    
    try {
      const birthDate = new Date(child.birthDate);
      const today = new Date();
      
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      
      // Fetch lessons that match the child's age
      const response = await fetch('/api/lessons/admin/lessons?status=scheduled');
      const data = await response.json();
      
      if (data.success) {
        // Filter lessons by age and ensure they're in the future
        const now = new Date();
        const filteredLessons = data.lessons.filter((lesson: LessonData) => {
          const lessonDate = new Date(lesson.date);
          return (
            lessonDate > now && 
            age >= parseInt(String(lesson.ageFrom)) && 
            age <= parseInt(String(lesson.ageTo))
          );
        });
        
        // Sort by date
        filteredLessons.sort((a: LessonData, b: LessonData) => {
          return new Date(a.date).getTime() - new Date(b.date).getTime();
        });
        
        setLessons(filteredLessons);
      }
    } catch (err) {
      console.error('Error fetching lessons:', err);
    } finally {
      setIsLessonsLoading(false);
    }
  }, [child]);

  useEffect(() => {
    fetchChildData();
  }, [fetchChildData]);

  useEffect(() => {
    if (child) {
      fetchLessons();
    }
  }, [child, fetchLessons]);
  
  // Fetch detailed lesson info when a lesson is selected
  const fetchLessonDetails = async (lessonId: string) => {
    try {
      const response = await fetch(`/api/lessons/admin/lessons/${lessonId}`);
      const data = await response.json();
      
      if (data.success) {
        setSelectedLesson(data.lesson);
      } else {
        console.error("Failed to fetch lesson details");
      }
    } catch (error) {
      console.error('Error fetching lesson details:', error);
    }
  };

  const handleLessonClick = (lessonId: string) => {
    setSelectedLessonId(lessonId);
    fetchLessonDetails(lessonId);
  };

  const handleBackToLessons = () => {
    setSelectedLessonId(null);
    setSelectedLesson(null);
  };

  const handleRegister = () => {
    // This function will be implemented later
    alert("Функция записи будет реализована позже");
  };

  const calculateAge = (birthDateString: string) => {
    const birthDate = new Date(birthDateString);
    const today = new Date();
    
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    
    // Склонение возраста
    let years = 'лет';
    if (age % 10 === 1 && age % 100 !== 11) {
      years = 'год';
    } else if ([2, 3, 4].includes(age % 10) && ![12, 13, 14].includes(age % 100)) {
      years = 'года';
    }
    
    return `${age} ${years}`;
  };

  // Removed toggleExpand function since we're removing ChildInfo

  const handleEditChild = () => {
    navigate(`/lessons/edit-child/${childId}`);
  };

  if (isLoading) {
    return (
      <div className={styles.childContentContainer}>
        <div className={styles.loadingState}>
          <p>Загрузка данных ребенка...</p>
        </div>
      </div>
    );
  }

  if (error || !child) {
    return (
      <div className={styles.childContentContainer}>
        <div className={styles.errorState}>
          <p>{error || 'Не удалось загрузить данные ребенка'}</p>
          <Button 
            state="secondary"
            label="Попробовать снова"
            onClick={fetchChildData}
          />
        </div>
      </div>
    );
  }

  // Display selected lesson details
  if (selectedLessonId && selectedLesson) {
    return (
      <div className={styles.childContentContainer}>
        <LessonDetailView 
          lesson={selectedLesson}
          onBack={handleBackToLessons}
          onRegister={handleRegister}
        />
      </div>
    );
  }

  return (
    <div className={styles.childContentContainer}>
      <div className={styles.childHeader}>
        <div className={styles.childInfo} onClick={handleEditChild}>
          <h1 title={`${child.firstName} ${child.lastName}`} className={styles.childName}>
            {child.firstName} {child.lastName}
            <img 
              src="../content/icons/edit-icon-20px.svg" 
              alt="Редактировать" 
              className={styles.editIcon} 
            />
          </h1>
          <p className={styles.childAge}>{calculateAge(child.birthDate)}</p>
        </div>
      </div>

      <div className={styles.infoCard}>
        <h2>Доступные занятия</h2>
        
        {isLessonsLoading ? (
          <div className={styles.loadingState}>
            <p>Загрузка занятий...</p>
          </div>
        ) : lessons.length === 0 ? (
          <div className={styles.noLessons}>
            <p>Нет доступных занятий для этого ребенка</p>
          </div>
        ) : (
          <div className={styles.lessonsScroll}>
            {lessons.map(lesson => (
              <div key={lesson._id} className={styles.lessonCardWrapper}>
                <LessonCard
                  lesson={lesson}
                  buttonText="Подробнее"
                  onButtonClick={() => handleLessonClick(lesson._id)}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};