import React, { useState, useEffect, useCallback } from 'react';
import { Input } from '../../../../components/atoms/Input/Input';
import { Button } from '../../../../components/atoms/Button/Button';
import { EditPopup } from '../EditPopup/EditPopup';
import styles from './MultipleLessonsForm.module.scss';

interface Template {
  _id: string;
  name: string;
  photo: string[];
  description: string;
  groupId?: string;
  ageFrom: string | number;
  ageTo: string | number;
  startTimeHours: number;
  startTimeMinutes: number;
  endTimeHours: number;
  endTimeMinutes: number;
  teacherId: string;
  participantsLimit: number;
  price: number;
  teacherPrice?: number;
}

interface Teacher {
  _id: string;
  name: string;
  surname: string;
  specializations?: string[];
}

interface MultipleLessonsFormProps {
  templateId: string;
  onSubmit: (templateId: string, count: number, selectedDays: number[]) => void;
  onCancel: () => void;
  onDelete?: (templateId: string) => void;
}

export const MultipleLessonsForm: React.FC<MultipleLessonsFormProps> = ({
  templateId,
  onSubmit,
  onCancel,
  onDelete
}) => {
  const [template, setTemplate] = useState<Template | null>(null);
  const [templateName, setTemplateName] = useState('');
  const [templateDescription, setTemplateDescription] = useState('');
  const [ageFrom, setAgeFrom] = useState('');
  const [ageTo, setAgeTo] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [price, setPrice] = useState('');
  const [participantsLimit, setParticipantsLimit] = useState('');
  const [teachers, setTeachers] = useState<Teacher[]>([]);
  const [selectedTeacher, setSelectedTeacher] = useState('');
  const [isEditingTemplate, setIsEditingTemplate] = useState(false);
  
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [lessonCount, setLessonCount] = useState<number>(10);
  const [previewDates, setPreviewDates] = useState<string[]>([]);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  
  // Days of the week
  const [daysOfWeek, setDaysOfWeek] = useState<Array<{id: number; name: string; shortName: string; selected: boolean}>>([
    { id: 1, name: 'Понедельник', shortName: 'ПН', selected: false },
    { id: 2, name: 'Вторник', shortName: 'ВТ', selected: false },
    { id: 3, name: 'Среда', shortName: 'СР', selected: false },
    { id: 4, name: 'Четверг', shortName: 'ЧТ', selected: false },
    { id: 5, name: 'Пятница', shortName: 'ПТ', selected: false },
    { id: 6, name: 'Суббота', shortName: 'СБ', selected: false },
    { id: 0, name: 'Воскресенье', shortName: 'ВС', selected: false },
  ]);

  // Fetch template data
  const fetchTemplateData = useCallback(async () => {
    try {
      setIsFetching(true);
      setError(null);
      
      const response = await fetch(`/api/lessons/admin/templates/${templateId}`);
      
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      
      const data = await response.json();
      
      if (data.success) {
        setTemplate(data.template);
        
        // Set edit state values
        setTemplateName(data.template.name);
        setTemplateDescription(data.template.description);
        setAgeFrom(String(data.template.ageFrom));
        setAgeTo(String(data.template.ageTo));
        setStartTime(`${String(data.template.startTimeHours).padStart(2, '0')}:${String(data.template.startTimeMinutes).padStart(2, '0')}`);
        setEndTime(`${String(data.template.endTimeHours).padStart(2, '0')}:${String(data.template.endTimeMinutes).padStart(2, '0')}`);
        setPrice(String(data.template.price));
        setParticipantsLimit(String(data.template.participantsLimit));
        setSelectedTeacher(data.template.teacherId);
        
        // Set current day of week as selected by default
        const today = new Date();
        const dayOfWeek = today.getDay();
        
        setDaysOfWeek(prev => 
          prev.map(day => ({
            ...day,
            selected: day.id === dayOfWeek
          }))
        );
        
        // Generate preview dates
        generatePreviewDates(lessonCount, [dayOfWeek]);
      } else {
        throw new Error(data.error || 'Не удалось загрузить данные шаблона');
      }
    } catch (error) {
      console.error('Error fetching template:', error);
      setError('Не удалось загрузить шаблон. Пожалуйста, попробуйте позже.');
    } finally {
      setIsFetching(false);
    }
  }, [templateId]);

  const fetchTeachers = useCallback(async () => {
    try {
      const response = await fetch('/api/lessons/teachers');
      
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      
      const data = await response.json();
      
      if (data.success) {
        setTeachers(data.teachers);
      }
    } catch (error) {
      console.error('Error fetching teachers:', error);
    }
  }, []);

  useEffect(() => {
    fetchTemplateData();
    fetchTeachers();
  }, [fetchTemplateData, fetchTeachers]);

  // Toggle day selection
  const toggleDay = (dayId: number) => {
    const updatedDays = daysOfWeek.map(day => 
      day.id === dayId ? {...day, selected: !day.selected} : day
    );
    
    setDaysOfWeek(updatedDays);
    
    // Get selected day IDs
    const selectedDayIds = updatedDays
      .filter(day => day.selected)
      .map(day => day.id);
    
    // Regenerate preview dates
    generatePreviewDates(lessonCount, selectedDayIds);
  };

  // Handle lesson count change
  const handleLessonCountChange = (value: string) => {
    const count = parseInt(value) || 0;
    setLessonCount(count);
    
    // Get selected day IDs
    const selectedDayIds = daysOfWeek
      .filter(day => day.selected)
      .map(day => day.id);
    
    // Regenerate preview dates
    generatePreviewDates(count, selectedDayIds);
  };

  // Generate preview dates
  const generatePreviewDates = (count: number, selectedDayIds: number[]) => {
    if (count <= 0 || selectedDayIds.length === 0) {
      setPreviewDates([]);
      return;
    }
    
    const dates: string[] = [];
    const today = new Date();
    const currentDay = today.getDay();
    let date = new Date(today);
    let lessonCounter = 0;
    
    // Sort selected days to go in the right order from the current day
    const sortedDays = [...selectedDayIds].sort((a, b) => {
      // Calculate days from current day of week
      const daysFromCurrent = (dayId: number) => {
        return (dayId >= currentDay) ? 
          dayId - currentDay : 
          (7 - currentDay) + dayId;
      };
      
      return daysFromCurrent(a) - daysFromCurrent(b);
    });
    
    // If today's day is in the list of selected days
    if (selectedDayIds.includes(currentDay)) {
      const now = new Date();
      
      // Check if it's already past the time, if the template has specified time
      if (template && template.startTimeHours !== undefined && template.startTimeMinutes !== undefined) {
        const templateStartTime = new Date(now);
        templateStartTime.setHours(template.startTimeHours, template.startTimeMinutes, 0, 0);
        
        // If the start time today hasn't passed yet, add today
        if (now < templateStartTime) {
          dates.push(formatDate(date));
          lessonCounter++;
        }
      } else {
        // If there's no start time, just add today
        dates.push(formatDate(date));
        lessonCounter++;
      }
    }
    
    // Set date to next day to start generating from tomorrow
    date.setDate(date.getDate() + 1);
    
    while (lessonCounter < count) {
      const dayOfWeek = date.getDay();
      
      if (selectedDayIds.includes(dayOfWeek)) {
        dates.push(formatDate(date));
        lessonCounter++;
      }
      
      // Move to next day
      date.setDate(date.getDate() + 1);
      
      // Safeguard against infinite loop
      if (dates.length > 366) { // Maximum one year ahead
        break;
      }
    }
    
    setPreviewDates(dates);
  };

  // Format date as DD.MM (Day)
  const formatDate = (date: Date): string => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const dayOfWeek = date.getDay();
    const dayNames = ['ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ'];
    
    return `${day}.${month} (${dayNames[dayOfWeek]})`;
  };

  // Handle form submission
  const handleSubmit = () => {
    if (lessonCount <= 0) {
      setError('Укажите количество занятий');
      return;
    }
    
    const selectedDayIds = daysOfWeek
      .filter(day => day.selected)
      .map(day => day.id);
    
    if (selectedDayIds.length === 0) {
      setError('Выберите хотя бы один день недели');
      return;
    }
    
    setIsLoading(true);
    onSubmit(templateId, lessonCount, selectedDayIds);
  };

  // Handle template update
  const handleUpdateTemplate = async () => {
    if (!template) return;
    
    try {
      setIsLoading(true);
      
      // Parse time strings to hours and minutes
      const [startHours, startMinutes] = startTime.split(':').map(Number);
      const [endHours, endMinutes] = endTime.split(':').map(Number);
      
      const updatedTemplate = {
        name: templateName,
        description: templateDescription,
        ageFrom: parseFloat(ageFrom),
        ageTo: parseFloat(ageTo),
        startTimeHours: startHours,
        startTimeMinutes: startMinutes,
        endTimeHours: endHours,
        endTimeMinutes: endMinutes,
        price: parseInt(price),
        participantsLimit: parseInt(participantsLimit),
        teacherId: selectedTeacher,
        photo: template.photo // Keep the existing photo
      };
      
      const response = await fetch(`/api/lessons/admin/templates/${templateId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedTemplate),
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      
      const data = await response.json();
      
      if (data.success) {
        setTemplate(data.template);
        setIsEditingTemplate(false);
        
        // Update local states with the updated template
        if (data.template) {
          setTemplateName(data.template.name);
          setTemplateDescription(data.template.description);
          setAgeFrom(String(data.template.ageFrom));
          setAgeTo(String(data.template.ageTo));
          setStartTime(`${String(data.template.startTimeHours).padStart(2, '0')}:${String(data.template.startTimeMinutes).padStart(2, '0')}`);
          setEndTime(`${String(data.template.endTimeHours).padStart(2, '0')}:${String(data.template.endTimeMinutes).padStart(2, '0')}`);
          setPrice(String(data.template.price));
          setParticipantsLimit(String(data.template.participantsLimit));
        }
      } else {
        throw new Error(data.error || 'Не удалось обновить шаблон');
      }
    } catch (error) {
      console.error('Error updating template:', error);
      setError('Не удалось обновить шаблон. Пожалуйста, попробуйте позже.');
    } finally {
      setIsLoading(false);
    }
  };

  // Handle template deletion
  const handleDeleteTemplate = () => {
    setShowDeleteConfirm(true);
  };

  // Confirm deletion
  const handleConfirmDelete = (reason: string) => {
    if (onDelete) {
      onDelete(templateId);
    }
    setShowDeleteConfirm(false);
  };

  // Render loading state
  if (isFetching) {
    return (
      <div className={styles.formContainer}>
        <div className={styles.loadingContainer}>
          <p>Загрузка шаблона...</p>
        </div>
      </div>
    );
  }

  // Render error state
  if (error || !template) {
    return (
      <div className={styles.formContainer}>
        <div className={styles.errorContainer}>
          <p className={styles.errorMessage}>{error || 'Шаблон не найден'}</p>
          <Button
            state="secondary"
            label="Вернуться"
            onClick={onCancel}
          />
        </div>
      </div>
    );
  }

  // Render edit form for template
  if (isEditingTemplate) {
    return (
      <div className={styles.formContainer}>
        <h2 className={styles.formTitle}>Редактирование шаблона</h2>
        
        <div className={styles.formMainSection}>
          <div className={styles.formInputs}>
            <div className={styles.formRow}>
              <Input
                type="text"
                value={templateName}
                onChange={setTemplateName}
                label="Название шаблона"
                upperLabel="Название шаблона"
                disabled={isLoading}
              />
            </div>
            
            <div className={styles.formRow}>
              <textarea
                className={styles.textarea}
                value={templateDescription}
                onChange={(e) => setTemplateDescription(e.target.value)}
                placeholder="Описание шаблона"
                disabled={isLoading}
                style={{ width: '100%', minHeight: '100px', padding: '12px', borderRadius: '8px', border: 'none' }}
              />
            </div>
            
            <div className={styles.formRow}>
              <Input
                type="text"
                value={ageFrom}
                onChange={setAgeFrom}
                label="Возраст от"
                upperLabel="Возраст от"
                disabled={isLoading}
              />
              <Input
                type="text"
                value={ageTo}
                onChange={setAgeTo}
                label="Возраст до"
                upperLabel="Возраст до"
                disabled={isLoading}
              />
            </div>
            
            <div className={styles.formRow}>
              <Input
                type="text"
                value={startTime}
                onChange={setStartTime}
                label="Время начала (ЧЧ:ММ)"
                upperLabel="Время начала"
                disabled={isLoading}
              />
              <Input
                type="text"
                value={endTime}
                onChange={setEndTime}
                label="Время окончания (ЧЧ:ММ)"
                upperLabel="Время окончания"
                disabled={isLoading}
              />
            </div>
            
            <div className={styles.formRow}>
              <Input
                type="text"
                value={price}
                onChange={setPrice}
                label="Цена занятия (руб.)"
                upperLabel="Цена занятия"
                disabled={isLoading}
              />
              <Input
                type="text"
                value={participantsLimit}
                onChange={setParticipantsLimit}
                label="Лимит участников"
                upperLabel="Лимит участников"
                disabled={isLoading}
              />
            </div>
            
            <div className={styles.formRow}>
              <div className={styles.formColumn}>
                <label style={{ display: 'block', fontSize: '12px', color: 'var(--tg-theme-hint-color)', margin: '0 0 4px 8px' }}>Преподаватель</label>
                <select
                  style={{ width: '100%', height: '48px', padding: '0 12px', border: '1px solid var(--tg-theme-section-separator-color)', borderRadius: '8px', backgroundColor: 'var(--tg-theme-bg-color)', color: 'var(--tg-theme-text-color)', fontSize: '16px' }}
                  value={selectedTeacher}
                  onChange={(e) => setSelectedTeacher(e.target.value)}
                  disabled={isLoading}
                >
                  <option value="">Выберите преподавателя</option>
                  {teachers.map((teacher) => (
                    <option key={teacher._id} value={teacher._id}>
                      {teacher.surname} {teacher.name} {teacher.specializations?.length ? `(${teacher.specializations.join(', ')})` : ''}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
        
        <div className={styles.formButtons}>
          <Button
            state="secondary"
            label="Отменить"
            onClick={() => setIsEditingTemplate(false)}
            disabled={isLoading}
          />
          <Button
            state="active"
            label={isLoading ? "Сохранение..." : "Сохранить изменения"}
            onClick={handleUpdateTemplate}
            disabled={isLoading}
          />
        </div>
      </div>
    );
  }

  // Render main form for creating lessons
  return (
    <div className={styles.formContainer}>
      <h2 className={styles.formTitle}>Создание серии занятий</h2>
      
      {error && (
        <div className={styles.formError}>
          <p>{error}</p>
        </div>
      )}
      
      <div className={styles.templateInfoSection}>
        <div className={styles.templateCard}>
          <div className={styles.imageContainer}>
            {template.photo && template.photo.length > 0 ? (
              <img src={template.photo[0]} alt={template.name} className={styles.image} />
            ) : (
              <div className={styles.imagePlaceholder}>
                <span>Нет фото</span>
              </div>
            )}
          </div>
        </div>
        
        <div className={styles.templateDetails}>
          <h4 className={styles.templateName}>{template.name}</h4>
          
          <div className={styles.detailRow}>
            <span className={styles.label}>Время:</span>
            <span className={styles.value}>
              {String(template.startTimeHours).padStart(2, '0')}:
              {String(template.startTimeMinutes).padStart(2, '0')} – 
              {String(template.endTimeHours).padStart(2, '0')}:
              {String(template.endTimeMinutes).padStart(2, '0')}
            </span>
          </div>
          
          <div className={styles.detailRow}>
            <span className={styles.label}>Возраст:</span>
            <span className={styles.value}>{template.ageFrom} - {template.ageTo} лет</span>
          </div>
          
          <div className={styles.detailRow}>
            <span className={styles.label}>Стоимость:</span>
            <span className={styles.value}>{template.price}₽</span>
          </div>
          
          <div className={styles.detailRow}>
            <span className={styles.label}>Лимит участников:</span>
            <span className={styles.value}>{template.participantsLimit}</span>
          </div>
          
          <Button
            className={styles.editButton}
            state="secondary"
            label="Редактировать шаблон"
            onClick={() => setIsEditingTemplate(true)}
            disabled={isLoading}
          />
        </div>
      </div>
      
      <div className={styles.formMainSection}>
        <h3 className={styles.sectionTitle}>Параметры создания</h3>
        
        <div className={styles.formRow}>
          <div className={styles.formColumn}>
            <Input
              type="text"
              value={lessonCount.toString()}
              onChange={handleLessonCountChange}
              label="Количество занятий"
              upperLabel="Количество занятий"
              state={error && lessonCount <= 0 ? 'error' : 'inactive'}
              disabled={isLoading}
            />
          </div>
        </div>
        
        <div className={styles.formRow}>
          <div className={styles.fullWidth}>
            <p className={styles.daysLabel}>Выберите дни недели:</p>
            <div className={styles.daysGrid}>
              {daysOfWeek.map(day => (
                <button 
                  key={day.id} 
                  className={`${styles.dayButton} ${day.selected ? styles.selected : ''}`}
                  onClick={() => toggleDay(day.id)}
                  type="button"
                >
                  <span className={styles.dayShort}>{day.shortName}</span>
                  <span className={styles.dayFull}>{day.name}</span>
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
      
      <div className={styles.previewSection}>
        <h3 className={styles.sectionTitle}>Предварительный просмотр дат</h3>
        
        {previewDates.length > 0 ? (
          <div className={styles.datesPreview}>
            {previewDates.map((date, index) => (
              <div key={index} className={styles.dateItem}>
                <span className={styles.dateNumber}>{index + 1}</span>
                <span className={styles.dateValue}>{date}</span>
              </div>
            ))}
          </div>
        ) : (
          <p className={styles.noDates}>
            Выберите количество занятий и дни недели для просмотра дат
          </p>
        )}
      </div>
      
      <div className={styles.formButtons}>
        <div className={styles.leftButtons}>
          {onDelete && (
            <Button
              state="agressive"
              label="Удалить шаблон"
              onClick={handleDeleteTemplate}
              disabled={isLoading}
            />
          )}
        </div>
        <div className={styles.rightButtons}>
          <Button
            state="secondary"
            label="Отменить"
            onClick={onCancel}
            disabled={isLoading}
          />
          <Button
            state="active"
            label={isLoading ? "Создание..." : "Создать занятия"}
            onClick={handleSubmit}
            disabled={isLoading || previewDates.length === 0}
          />
        </div>
      </div>
      
      {/* Delete confirmation popup */}
      <EditPopup
        title="Подтверждение удаления"
        isOpen={showDeleteConfirm}
        onClose={() => setShowDeleteConfirm(false)}
        onSave={handleConfirmDelete}
      >
        <div>
          <p>
            Вы уверены, что хотите удалить этот шаблон? Это действие нельзя будет отменить.
            Все созданные на основе этого шаблона занятия останутся без изменений.
          </p>
        </div>
      </EditPopup>
    </div>
  );
};