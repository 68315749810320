import React, { useState, useRef, useEffect } from 'react';
import '../../styles/PinInput.scss';
import * as phoneUtils from '../../utils/phoneUtils';

interface PinInputProps {
    onPinSubmit: (pin: string) => void;
    onPinError: () => void;
    phone: string;
}

const PinInput: React.FC<PinInputProps> = ({ phone, onPinSubmit, onPinError }) => {
  const [digits, setDigits] = useState(['', '', '', '']);
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const currentIndex = digits.findIndex(digit => digit === '');
  const normalizedPhone = phoneUtils.normalizePhone(phone);

  useEffect(() => {
    inputRefs.current = inputRefs.current.slice(0, digits.length);
    const tryStoredPin = async () => {
      const storedPin = localStorage.getItem('adminPin');
      if (storedPin) {
        try {
          const response = await fetch('/api/verify-pin', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ 
              pin: storedPin,
              phone: normalizedPhone 
            }),
          });

          const data = await response.json();

          if (data.success) {
            onPinSubmit(storedPin);
          } else {
            localStorage.removeItem('adminPin');
          }
        } catch (error) {
          console.error('Stored pin verification error:', error);
          localStorage.removeItem('adminPin');
        }
      }
      setIsLoading(false);
    };

    tryStoredPin();
  }, []);

  const handleDigitInput = (value: string) => {
    if (currentIndex === -1) return;

    const newDigits = [...digits];
    newDigits[currentIndex] = value;
    setDigits(newDigits);
    setShowError(false);

    if (newDigits.every(digit => digit !== '')) {
      handleCompletePin(newDigits);
    }
  };

  const handleBackspace = () => {
    const lastFilledIndex = digits.map(d => d !== '').lastIndexOf(true);
    if (lastFilledIndex !== -1) {
      const newDigits = [...digits];
      newDigits[lastFilledIndex] = '';
      setDigits(newDigits);
      setShowError(false);
    }
  };

  const handleCompletePin = async (completedDigits: string[]) => {
    const pin = completedDigits.join('');
    try {
      const response = await fetch('/api/verify-pin', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          pin,
          phone: normalizedPhone
        }),
      });
  
      const data = await response.json();
  
      if (data.success) {
        localStorage.setItem('adminPin', pin);
        onPinSubmit(pin);
      } else {
        setShowError(true);
        setDigits(['', '', '', '']);
        onPinError();
      }
    } catch (error) {
      console.error('Pin verification error:', error);
      setShowError(true);
    }
  };

  const renderKeypad = () => {
    const buttons = [
      ['1', '2', '3'],
      ['4', '5', '6'],
      ['7', '8', '9'],
      ['←', '0', '']
    ];

    return (
      <div className="keypad">
        {buttons.map((row, rowIndex) => (
          <div key={rowIndex} className="keypad-row">
            {row.map((button, index) => (
              <button
                key={index}
                className={`keypad-button ${!button ? 'empty' : ''} ${button === '←' ? 'backspace' : ''}`}
                onClick={() => {
                  if (button === '←') {
                    handleBackspace();
                  } else if (button !== '') {
                    handleDigitInput(button);
                  }
                }}
                disabled={!button}
              >
                {button}
              </button>
            ))}
          </div>
        ))}
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className="pin-input-container">
        <h1>Проверка...</h1>
      </div>
    );
  }

  return (
    <div className="pin-input-container">
      <h1>Введите PIN-код</h1>
      
      <div className="digits-container">
        {digits.map((digit, index) => (
          <input
            key={index}
            ref={el => inputRefs.current[index] = el}
            type="text"
            readOnly
            value={digit ? '●' : ''}
            className={`digit-input ${index === currentIndex ? 'current' : ''} ${showError ? 'error' : ''}`}
          />
        ))}
      </div>

      {showError && (
        <div className="error-message">
          Неверный PIN-код
        </div>
      )}

      {renderKeypad()}
    </div>
  );
};

export default PinInput;