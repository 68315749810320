import React from 'react';
import { Button } from '../../atoms/Button/Button';
import styles from './GroupHeader.module.scss';

export interface GroupHeaderProps {
  group: {
    _id: string;
    name: string;
    photo?: string[];
    ageFrom: number | string;
    ageTo: number | string;
    studentsIds?: Record<string, any>;
    participantsLimit: number;
    description?: string;
    teacherId?: string;
  };
  onDetailsClick?: (groupId: string) => void;
  onEditClick?: (groupId: string) => void;
}

export const GroupHeader: React.FC<GroupHeaderProps> = ({
  group,
  onDetailsClick,
  onEditClick
}) => {
  const {
    _id,
    name,
    photo,
    ageFrom,
    ageTo,
    studentsIds,
    participantsLimit
  } = group;

  // Calculate the number of enrolled students
  const enrolledStudents = studentsIds ? Object.keys(studentsIds).length : 0;

  return (
    <div className={styles.groupHeader}>
      <div className={styles.imageContainer}>
        {photo && photo.length > 0 ? (
          <img src={photo[0]} alt={name} className={styles.groupImage} />
        ) : (
          <div className={styles.imagePlaceholder}>
            <span>Без фото</span>
          </div>
        )}
      </div>

      <div className={styles.groupInfo}>
        <h3 className={styles.groupName}>{name}</h3>
        <p className={styles.ageRange}>от {ageFrom} до {ageTo} лет</p>
        <p className={styles.studentsCount}>{enrolledStudents} из {participantsLimit} учеников</p>
        <button 
          className={styles.detailsButton}
          onClick={() => onDetailsClick && onDetailsClick(_id)}
        >
          Подробнее о группе
        </button>
      </div>

      {onEditClick && (
        <button 
          className={styles.editButton}
          onClick={() => onEditClick && onEditClick(_id)}
        >
          Редактировать
        </button>
      )}
    </div>
  );
};