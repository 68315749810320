import React, { useState, useEffect } from 'react';
import { Input } from '../../../atoms/Input/Input';
import { Button } from '../../../atoms/Button/Button';
import { normalizePhone, formatPhoneForDisplay } from '../../../../utils/phoneUtils';
import styles from './Employees.module.scss';

interface Employee {
  _id: string;
  name: string;
  surname: string;
  phone: string;
  role: string;
}

export const Employees: React.FC = () => {
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [phone, setPhone] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // Загрузка списка сотрудников
  const fetchEmployees = async () => {
    try {
      const token = localStorage.getItem('lessonsAuthToken');
      const response = await fetch('/api/lessons/employees', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.json();
      if (data.success) {
        setEmployees(data.employees);
      }
    } catch (error) {
      console.error('Error fetching employees:', error);
    }
  };

  useEffect(() => {
    fetchEmployees();
  }, []);

  // Повышение до сотрудника
  const handlePromote = async (role: 'teacher' | 'admin') => {
    setIsLoading(true);
    setError(null);

    try {
      const token = localStorage.getItem('lessonsAuthToken');
      const response = await fetch('/api/lessons/employees/promote', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          phone: normalizePhone(phone),
          role
        }),
      });

      const data = await response.json();

      if (data.success) {
        setPhone('');
        fetchEmployees();
      } else {
        setError(data.error);
      }
    } catch (error) {
      console.error('Error promoting user:', error);
      setError('Произошла ошибка');
    } finally {
      setIsLoading(false);
    }
  };

  // Понижение до родителя
  const handleDemote = async (userId: string) => {
    try {
      const token = localStorage.getItem('lessonsAuthToken');
      const response = await fetch(`/api/lessons/employees/demote/${userId}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      const data = await response.json();

      if (data.success) {
        fetchEmployees();
      }
    } catch (error) {
      console.error('Error demoting employee:', error);
    }
  };

  const getRoleTitle = (role: string) => {
    switch (role) {
      case 'teacher':
        return 'Учитель';
      case 'admin':
        return 'Администратор';
      case 'director':
        return 'Директор';
      default:
        return role;
    }
  };

  return (
    <div className={styles.employees}>
      <div className={styles.addEmployee}>
        <div className={styles.phoneInput}>
          <Input
            type="phone"
            value={phone}
            onChange={setPhone}
            state={error ? "error" : "inactive"}
            error={error}
            disabled={isLoading}
          />
        </div>
        <div className={styles.buttons}>
          <Button
            state="secondary"
            label="Учитель"
            onClick={() => handlePromote('teacher')}
            disabled={isLoading || !phone}
          />
          <Button
            state="secondary"
            label="Администратор"
            onClick={() => handlePromote('admin')}
            disabled={isLoading || !phone}
          />
        </div>
      </div>

      {employees.length > 0 ? (
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Сотрудник</th>
              <th>Номер телефона</th>
              <th>Роль</th>
              <th>Действия</th>
            </tr>
          </thead>
          <tbody>
            {employees.map(employee => {
              const currentUserPhone = localStorage.getItem('userPhone');
              const isCurrentUser = employee.phone === currentUserPhone;
              
              return (
                <tr key={employee._id}>
                  <td>{`${employee.surname} ${employee.name}`}</td>
                  <td>{formatPhoneForDisplay(employee.phone)}</td>
                  <td>{getRoleTitle(employee.role)}</td>
                  <td>
                    {!isCurrentUser && (
                      <Button
                        state="agressive"
                        label="Удалить"
                        onClick={() => handleDemote(employee._id)}
                      />
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <div className={styles.noEmployees}>
          Нет сотрудников
        </div>
      )}
    </div>
  );
};