import React from 'react';
import styles from './Enrollments.module.scss';

export const Enrollments: React.FC = () => {
    return (
      <div>
        {/* Управление записями:
            - Подтверждение/отмена записей
            - Управление листом ожидания
            - Перенос занятий
            - История записей */}
      </div>
    );
  };