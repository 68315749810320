import React from 'react';
import styles from './Students.module.scss';

export const Students: React.FC = () => {
  return (
    <div>
      {/* Список учеников:
          - Группировка по занятиям/группам
          - Информация о каждом ученике
          - Прогресс обучения
          - Посещаемость */}
    </div>
  );
};