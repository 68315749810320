import React, { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import './styles.scss';
import { User } from './bookings/types';
import BookingRoutes from './bookings/routes/routes';
import { LessonsRoutes } from './lessons/routes/routes';
import ErrorBoundary from './bookings/components/errors/ErrorBoundary';
import * as phoneUtils from './bookings/utils/phoneUtils';
import { ReactComponent as LogoSVG } from './bookings/content/center.svg';

const App: React.FC = () => {
  const [currentPhone, setCurrentPhone] = useState<string>('+7 ');
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [needsPin, setNeedsPin] = useState(false);
  const [tempUser, setTempUser] = useState<User | null>(null);

  useEffect(() => {
    const savedPhone = localStorage.getItem('userPhone');
    if (savedPhone) {
      handleAutoAuth(savedPhone);
    } else {
      setIsLoading(false);
    }
  }, []);

  const handleAutoAuth = async (savedPhone: string) => {
    try {
      const response = await fetch('/api/auth', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ phone: savedPhone }),
      });

      const data = await response.json();

      if (data.success) {
        if (data.user.role === 'admin') {
          setTempUser(data.user);
          setNeedsPin(true);
        } else {
          setUser(data.user);
        }
        setCurrentPhone(phoneUtils.formatPhoneForDisplay(savedPhone));
      }
    } catch (error) {
      console.error('Auto auth error:', error);
      localStorage.removeItem('userPhone');
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogin = (user: User) => {
    if (user.role === 'admin') {
      setTempUser(user);
      setNeedsPin(true);
    } else {
      setUser(user);
      if (user.phone) {
        localStorage.setItem('userPhone', user.phone);
      }
    }
  };

  const handlePinVerified = () => {
    if (tempUser) {
      setUser(tempUser);
      if (tempUser.phone) {
        localStorage.setItem('userPhone', tempUser.phone);
      }
      setNeedsPin(false);
      setTempUser(null);
    }
  };

  const handleRegistrationNeeded = (phone: string) => {
    setCurrentPhone(phone);
  };

  const handleLogout = () => {
    localStorage.removeItem('userPhone');
    setUser(null);
    setTempUser(null);
    setNeedsPin(false);
    setCurrentPhone('+7 ');
  };

  if (isLoading) {
    return (
      <div className="container">
        <div style={{ margin: "auto" }} className="auth-card">
          <LogoSVG style={{ width: "140px", height: "140px" }}></LogoSVG>
          <h1>Загрузка...</h1>
        </div>
      </div>
    );
  }

  const isLessonsRoute = window.location.pathname.startsWith('/lessons');

  return (
    <BrowserRouter>
      <ErrorBoundary>
        {isLessonsRoute ? (
          <LessonsRoutes />
        ) : (
          <div className="container">
            <BookingRoutes
              user={user}
              onLogin={handleLogin}
              onLogout={handleLogout}
              onRegistrationNeeded={handleRegistrationNeeded}
              currentPhone={currentPhone}
              needsPin={needsPin}
              tempUser={tempUser}
              onPinVerified={handlePinVerified}
            />
          </div>
        )}
      </ErrorBoundary>
    </BrowserRouter>
  );
};

export default App;