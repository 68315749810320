import React from 'react';
import styles from './History.module.scss';

export const History: React.FC = () => {
  return (
    <div>
      {/* История всех детей:
          - История посещений занятий
          - История платежей
          - Фильтры по датам и детям
          - Возможность выгрузки отчётов */}
    </div>
  );
};