interface TabConfig {
  id: string;
  label: string;
}

export const getRoleTabsConfig = (role: string): TabConfig[] => {
  switch (role) {
    case 'parent':
      return [
        { id: 'history', label: 'История' }
      ];
    case 'teacher':
      return [
        { id: 'schedule', label: 'Расписание' },
        { id: 'students', label: 'Ученики' },
        { id: 'statistics', label: 'Статистика' },
        { id: 'homework', label: 'ДЗ' },
        { id: 'profile', label: 'Профиль' }
      ];
    case 'admin':
      return [
        { id: 'lessons', label: 'Занятия' },
        { id: 'enrollments', label: 'Записи' },
        { id: 'clients', label: 'Клиенты' },
        { id: 'groups', label: 'Группы' },
        { id: 'documents', label: 'Документы' },
        { id: 'students', label: 'Ученики' },
        { id: 'accounting', label: 'Доходы/Расходы' }
      ];
    case 'director':
      return [
        { id: 'analytics', label: 'Аналитика' },
        { id: 'finances', label: 'Финансы' },
        { id: 'clients', label: 'Клиенты' },
        { id: 'control', label: 'Контроль' },
        { id: 'employees', label: 'Сотрудники' },
        { id: 'settings', label: 'Настройки' }
      ];
    default:
      return [];
  }
};