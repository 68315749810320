import { Navigate } from 'react-router-dom';
import { User } from '../types';

interface ProtectedLayoutProps {
  user: User | null;
  allowedRoles: string[];
  children: React.ReactNode;
}

const ProtectedLayout: React.FC<ProtectedLayoutProps> = ({
  user,
  allowedRoles,
  children
}) => {
  if (!user) {
    return <Navigate to="/login" replace />;
  }

  if (!allowedRoles.includes(user.role)) {
    return <Navigate to={user.role === 'admin' ? '/admin' : '/profile'} replace />;
  }

  return <>{children}</>;
};

export default ProtectedLayout;