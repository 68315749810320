import React, { useState, useCallback, InputHTMLAttributes } from "react";
import classNames from "classnames";
import styles from "./Input.module.scss";
import { ReactComponent as ShowPassIcon } from '../../../content/icons/show-pass-icon.svg';
import { ReactComponent as HidePassIcon } from '../../../content/icons/hide-pass-icon.svg';

type InputType = "text" | "phone" | "date" | "password";
type InputState = "filled" | "inactive" | "active" | "error";

interface BaseInputProps {
  type?: InputType;
  state?: InputState;
  label?: string;
  upperLabel?: string;
  className?: string;
  value: string;
  onChange: (value: string) => void;
  error?: string | null;
  readOnly?: boolean;
}

type InputProps = Omit<InputHTMLAttributes<HTMLInputElement>, keyof BaseInputProps> & BaseInputProps;

const RussianFlag = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 10H16V12C16 13.1046 15.1046 14 14 14H2C0.895431 14 0 13.1046 0 12V10Z" fill="#CE2028"/>
    <path d="M0 4C0 2.89543 0.895431 2 2 2H14C15.1046 2 16 2.89543 16 4V6H0V4Z" fill="#EEEEEE"/>
    <rect y="6" width="16" height="4" fill="#22408C"/>
  </svg>
);

const CalendarIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.5 11.5H7M4.5 9H11.5M14 6.5V5C14 4.17157 13.3284 3.5 12.5 3.5H11.5M14 6.5V12.5C14 13.3284 13.3284 14 12.5 14H3.5C2.67157 14 2 13.3284 2 12.5V6.5M14 6.5H2M2 6.5V5C2 4.17157 2.67157 3.5 3.5 3.5H4.5M4.5 3.5V2M4.5 3.5H11.5M11.5 3.5V2" stroke="#8A8A8F" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const getInputMode = (type: InputType): React.HTMLAttributes<HTMLInputElement>['inputMode'] => {
  switch (type) {
    case 'phone':
    case 'date':
      return 'numeric';
    default:
      return 'text';
  }
};

export const Input: React.FC<InputProps> = ({
  type = "text",
  state = "inactive",
  label,
  upperLabel,
  className,
  value,
  onChange,
  error,
  readOnly = false,
  ...props
}) => {
  const [focused, setFocused] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const formatPhoneNumber = (input: string): string => {
    const digits = input.replace(/\D/g, "");
    const truncatedDigits = digits.slice(0, 10);
    
    if (truncatedDigits.length === 0) return "";
    if (truncatedDigits.length <= 3) {
      return `(${truncatedDigits}`;
    }
    if (truncatedDigits.length <= 6) {
      return `(${truncatedDigits.slice(0, 3)}) ${truncatedDigits.slice(3)}`;
    }
    if (truncatedDigits.length <= 8) {
      return `(${truncatedDigits.slice(0, 3)}) ${truncatedDigits.slice(3, 6)}-${truncatedDigits.slice(6)}`;
    }
    return `(${truncatedDigits.slice(0, 3)}) ${truncatedDigits.slice(3, 6)}-${truncatedDigits.slice(6, 8)}-${truncatedDigits.slice(8)}`;
  };

  const formatDate = (input: string): string => {
    const numbers = input.replace(/\D/g, "").slice(0, 8);
    if (numbers.length === 0) return "";
    if (numbers.length <= 2) return numbers;
    if (numbers.length <= 4) return `${numbers.slice(0, 2)}.${numbers.slice(2)}`;
    return `${numbers.slice(0, 2)}.${numbers.slice(2, 4)}.${numbers.slice(4)}`;
  };

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const input = e.target.value;
      let formattedValue = input;
  
      switch (type) {
        case "phone":
          let phoneInput = input;
          if (input.startsWith('+7')) {
            phoneInput = input.substring(2);
          } else if (input.startsWith('7') || input.startsWith('8')) {
            phoneInput = input.substring(1);
          }
          formattedValue = formatPhoneNumber(phoneInput);
          break;
        case "date":
          formattedValue = formatDate(input);
          break;
        default:
          formattedValue = input;
      }
  
      onChange(formattedValue);
    },
    [type, onChange]
  );

  const getPlaceholder = () => {
    switch (type) {
      case "phone":
        return "(900) 800-55-55";
      case "date":
        return "01.01.2008";
      case "password":
        return "••••••";
      default:
        return label || "Label";
    }
  };

  const currentState = focused ? "active" : state;

  return (
    <div className={styles.inputWrapper}>
      {upperLabel && <span className={styles.upperLabel}>{upperLabel}</span>}
      <div
        className={classNames(
          styles.container,
          styles[currentState],
          styles[`type-${type}`],
          { 
            [styles.hasError]: error,
            [styles.readOnly]: readOnly 
          },
          className
        )}
      >
        {type === "phone" && (
          <>
            <RussianFlag />
            <span className={styles.prefix}>+7</span>
          </>
        )}
        {type === "date" && <CalendarIcon />}
        <input
          className={styles.input}
          value={value}
          onChange={handleInputChange}
          onFocus={() => !readOnly && setFocused(true)}
          onBlur={() => setFocused(false)}
          placeholder={getPlaceholder()}
          type={type === "password" ? (showPassword ? "text" : "password") : "text"}
          inputMode={getInputMode(type)}
          readOnly={readOnly}
          {...props}
        />
        {type === "password" && !readOnly && (
          <button
            type="button"
            className={styles.togglePassword}
            onClick={() => setShowPassword(!showPassword)}
            aria-label={showPassword ? "Скрыть пароль" : "Показать пароль"}
          >
            {showPassword ? <HidePassIcon /> : <ShowPassIcon />}
          </button>
        )}
      </div>
      {error && <div className={styles.errorMessage}>{error}</div>}
    </div>
  );
};