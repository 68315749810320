import React from 'react';
import styles from './Control.module.scss';

export const Control: React.FC = () => {
    return (
      <div>
        {/* Контроль системы:
            - Мониторинг работы центра
            - Контроль качества
            - Управление процессами
            - Системные отчёты */}
      </div>
    );
  };