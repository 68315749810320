import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Input } from '../../../atoms/Input/Input';
import { Button } from '../../../atoms/Button/Button';
import { Switch } from '../../../atoms/Switch/Switch';
import { UpperButton } from '../../../atoms/UpperButton/UpperButton';
import styles from '../AddChild/AddChild.module.scss';

interface EditChildForm {
  firstName: string;
  lastName: string;
  birthDate: string;
  gender: string;
  phoneNumber: string;
  school: string;
  address: string;
  photoPermission: boolean;
  photoPostingPermission: string;
  independentVisitPermission: boolean;
  medicalConditions: string;
  allergies: string;
  additionalInfo: string;
}

interface FormErrors {
  firstName: string | null;
  lastName: string | null;
  birthDate: string | null;
  gender: string | null;
  phoneNumber: string | null;
  school: string | null;
  address: string | null;
}

export const EditChild: React.FC = () => {
  const navigate = useNavigate();
  const { childId } = useParams<{ childId: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [fetchError, setFetchError] = useState<string | null>(null);

  const [formData, setFormData] = useState<EditChildForm>({
    firstName: '',
    lastName: '',
    birthDate: '',
    gender: 'male',
    phoneNumber: '',
    school: '',
    address: '',
    photoPermission: false,
    photoPostingPermission: 'Нигде',
    independentVisitPermission: false,
    medicalConditions: '',
    allergies: '',
    additionalInfo: ''
  });

  const [errors, setErrors] = useState<FormErrors>({
    firstName: null,
    lastName: null,
    birthDate: null,
    gender: null,
    phoneNumber: null,
    school: null,
    address: null
  });

  // Загрузка данных ребенка
  useEffect(() => {
    const fetchChildData = async () => {
      if (!childId) return;
      
      setIsFetching(true);
      setFetchError(null);
      
      try {
        const response = await fetch(`/api/lessons/children/details/${childId}`);
        const data = await response.json();
        
        if (data.success && data.child) {
          const child = data.child;
          
          // Format birth date from ISO to DD.MM.YYYY
          const birthDate = new Date(child.birthDate);
          const formattedBirthDate = `${String(birthDate.getDate()).padStart(2, '0')}.${String(birthDate.getMonth() + 1).padStart(2, '0')}.${birthDate.getFullYear()}`;
          
          // Convert arrays to strings for form
          const medicalConditions = child.medicalConditions ? child.medicalConditions.join(', ') : '';
          const allergies = child.allergies ? child.allergies.join(', ') : '';
          
          setFormData({
            firstName: child.firstName,
            lastName: child.lastName,
            birthDate: formattedBirthDate,
            gender: child.gender,
            phoneNumber: child.phoneNumber || '',
            school: child.school || '',
            address: child.address,
            photoPermission: child.photoPermission,
            photoPostingPermission: child.photoPostingPermission,
            independentVisitPermission: child.independentVisitPermission,
            medicalConditions,
            allergies,
            additionalInfo: child.additionalInfo || ''
          });
        } else {
          setFetchError(data.error || 'Не удалось загрузить данные ребенка');
        }
      } catch (error) {
        console.error('Error fetching child data:', error);
        setFetchError('Ошибка загрузки данных ребенка. Попробуйте позже.');
      } finally {
        setIsFetching(false);
      }
    };
    
    fetchChildData();
  }, [childId]);

  const validateForm = (): boolean => {
    const newErrors: FormErrors = {
      firstName: null,
      lastName: null,
      birthDate: null,
      gender: null,
      phoneNumber: null,
      school: null,
      address: null
    };

    // Validate first name
    if (!formData.firstName.trim()) {
      newErrors.firstName = 'Введите имя ребенка';
    } else if (!/^[а-яА-ЯёЁ\s-]+$/i.test(formData.firstName)) {
      newErrors.firstName = 'Только русские буквы, пробел и дефис';
    }

    // Validate last name
    if (!formData.lastName.trim()) {
      newErrors.lastName = 'Введите фамилию ребенка';
    } else if (!/^[а-яА-ЯёЁ\s-]+$/i.test(formData.lastName)) {
      newErrors.lastName = 'Только русские буквы, пробел и дефис';
    }

    // Validate birth date
    if (!formData.birthDate) {
      newErrors.birthDate = 'Введите дату рождения';
    } else {
      const birthDate = new Date(formData.birthDate.split('.').reverse().join('-'));
      const today = new Date();
      const age = today.getFullYear() - birthDate.getFullYear();
      
      if (isNaN(birthDate.getTime())) {
        newErrors.birthDate = 'Некорректная дата';
      } else if (age < 2 || age > 18) {
        newErrors.birthDate = 'Возраст должен быть от 2 до 18 лет';
      }
    }

    // Validate phone number (optional)
    if (formData.phoneNumber) {
      const phoneDigits = formData.phoneNumber.replace(/\D/g, '');
      if (phoneDigits.length !== 10) {
        newErrors.phoneNumber = 'Неверный формат телефона';
      }
    }

    // Validate address
    if (!formData.address.trim()) {
      newErrors.address = 'Введите адрес';
    }

    setErrors(newErrors);
    return !Object.values(newErrors).some(error => error !== null);
  };

  const handleSubmit = async () => {
    if (!validateForm() || !childId) {
      return;
    }

    setIsLoading(true);
    try {
      // Format birth date from DD.MM.YYYY to YYYY-MM-DD for the API
      const formattedBirthDate = formData.birthDate.split('.').reverse().join('-');
      
      // Parse medical conditions and allergies as arrays
      const medicalConditionsArray = formData.medicalConditions
        ? formData.medicalConditions.split(',').map(item => item.trim())
        : [];
      
      const allergiesArray = formData.allergies
        ? formData.allergies.split(',').map(item => item.trim())
        : [];

      // Prepare data for API
      const childData = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        birthDate: formattedBirthDate,
        gender: formData.gender,
        phoneNumber: formData.phoneNumber || undefined,
        address: formData.address,
        school: formData.school || undefined,
        photoPermission: formData.photoPermission,
        photoPostingPermission: formData.photoPostingPermission,
        independentVisitPermission: formData.independentVisitPermission,
        medicalConditions: medicalConditionsArray,
        allergies: allergiesArray,
        additionalInfo: formData.additionalInfo || undefined
      };

      // Make API request
      const response = await fetch(`/api/lessons/children/${childId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(childData),
      });

      const data = await response.json();

      if (data.success) {
        setSuccessMessage(`Информация о ребенке ${formData.firstName} ${formData.lastName} успешно обновлена!`);
        setFormSubmitted(true);
        
        // Return to the child's page after a delay
        setTimeout(() => {
          navigate(`/lessons/home?tab=${childId}`);
        }, 2000);
      } else {
        throw new Error(data.error || 'Не удалось обновить данные ребенка');
      }
    } catch (error) {
      console.error('Error updating child:', error);
      setSuccessMessage('');
      setErrors(prev => ({
        ...prev,
        firstName: 'Не удалось обновить данные ребенка. Попробуйте позже.'
      }));
    } finally {
      setIsLoading(false);
    }
  };

  if (isFetching) {
    return (
      <div className={styles.addChildContainer}>
        <div className={styles.loadingState}>
          <p>Загрузка данных ребенка...</p>
        </div>
      </div>
    );
  }

  if (fetchError) {
    return (
      <div className={styles.addChildContainer}>
        <div className={styles.errorState}>
          <p>{fetchError}</p>
          <Button
            state="secondary"
            label="Вернуться назад"
            onClick={() => navigate(`/lessons/home`)}
          />
        </div>
      </div>
    );
  }

  if (formSubmitted && successMessage) {
    return (
      <div className={styles.successContainer}>
        <div className={styles.successMessage}>
          <h2>Успешно!</h2>
          <p>{successMessage}</p>
          <Button
            state="active"
            label="Вернуться на страницу ребенка"
            onClick={() => navigate(`/lessons/home?tab=${childId}`)}
          />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.addChildContainer}>
      <div className={styles.header}>
        <UpperButton
          label="Назад"
          iconSrc="../../../content/icons/back-icon-24px.svg"
          variant="white"
          onClick={() => navigate(`/lessons/home?tab=${childId}`)}
        />
        <h1>Редактирование данных ребенка</h1>
      </div>

      <div className={styles.formSection}>
        <h2>Основная информация</h2>
        
        <div className={styles.formRow}>
          <div className={styles.formColumn}>
            <Input
              type="text"
              value={formData.firstName}
              onChange={(value) => setFormData({ ...formData, firstName: value })}
              label="Имя ребенка"
              upperLabel="Имя ребенка"
              state={errors.firstName ? 'error' : 'inactive'}
              error={errors.firstName}
              disabled={isLoading}
            />
          </div>
          <div className={styles.formColumn}>
            <Input
              type="text"
              value={formData.lastName}
              onChange={(value) => setFormData({ ...formData, lastName: value })}
              label="Фамилия ребенка"
              upperLabel="Фамилия ребенка"
              state={errors.lastName ? 'error' : 'inactive'}
              error={errors.lastName}
              disabled={isLoading}
            />
          </div>
        </div>

        <div className={styles.formRow}>
          <div className={styles.formColumn}>
            <Input
              type="date"
              value={formData.birthDate}
              onChange={(value) => setFormData({ ...formData, birthDate: value })}
              label="Дата рождения"
              upperLabel="Дата рождения"
              state={errors.birthDate ? 'error' : 'inactive'}
              error={errors.birthDate}
              disabled={isLoading}
            />
          </div>
          <div className={styles.formColumn}>
            <div className={styles.switchContainer}>
              <p className={styles.switchLabel}>Пол ребенка</p>
              <Switch
                options={[
                  { id: 'male', label: 'Мальчик' },
                  { id: 'female', label: 'Девочка' }
                ]}
                value={formData.gender}
                onChange={(value) => setFormData({ ...formData, gender: value as string })}
                disabled={isLoading}
              />
            </div>
          </div>
        </div>

        <div className={styles.formRow}>
          <div className={styles.formColumn}>
            <Input
              type="phone"
              value={formData.phoneNumber}
              onChange={(value) => setFormData({ ...formData, phoneNumber: value })}
              label="Телефон ребенка (если есть)"
              upperLabel="Телефон ребенка (необязательно)"
              state={errors.phoneNumber ? 'error' : 'inactive'}
              error={errors.phoneNumber}
              disabled={isLoading}
            />
          </div>
          <div className={styles.formColumn}>
            <Input
              type="text"
              value={formData.school}
              onChange={(value) => setFormData({ ...formData, school: value })}
              label="Школа (если учится)"
              upperLabel="Школа (необязательно)"
              disabled={isLoading}
            />
          </div>
        </div>

        <div className={styles.formRow}>
          <div className={styles.fullWidth}>
            <Input
              type="text"
              value={formData.address}
              onChange={(value) => setFormData({ ...formData, address: value })}
              label="Домашний адрес"
              upperLabel="Домашний адрес"
              state={errors.address ? 'error' : 'inactive'}
              error={errors.address}
              disabled={isLoading}
            />
          </div>
        </div>
      </div>

      <div className={styles.formSection}>
        <h2>Разрешения</h2>
        
        <div className={styles.permissionRow}>
          <p className={styles.permissionLabel}>Разрешение на фото/видеосъемку</p>
          <div className={styles.switchWrapper}>
            <Switch
              options={[
                { id: 'false', label: 'Нет' },
                { id: 'true', label: 'Да' }
              ]}
              value={formData.photoPermission.toString()}
              onChange={(value) => setFormData({ 
                ...formData, 
                photoPermission: value === 'true' 
              })}
              disabled={isLoading}
            />
          </div>
        </div>

        <div className={styles.permissionRow}>
          <p className={styles.permissionLabel}>Разрешение на публикацию фото/видео</p>
          <div className={styles.switchWrapper}>
            <Switch
              options={[
                { id: 'Нигде', label: 'Нигде' },
                { id: 'В чатах', label: 'В чатах' },
                { id: 'Везде', label: 'Везде' }
              ]}
              value={formData.photoPostingPermission}
              onChange={(value) => setFormData({ 
                ...formData, 
                photoPostingPermission: value as string 
              })}
              disabled={isLoading}
            />
          </div>
        </div>

        <div className={styles.permissionRow}>
          <p className={styles.permissionLabel}>Разрешение на самостоятельное посещение</p>
          <div className={styles.switchWrapper}>
            <Switch
              options={[
                { id: 'false', label: 'Нет' },
                { id: 'true', label: 'Да' }
              ]}
              value={formData.independentVisitPermission.toString()}
              onChange={(value) => setFormData({ 
                ...formData, 
                independentVisitPermission: value === 'true' 
              })}
              disabled={isLoading}
            />
          </div>
        </div>
      </div>

      <div className={styles.formSection}>
        <h2>Дополнительная информация</h2>
        
        <div className={styles.formRow}>
          <div className={styles.fullWidth}>
            <textarea
              className={styles.textarea}
              value={formData.medicalConditions}
              onChange={(e) => setFormData({ ...formData, medicalConditions: e.target.value })}
              placeholder="Медицинские особенности (через запятую)"
              disabled={isLoading}
            />
          </div>
        </div>

        <div className={styles.formRow}>
          <div className={styles.fullWidth}>
            <textarea
              className={styles.textarea}
              value={formData.allergies}
              onChange={(e) => setFormData({ ...formData, allergies: e.target.value })}
              placeholder="Аллергии (через запятую)"
              disabled={isLoading}
            />
          </div>
        </div>

        <div className={styles.formRow}>
          <div className={styles.fullWidth}>
            <textarea
              className={styles.textarea}
              value={formData.additionalInfo}
              onChange={(e) => setFormData({ ...formData, additionalInfo: e.target.value })}
              placeholder="Дополнительная информация"
              disabled={isLoading}
            />
          </div>
        </div>
      </div>

      <div className={styles.buttonContainer}>
        <Button 
          state="active"
          label={isLoading ? "Сохранение..." : "Сохранить изменения"}
          onClick={handleSubmit}
          disabled={isLoading}
        />
        <Button 
          state="secondary"
          label="Отмена"
          onClick={() => navigate(`/lessons/home?tab=${childId}`)}
          disabled={isLoading}
        />
      </div>
    </div>
  );
};