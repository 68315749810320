import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import styles from './Students.module.scss';
import { Button } from '../../../atoms/Button/Button';
import { Input } from '../../../atoms/Input/Input';
import { Switch } from '../../../atoms/Switch/Switch';
import { EditPopup } from '../EditPopup/EditPopup';

interface ParentInfo {
  _id: string;
  name: string;
  surname: string;
  phone: string;
  balance: number;
  status: string;
}

interface Child {
  _id: string;
  firstName: string;
  lastName: string;
  birthDate: string;
  gender: string;
  phoneNumber?: string;
  address: string;
  school?: string;
  photoPermission: boolean;
  photoPostingPermission: string;
  independentVisitPermission: boolean;
  medicalConditions?: string[];
  allergies?: string[];
  additionalInfo?: string;
  status: string;
  parent: ParentInfo;
}

type EditType = 'status' | null;

interface EditState {
  isOpen: boolean;
  type: EditType;
  childId: string;
  currentValue: string;
  newValue: string;
}

export const Students: React.FC = () => {
  const [searchParams] = useSearchParams();
  const highlightedChildId = searchParams.get('childId');
  
  const [students, setStudents] = useState<Child[]>([]);
  const [expandedStudents, setExpandedStudents] = useState<Set<string>>(new Set());
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [statusFilter, setStatusFilter] = useState<'all' | 'active' | 'inactive' | 'pending'>('all');
  
  // State for editing
  const [editState, setEditState] = useState<EditState>({
    isOpen: false,
    type: null,
    childId: '',
    currentValue: '',
    newValue: ''
  });
  
  // Fetch students data from server
  useEffect(() => {
    const fetchStudents = async () => {
      try {
        setIsLoading(true);
        const response = await fetch('/api/lessons/students');
        
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        
        const data = await response.json();
        
        if (data.success) {
          setStudents(data.students);
          
          // If a specific child ID is provided in URL, expand that child's row
          if (highlightedChildId) {
            setExpandedStudents(new Set([highlightedChildId]));
            
            // If needed, scroll to the highlighted child
            setTimeout(() => {
              const element = document.getElementById(`child-${highlightedChildId}`);
              if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'center' });
              }
            }, 500);
          }
        } else {
          console.error('Failed to fetch students:', data.error);
        }
      } catch (error) {
        console.error('Error fetching students:', error);
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchStudents();
  }, [highlightedChildId]);
  
  // Toggle student expansion
  const toggleStudentExpansion = (studentId: string) => {
    setExpandedStudents(prev => {
      const newSet = new Set(prev);
      if (newSet.has(studentId)) {
        newSet.delete(studentId);
      } else {
        newSet.add(studentId);
      }
      return newSet;
    });
  };
  
  // Format date
  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    return date.toLocaleDateString('ru-RU', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  };
  
  // Calculate age
  const calculateAge = (birthDateString: string): string => {
    const birthDate = new Date(birthDateString);
    const today = new Date();
    
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    
    // Russian suffix for age
    const lastDigit = age % 10;
    const lastTwoDigits = age % 100;
    
    let years = 'лет';
    if (lastDigit === 1 && lastTwoDigits !== 11) {
      years = 'год';
    } else if ([2, 3, 4].includes(lastDigit) && ![12, 13, 14].includes(lastTwoDigits)) {
      years = 'года';
    }
    
    return `${age} ${years}`;
  };
  
  // Navigate to parent details
  const navigateToParent = (parentId: string) => {
    // Navigate to parent details page
    window.location.href = `/lessons/home?tab=clients&parentId=${parentId}`;
  };

  // Sort students by priority
  const sortStudents = (studentsArray: Child[]) => {
    return [...studentsArray].sort((a, b) => {
      // Priority function:
      // 1: active students
      // 2: pending students
      // 3: inactive students
      // 4: Highlighted student should be at the top
      const getPriority = (student: Child): number => {
        if (student._id === highlightedChildId) return 0; // Top priority
        if (student.status === 'active') return 1;
        if (student.status === 'pending') return 2;
        return 3; // inactive
      };
      
      return getPriority(a) - getPriority(b);
    });
  };

  // Filter students by search query and status
  const filteredStudents = sortStudents(students).filter(student => {
    // Status filter
    if (statusFilter !== 'all' && student.status !== statusFilter) {
      return false;
    }
    
    // Search filter
    if (searchQuery) {
      const query = searchQuery.toLowerCase();
      return (
        student.firstName.toLowerCase().includes(query) ||
        student.lastName.toLowerCase().includes(query) ||
        (student.phoneNumber && student.phoneNumber.toLowerCase().includes(query)) ||
        student.address.toLowerCase().includes(query) ||
        (student.school && student.school.toLowerCase().includes(query)) ||
        (student.parent.name.toLowerCase().includes(query)) ||
        (student.parent.surname.toLowerCase().includes(query)) ||
        (student.parent.phone.toLowerCase().includes(query))
      );
    }
    
    return true;
  });
  
  // Get status text
  const getStatusText = (status: string): string => {
    switch (status) {
      case 'active': return 'Активен';
      case 'inactive': return 'Неактивен';
      case 'pending': return 'Ожидание';
      default: return status;
    }
  };
  
  // Open status edit popup
  const openStatusEdit = (childId: string, currentStatus: string) => {
    setEditState({
      isOpen: true,
      type: 'status',
      childId,
      currentValue: currentStatus,
      newValue: currentStatus
    });
  };
  
  // Close edit popup
  const closeEditPopup = () => {
    setEditState({
      isOpen: false,
      type: null,
      childId: '',
      currentValue: '',
      newValue: ''
    });
  };
  
  // Handle save edit
  const handleSaveEdit = async (reason: string) => {
    if (!editState.type || !editState.childId) return;
    
    try {
      // Handle status change
      const url = `/api/lessons/students/${editState.childId}/status`;
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('lessonsAuthToken')}`
        },
        body: JSON.stringify({
          status: editState.newValue,
          reason
        })
      });
      
      if (!response.ok) {
        throw new Error('Failed to update status');
      }
      
      const result = await response.json();
      
      if (result.success) {
        // Update local state
        setStudents(prev => 
          prev.map(student => 
            student._id === editState.childId 
              ? { 
                  ...student, 
                  status: editState.newValue
                } 
              : student
          )
        );
        
        closeEditPopup();
      }
    } catch (error) {
      console.error(`Error updating ${editState.type}:`, error);
    }
  };
  
  // Handle edit value change
  const handleEditValueChange = (value: string) => {
    setEditState(prev => ({
      ...prev,
      newValue: value
    }));
  };
  
  // Render edit popup content
  const renderEditPopupContent = () => {
    switch (editState.type) {
      case 'status':
        return (
          <div className={styles.statusEditContent}>
            <p className={styles.currentValueLabel}>Текущий статус: {getStatusText(editState.currentValue as string)}</p>
            <div className={styles.statusSwitch}>
              <Switch
                options={[
                  { id: 'inactive', label: 'Неактивен' },
                  { id: 'pending', label: 'Ожидание' },
                  { id: 'active', label: 'Активен' }
                ]}
                value={editState.newValue}
                onChange={(value) => handleEditValueChange(value as string)}
              />
            </div>
          </div>
        );
      
      default:
        return null;
    }
  };
  
  if (isLoading) {
    return (
      <div className={styles.loadingContainer}>
        <div className={styles.loadingSpinner}></div>
        <p>Загрузка данных об учениках...</p>
      </div>
    );
  }
  
  return (
    <div className={styles.studentsContainer}>
      <div className={styles.controls}>
        <div className={styles.searchBox}>
          <Input
            type="text"
            value={searchQuery}
            onChange={setSearchQuery}
            label="Поиск по имени, телефону или школе..."
            state="inactive"
          />
        </div>
        
        <div className={styles.filterButtons}>
          <button 
            className={`${styles.filterButton} ${statusFilter === 'all' ? styles.active : ''}`}
            onClick={() => setStatusFilter('all')}
          >
            Все
          </button>
          <button 
            className={`${styles.filterButton} ${statusFilter === 'active' ? styles.active : ''}`}
            onClick={() => setStatusFilter('active')}
          >
            Активные
          </button>
          <button 
            className={`${styles.filterButton} ${statusFilter === 'pending' ? styles.active : ''}`}
            onClick={() => setStatusFilter('pending')}
          >
            Ожидающие
          </button>
          <button 
            className={`${styles.filterButton} ${statusFilter === 'inactive' ? styles.active : ''}`}
            onClick={() => setStatusFilter('inactive')}
          >
            Неактивные
          </button>
        </div>
      </div>
      
      <div className={styles.tableContainer}>
        <table className={styles.studentsTable}>
          <thead>
            <tr>
              <th className={styles.expandColumn}></th>
              <th className={styles.nameColumn}>ФИО</th>
              <th className={styles.balanceColumn}>Баланс ₽</th>
              <th className={styles.ageColumn}>Возраст</th>
              <th className={styles.birthDateColumn}>Дата рождения</th>
              <th className={styles.statusColumn}>Статус</th>
              <th className={styles.actionsColumn}>Действия</th>
            </tr>
          </thead>
          <tbody>
            {filteredStudents.length === 0 ? (
              <tr>
                <td colSpan={7} className={styles.noData}>
                  Нет данных для отображения
                </td>
              </tr>
            ) : (
              filteredStudents.map(student => (
                <React.Fragment key={student._id}>
                  {/* Student row */}
                  <tr 
                    id={`child-${student._id}`}
                    className={`${styles.studentRow} ${student._id === highlightedChildId ? styles.highlighted : ''}`}
    style={student._id === highlightedChildId ? {backgroundColor: 'rgba(126, 211, 33, 0.1)'} : undefined}
                  >
                    <td className={styles.expandCell}>
                      <button 
                        className={styles.expandButton}
                        onClick={() => toggleStudentExpansion(student._id)}
                        aria-label={expandedStudents.has(student._id) ? "Свернуть" : "Развернуть"}
                      >
                        {expandedStudents.has(student._id) ? '−' : '+'}
                      </button>
                    </td>
                    <td className={styles.nameCell}>
                      <div className={styles.studentName}>
                        {student.lastName} {student.firstName}
                      </div>
                      <div className={styles.studentPhone}>{student.phoneNumber || '—'}</div>
                    </td>
                    <td className={styles.balanceCell}>
                      <span className={student.parent.balance > 0 ? styles.positiveBalance : styles.negativeBalance}>
                        {student.parent.balance}
                      </span>
                    </td>
                    <td className={styles.ageCell}>
                      {calculateAge(student.birthDate)}
                    </td>
                    <td className={styles.birthDateCell}>
                      {formatDate(student.birthDate)}
                    </td>
                    <td 
                      className={styles.statusCell}
                      onClick={() => openStatusEdit(student._id, student.status)}
                    >
                      <span className={`${styles.statusBadge} ${styles[student.status]}`}>
                        {getStatusText(student.status)}
                      </span>
                    </td>
                    <td>
                      <div className={styles.actionButtons}>
                        <button className={styles.editButton}>
                          Редактировать
                        </button>
                      </div>
                    </td>
                  </tr>
                  
                  {/* Expanded details row */}
                  {expandedStudents.has(student._id) && (
                    <tr className={styles.detailsRow}>
                      <td colSpan={7}>
                        <div className={styles.detailsContainer}>
                          {/* Student details */}
                          <div className={styles.studentDetails}>
                            <h3>Информация об ученике</h3>
                            <div className={styles.detailsGrid}>
                              <div className={styles.detailGroup}>
                                <h4>Основная информация</h4>
                                <p><strong>Полное имя:</strong> {student.lastName} {student.firstName}</p>
                                <p><strong>Пол:</strong> {student.gender === 'male' ? 'Мальчик' : 'Девочка'}</p>
                                <p><strong>Дата рождения:</strong> {formatDate(student.birthDate)}</p>
                                <p><strong>Возраст:</strong> {calculateAge(student.birthDate)}</p>
                                {student.phoneNumber && <p><strong>Телефон:</strong> {student.phoneNumber}</p>}
                                <p><strong>Адрес:</strong> {student.address}</p>
                                {student.school && <p><strong>Школа:</strong> {student.school}</p>}
                              </div>
                              
                              <div className={styles.detailGroup}>
                                <h4>Разрешения</h4>
                                <p>
                                  <strong>Фото/видеосъемка:</strong> 
                                  <span className={`${styles.permissionBadge} ${student.photoPermission ? styles.allowed : styles.denied}`}>
                                    {student.photoPermission ? 'Разрешено' : 'Запрещено'}
                                  </span>
                                </p>
                                <p>
                                  <strong>Публикация фото/видео:</strong> 
                                  <span className={`${styles.permissionBadge} ${
                                    student.photoPostingPermission === 'Везде' 
                                      ? styles.allowed 
                                      : student.photoPostingPermission === 'В чатах' 
                                        ? styles.partial
                                        : styles.denied
                                  }`}>
                                    {student.photoPostingPermission}
                                  </span>
                                </p>
                                <p>
                                  <strong>Самостоятельное посещение:</strong> 
                                  <span className={`${styles.permissionBadge} ${student.independentVisitPermission ? styles.allowed : styles.denied}`}>
                                    {student.independentVisitPermission ? 'Разрешено' : 'Запрещено'}
                                  </span>
                                </p>
                              </div>
                              
                              {(student.medicalConditions?.length || student.allergies?.length || student.additionalInfo) && (
                                <div className={styles.detailGroup}>
                                  <h4>Дополнительная информация</h4>
                                  {student.medicalConditions && student.medicalConditions.length > 0 && (
                                    <div>
                                      <p><strong>Медицинские особенности:</strong></p>
                                      <ul>
                                        {student.medicalConditions.map((condition, index) => (
                                          <li key={index}>{condition}</li>
                                        ))}
                                      </ul>
                                    </div>
                                  )}
                                  
                                  {student.allergies && student.allergies.length > 0 && (
                                    <div>
                                      <p><strong>Аллергии:</strong></p>
                                      <ul>
                                        {student.allergies.map((allergy, index) => (
                                          <li key={index}>{allergy}</li>
                                        ))}
                                      </ul>
                                    </div>
                                  )}
                                  
                                  {student.additionalInfo && (
                                    <p><strong>Дополнительные заметки:</strong> {student.additionalInfo}</p>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                          
                          {/* Parent information */}
                          <div className={styles.parentInfo}>
                            <h3>Родитель</h3>
                            <div className={styles.parentCard}>
                              <div className={styles.parentData}>
                                <p className={styles.parentName}>{student.parent.surname} {student.parent.name}</p>
                                <p className={styles.parentPhone}>{student.parent.phone}</p>
                                <p className={styles.parentBalance}>
                                  Баланс: 
                                  <span className={student.parent.balance > 0 ? styles.positiveBalance : styles.negativeBalance}>
                                    {student.parent.balance} ₽
                                  </span>
                                </p>
                                <p className={styles.parentStatus}>
                                  Статус: 
                                  <span className={`${styles.statusBadge} ${styles[student.parent.status]}`}>
                                    {getStatusText(student.parent.status)}
                                  </span>
                                </p>
                              </div>
                              <div className={styles.parentActions}>
                                <Button
                                  state="secondary"
                                  label="Подробнее"
                                  onClick={() => navigateToParent(student.parent._id)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))
            )}
          </tbody>
        </table>
      </div>
      
      {/* Edit popup */}
      <EditPopup
        title="Изменение статуса"
        isOpen={editState.isOpen}
        onClose={closeEditPopup}
        onSave={handleSaveEdit}
      >
        {renderEditPopupContent()}
      </EditPopup>
    </div>
  );
};