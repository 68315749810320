import React from 'react';
import classNames from 'classnames';
import styles from './UpperButton.module.scss';

export type UpperButtonVariant = 'dark' | 'white';

interface UpperButtonProps {
  label: string;
  iconSrc?: string;
  variant?: UpperButtonVariant;
  onClick?: () => void;
  className?: string;
}

export const UpperButton: React.FC<UpperButtonProps> = ({
  label,
  iconSrc,
  variant = 'dark',
  onClick,
  className
}) => {
  return (
    <button
      className={classNames(
        styles.upperButton,
        styles[variant],
        className
      )}
      onClick={onClick}
      type="button"
    >
      {iconSrc && (
        <span className={styles.icon}>
          <img src={iconSrc} alt="" />
        </span>
      )}
      <span className={styles.label}>{label}</span>
    </button>
  );
};