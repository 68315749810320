import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input } from '../../components/atoms/Input/Input';
import { Button } from '../../components/atoms/Button/Button';
import { Switch } from '../../components/atoms/Switch/Switch';
import { ReactComponent as GreetingSVG } from '../../content/greeting.svg';
import { RegisterFormData, validateRegisterForm, hasErrors } from '../../utils/validation';
import styles from './AuthPages.module.scss';

export const RegisterPage: React.FC = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState<string | null>(null);
  
  const [formData, setFormData] = useState<RegisterFormData>({
    phone: '',
    password: '',
    confirmPassword: '',
    parentType: 'mother',
    name: '',
    surname: '',
    age: ''
  });

  const [errors, setErrors] = useState<Record<keyof RegisterFormData, string | null>>({
    phone: null,
    password: null,
    confirmPassword: null,
    parentType: null,
    name: null,
    surname: null,
    age: null
  });

  const validateStep1 = () => {
    const validationErrors = validateRegisterForm(formData);
    const step1Fields = ['phone', 'password', 'confirmPassword'];
    
    const step1Errors = Object.fromEntries(
      Object.entries(validationErrors).map(([key, value]) => [
        key,
        step1Fields.includes(key) ? value : null
      ])
    ) as Record<keyof RegisterFormData, string | null>;

    setErrors(step1Errors);
    return !hasErrors(step1Errors);
  };

  const validateStep2 = () => {
    const validationErrors = validateRegisterForm(formData);
    const step2Fields = ['name', 'surname', 'age'];
    
    const step2Errors = Object.fromEntries(
      Object.entries(validationErrors).map(([key, value]) => [
        key,
        step2Fields.includes(key) ? value : null
      ])
    ) as Record<keyof RegisterFormData, string | null>;

    setErrors(step2Errors);
    return !hasErrors(step2Errors);
  };

  const handleNextStep = async () => {
    if (validateStep1()) {
      // Проверяем, существует ли пользователь с таким номером телефона
      // и сброшен ли его пароль
      try {
        setIsLoading(true);
        setApiError(null);
        
        const normalizedPhone = '8' + formData.phone.replace(/\D/g, '');
        
        const response = await fetch('/api/lessons/auth/check-user', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ 
            phone: normalizedPhone
          }),
        });
        
        const data = await response.json();
        
        if (data.success && data.passwordReset) {
          // Если пароль сброшен, обновляем пароль и автоматически входим
          await handleResetUserLogin();
        } else {
          // Продолжаем обычный процесс регистрации
          setStep(2);
        }
      } catch (error) {
        console.error('Check user error:', error);
        // Продолжаем со следующим шагом даже при ошибке проверки
        setStep(2);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleResetUserLogin = async () => {
    try {
      setIsLoading(true);
      setApiError(null);
      
      const normalizedPhone = '8' + formData.phone.replace(/\D/g, '');
      
      const response = await fetch('/api/lessons/auth/update-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          phone: normalizedPhone,
          password: formData.password
        }),
      });
      
      const data = await response.json();
      
      if (data.success) {
        // Сохраняем токен и данные пользователя, как при входе
        localStorage.setItem('lessonsAuthToken', data.token);
        localStorage.setItem('lessonsUserData', JSON.stringify(data.user));
        navigate('/lessons/home');
      } else {
        setApiError(data.error || 'Ошибка обновления пароля');
        setStep(2); // При ошибке продолжаем обычную регистрацию
      }
    } catch (error) {
      console.error('Password update error:', error);
      setApiError('Произошла ошибка при обновлении пароля');
      setStep(2); // При ошибке продолжаем обычную регистрацию
    } finally {
      setIsLoading(false);
    }
  };

  const handlePreviousStep = () => {
    if (step === 2) {
      setStep(1);
    } else {
      navigate('/lessons/login');
    }
  };

  const handleRegister = async () => {
    if (!validateStep2()) return;
  
    setIsLoading(true);
    setApiError(null);
    
    try {
      const normalizedPhone = '8' + formData.phone.replace(/\D/g, '');
      
      // Create a copy of form data without confirmPassword
      const { confirmPassword, ...registrationData } = formData;
      
      const response = await fetch('/api/lessons/auth/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...registrationData,
          phone: normalizedPhone
        }),
      });
  
      const data = await response.json();
      
      if (data.success) {
        // Save both token and user data, just like in login
        localStorage.setItem('lessonsAuthToken', data.token);
        localStorage.setItem('lessonsUserData', JSON.stringify(data.user));
        navigate('/lessons/home');
      } else {
        setApiError(data.error || 'Ошибка регистрации');
        setErrors(prev => ({
          ...prev,
          [data.field || 'phone']: data.error || 'Ошибка регистрации'
        }));
      }
    } catch (error) {
      console.error('Registration error:', error);
      setApiError('Произошла ошибка при регистрации. Попробуйте позже.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && !isLoading) {
      if (step === 1) {
        handleNextStep();
      } else {
        handleRegister();
      }
    }
  };

  if (step === 1) {
    return (
      <div className={styles.authCard}>
        <GreetingSVG className={styles.greetingIcon} />
        <h1>Давайте знакомиться?</h1>

        {apiError && (
          <div className={styles.errorMessage}>
            {apiError}
          </div>
        )}

        <div className={styles.inputGroup}>
          <Input
            type="phone"
            value={formData.phone}
            onChange={(value) => {
              setFormData({...formData, phone: value});
              if (errors.phone) setErrors({...errors, phone: null});
              if (apiError) setApiError(null);
            }}
            state={errors.phone ? "error" : "inactive"}
            error={errors.phone}
            onKeyPress={handleKeyPress}
            disabled={isLoading}
          />
        </div>

        <div className={styles.inputGroup}>
          <Input
            type="password"
            value={formData.password}
            onChange={(value) => {
              setFormData({...formData, password: value});
              if (errors.password) setErrors({...errors, password: null});
              if (apiError) setApiError(null);
            }}
            state={errors.password ? "error" : "inactive"}
            label="Придумайте пароль"
            upperLabel="Придумайте пароль"
            error={errors.password}
            onKeyPress={handleKeyPress}
            disabled={isLoading}
          />
        </div>

        <div className={styles.inputGroup}>
          <Input
            type="password"
            value={formData.confirmPassword}
            onChange={(value) => {
              setFormData({...formData, confirmPassword: value});
              if (errors.confirmPassword) setErrors({...errors, confirmPassword: null});
              if (apiError) setApiError(null);
            }}
            state={errors.confirmPassword ? "error" : "inactive"}
            label="Повторите пароль"
            upperLabel="Повторите пароль"
            error={errors.confirmPassword}
            onKeyPress={handleKeyPress}
            disabled={isLoading}
          />
        </div>

        <div className={styles.buttons}>
          <Button 
            state="active"
            label={isLoading ? "Подождите..." : "Продолжить"}
            onClick={handleNextStep}
            disabled={isLoading || !formData.phone || !formData.password || !formData.confirmPassword}
          />
          <Button 
            state="secondary"
            label="Вернуться"
            onClick={handlePreviousStep}
            disabled={isLoading}
          />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.authCard}>
      <GreetingSVG className={styles.greetingIcon} />
      <h1>Давайте познакомимся!</h1>

      {apiError && (
        <div className={styles.errorMessage}>
          {apiError}
        </div>
      )}

      <div className={styles.inputGroup}>
        <Switch
          options={[
            { id: 'mother', label: 'Я мама' },
            { id: 'father', label: 'Я папа' }
          ]}
          value={formData.parentType}
          onChange={(value) => setFormData({...formData, parentType: value as 'mother' | 'father'})}
          disabled={isLoading}
        />
      </div>

      <div className={styles.inputGroup}>
        <Input
          type="text"
          value={formData.name}
          onChange={(value) => {
            setFormData({...formData, name: value});
            if (errors.name) setErrors({...errors, name: null});
            if (apiError) setApiError(null);
          }}
          state={errors.name ? "error" : "inactive"}
          label="Ваше имя"
          error={errors.name}
          onKeyPress={handleKeyPress}
          disabled={isLoading}
        />
      </div>

      <div className={styles.inputGroup}>
        <Input
          type="text"
          value={formData.surname}
          onChange={(value) => {
            setFormData({...formData, surname: value});
            if (errors.surname) setErrors({...errors, surname: null});
            if (apiError) setApiError(null);
          }}
          state={errors.surname ? "error" : "inactive"}
          label="Ваша фамилия"
          error={errors.surname}
          onKeyPress={handleKeyPress}
          disabled={isLoading}
        />
      </div>

      <div className={styles.inputGroup}>
        <Input
          type="text"
          value={formData.age}
          onChange={(value) => {
            setFormData({...formData, age: value});
            if (errors.age) setErrors({...errors, age: null});
            if (apiError) setApiError(null);
          }}
          state={errors.age ? "error" : "inactive"}
          label="Ваш возраст"
          error={errors.age}
          onKeyPress={handleKeyPress}
          disabled={isLoading}
        />
      </div>

      <div className={styles.buttons}>
        <Button 
          className={styles.button}
          state="active"
          label={isLoading ? "Регистрация..." : "Зарегистрироваться"}
          onClick={handleRegister}
          disabled={isLoading || !formData.name || !formData.surname || !formData.age}
        />
        <Button 
          className={styles.button}
          state="secondary"
          label="Вернуться"
          onClick={handlePreviousStep}
          disabled={isLoading}
        />
      </div>
    </div>
  );
};