import React from 'react';
import styles from './Profile.module.scss';

export const Profile: React.FC = () => {
    return (
      <div>
        {/* Профиль преподавателя:
            - Личная информация
            - График работы
            - Настройки уведомлений
            - Изменение пароля */}
      </div>
    );
  };