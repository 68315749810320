import React from 'react';
import { Button } from '../../atoms/Button/Button';
import styles from './LessonCard.module.scss';

interface LessonCardProps {
  lesson: {
    _id: string;
    name: string;
    photo: string[];
    description: string;
    date: string;
    ageFrom: string | number;
    ageTo: string | number;
    startTime?: string;
    endTime?: string;
    startTimeHours?: number;
    startTimeMinutes?: number;
    endTimeHours?: number;
    endTimeMinutes?: number;
    studentsIds?: Record<string, any>;
    participantsLimit: number;
    price: number;
  };
  showAgeRange?: boolean;
  buttonText?: string;
  onButtonClick?: () => void;
}

export const LessonCard: React.FC<LessonCardProps> = ({
  lesson,
  showAgeRange = true,
  buttonText = 'Подробнее',
  onButtonClick,
}) => {
  const {
    name,
    photo,
    description,
    date,
    ageFrom,
    ageTo,
    startTime,
    endTime,
    startTimeHours,
    startTimeMinutes,
    endTimeHours,
    endTimeMinutes,
    studentsIds,
    participantsLimit,
    price,
  } = lesson;

  // Форматируем время из часов и минут, если startTime/endTime отсутствуют
  const formattedStartTime = startTime || 
    (typeof startTimeHours === 'number' && typeof startTimeMinutes === 'number' ? 
      `${String(startTimeHours).padStart(2, '0')}:${String(startTimeMinutes).padStart(2, '0')}` : 
      '00:00');
  
  const formattedEndTime = endTime || 
    (typeof endTimeHours === 'number' && typeof endTimeMinutes === 'number' ? 
      `${String(endTimeHours).padStart(2, '0')}:${String(endTimeMinutes).padStart(2, '0')}` : 
      '00:00');

  // Format date to show day, month, and day of week
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    
    // Форматируем дату в московском часовом поясе
    const options: Intl.DateTimeFormatOptions = { 
      day: 'numeric', 
      month: 'long', 
      timeZone: 'Europe/Moscow'
    };
    const formattedDate = new Intl.DateTimeFormat('ru-RU', options).format(date);
    
    // Получаем день недели в московском часовом поясе
    const weekdayOptions: Intl.DateTimeFormatOptions = { 
      weekday: 'short', 
      timeZone: 'Europe/Moscow'
    };
    const dayOfWeek = new Intl.DateTimeFormat('ru-RU', weekdayOptions).format(date).toUpperCase();
    
    return `${formattedDate} (${dayOfWeek})`;
  };

  // Calculate the number of enrolled students - safely handle undefined/null studentsIds
  const enrolledStudents = studentsIds ? Object.keys(studentsIds).length : 0;

  return (
    <div className={styles.lessonCard}>
      <div className={styles.imageContainer}>
        {photo && photo.length > 0 ? (
          <img src={photo[0]} alt={name} className={styles.image} />
        ) : (
          <div className={styles.imagePlaceholder}></div>
        )}
        <div className={styles.overlay}>
          <div className={styles.dateTimeSection}>
            <div className={styles.dateBlock}>
              {formatDate(date)}
            </div>
            <div className={styles.timeBlock}>
              {formattedStartTime} – {formattedEndTime}
            </div>
          </div>
          <div className={styles.infoSection}>
            {showAgeRange && (
              <div className={styles.ageBlock}>
                от {ageFrom} до {ageTo}
              </div>
            )}
            <div className={styles.priceBlock}>
              {price}₽
            </div>
          </div>
        </div>
      </div>
      <div className={styles.content}>
        <h3 className={styles.title}>{name}</h3>
        <p className={styles.studentLimit}>{enrolledStudents} учеников из {participantsLimit}</p>
        <p className={styles.description}>{description}</p>
      </div>
      <div className={styles.footer}>
        <Button
          className={styles.footerButton}
          state="secondary"
          label={buttonText}
          onClick={onButtonClick}
        />
      </div>
    </div>
  );
};