import React, { useState } from 'react';
import { TimeSlot } from '../../types';
import trashIcon from '../../icons/trash.svg';
import checkIcon from '../../icons/check.svg';
import './BookingCard.scss';

interface BookingCardProps {
  booking: {
    _id: string;
    userId: {
      name: string;
      phone?: string;
    };
    roomId: {
      name: string;
    };
    date?: string;
    timeSlots: TimeSlot[];
    type: 'individual' | 'group' | 'automatic';
    numberOfPeople: number;
    eventType: string;
    status: 'pending' | 'confirmed' | 'cancelled';
    totalPrice?: number;
  };
  onDelete?: (id: string) => void;
  onConfirm?: (id: string) => void;
  showDate?: boolean;
  showActions?: boolean;
  onPriceChange?: (id: string, price: string) => void;
  priceInput?: string;
  priceError?: string;
  onUpdatePrice?: (id: string, newPrice: number) => void;
}

const timeToMinutes = (time: string): number => {
  const [hours, minutes] = time.split(':').map(Number);
  return hours * 60 + minutes;
};

const normalizeTime = (time: string): string => {
  const [hours, minutes] = time.split(':').map(Number);
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
};

const formatTimeSlots = (timeSlots: TimeSlot[]): string => {
  if (!Array.isArray(timeSlots) || timeSlots.length === 0) return '';

  const sortedSlots = [...timeSlots].sort((a, b) => 
    timeToMinutes(a.start) - timeToMinutes(b.start)
  );

  const mergedSlots: { start: string; end: string }[] = [];
  let currentSlot = { ...sortedSlots[0] };

  for (let i = 1; i < sortedSlots.length; i++) {
    if (timeToMinutes(currentSlot.end) === timeToMinutes(sortedSlots[i].start)) {
      currentSlot.end = sortedSlots[i].end;
    } else {
      mergedSlots.push({ ...currentSlot });
      currentSlot = { ...sortedSlots[i] };
    }
  }
  mergedSlots.push(currentSlot);

  return mergedSlots
    .map(slot => `${normalizeTime(slot.start)}-${normalizeTime(slot.end)}`)
    .join(', ');
};

const formatDateKey = (dateString: string | undefined) => {
  if (!dateString) return '';
  
  const date = new Date(dateString);
  const utcDate = new Date(Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate()
  ));
  
  const day = utcDate.getUTCDate().toString().padStart(2, '0');
  const months = ['ЯНВ.', 'ФЕВ.', 'МАР.', 'АПР.', 'МАЯ', 'ИЮН.', 
    'ИЮЛ.', 'АВГ.', 'СЕНТ.', 'ОКТ.', 'НОЯБ.', 'ДЕК.'];
  const month = months[utcDate.getUTCMonth()];
  const year = utcDate.getUTCFullYear();
  const weekday = ['ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ'][utcDate.getUTCDay()];
  
  return `${day} ${month} ${year} (${weekday})`;
};

const getStatusLabel = (status: string) => {
  switch (status) {
    case 'confirmed':
      return 'Подтверждено';
    case 'pending':
      return 'На рассмотрении';
    case 'cancelled':
      return 'Отменено';
    default:
      return status;
  }
};

const getStatusClass = (status: string) => {
  switch (status) {
    case 'confirmed':
      return 'status-confirmed';
    case 'pending':
      return 'status-pending';
    case 'cancelled':
      return 'status-cancelled';
    default:
      return '';
  }
};

const BookingCard: React.FC<BookingCardProps> = ({
  booking,
  onDelete,
  onConfirm,
  showDate = false,
  showActions = false,
  onPriceChange,
  priceInput,
  priceError,
  onUpdatePrice
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedPrice, setEditedPrice] = useState(booking.totalPrice?.toString() || '');

  const handleCardClick = (e: React.MouseEvent) => {
    // Проверяем, что клик не был по элементам редактирования
    const target = e.target as HTMLElement;
    if (target.closest('.price-edit') || target.closest('.price-display') || 
        target.closest('.booking-actions')) {
      e.stopPropagation();
      return;
    }
    setIsExpanded(!isExpanded);
  };

  const handlePriceEdit = (e: React.MouseEvent) => {
    e.stopPropagation();
    const newPrice = parseFloat(editedPrice);
    if (newPrice > 0 && onUpdatePrice) {
      onUpdatePrice(booking._id, newPrice);
      setIsEditing(false);
    }
  };

  const handleEditClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsEditing(true);
  };

  const handleEditCancel = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsEditing(false);
    setEditedPrice(booking.totalPrice?.toString() || '');
  };

  return (
    <div 
      className={`booking-card ${isExpanded ? 'expanded' : ''}`} 
      data-type={booking.type}
      onClick={handleCardClick}
    >
      <div className="booking-info">
        <div className="user-info">
          <h3>{booking.userId.name}</h3>
          {(isExpanded || booking.status === 'pending') && booking.userId.phone && (
            <a 
              href={`tel:${booking.userId.phone}`} 
              className="phone"
              onClick={(e) => e.stopPropagation()}
            >
              {booking.userId.phone}
            </a>
          )}
        </div>
        
        <div className="booking-details">
          {showDate && booking.date && (
            <span className="date">{formatDateKey(booking.date)}</span>
          )}
          <span className="time">
            {formatTimeSlots(booking.timeSlots)}
          </span>
          <span className="room">Кабинет: {booking.roomId.name}</span>
          
          {(isExpanded || booking.status === 'pending') && booking.type !== 'automatic' && (
            <>
              <span className="type">
                {booking.type === 'group' ? 'Групповое' : 'Индивидуальное'} занятие
              </span>
              <span className="people">{booking.numberOfPeople} чел.</span>
              <span className="event-type">Мероприятие: {booking.eventType || 'Не указано'}</span>
            </>
          )}
          
          {booking.status === 'confirmed' && isExpanded && (
            <div className="price-section" onClick={(e) => e.stopPropagation()}>
              {isEditing ? (
                <div className="price-edit">
                  <input
                    type="number"
                    value={editedPrice}
                    onChange={(e) => setEditedPrice(e.target.value)}
                    className="price-input"
                  />
                  <button onClick={handlePriceEdit} className="save-price">
                    Сохранить
                  </button>
                  <button onClick={handleEditCancel} className="cancel-edit">
                    Отмена
                  </button>
                </div>
              ) : (
                <div className="price-display" onClick={handleEditClick}>
                  <span className="price">{booking.totalPrice} руб.</span>
                  <span className="edit-hint">(нажмите для редактирования)</span>
                </div>
              )}
            </div>
          )}
          
          {(isExpanded || booking.status === 'pending') && (
            <span className={`status ${getStatusClass(booking.status)}`}>
              {getStatusLabel(booking.status)}
            </span>
          )}
        </div>
      </div>

      {showActions && (
        <div className="booking-actions" onClick={(e) => e.stopPropagation()}>
          {booking.status === 'pending' ? (
            <>
              <div className="action-buttons">
                {onConfirm && (
                  <button 
                    className="confirm-button"
                    onClick={() => onConfirm(booking._id)}
                    disabled={!priceInput}
                  >
                    <img src={checkIcon} alt="Подтвердить" />
                  </button>
                )}
                {onDelete && (
                  <button 
                    className="delete-button"
                    onClick={() => onDelete(booking._id)}
                  >
                    <img src={trashIcon} alt="Удалить" />
                  </button>
                )}
              </div>
              {onPriceChange && (
                <div className="price-input-group">
                  <input
                    type="number"
                    placeholder="Цена"
                    value={priceInput}
                    onChange={(e) => onPriceChange(booking._id, e.target.value)}
                    className={priceError ? 'error' : ''}
                  />
                  {priceError && (
                    <span className="error-message">{priceError}</span>
                  )}
                </div>
              )}
            </>
          ) : (
            onDelete && (
              <div className="action-buttons">
                <button 
                  className="delete-button"
                  onClick={() => onDelete(booking._id)}
                >
                  <img src={trashIcon} alt="Удалить" />
                </button>
              </div>
            )
          )}
        </div>
      )}
    </div>
  );
};

export default BookingCard;