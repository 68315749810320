import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { LoginPage } from '../pages/auth/LoginPage';
import { RegisterPage } from '../pages/auth/RegisterPage';
import HomePage from '../pages/HomePage';
import { SettingsPage } from '../pages/features/settings/SettingsPage';
import { EditChild } from '../components/roles/parent/ChildContent/EditChild';

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const isAuthenticated = Boolean(localStorage.getItem('lessonsAuthToken'));
  
  if (!isAuthenticated) {
    return <Navigate to="/lessons/login" replace />;
  }

  return <>{children}</>;
};

export const LessonsRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/lessons">
        <Route path="login" element={<LoginPage />} />
        <Route path="register" element={<RegisterPage />} />
        <Route
          path="home"
          element={
            <ProtectedRoute>
              <HomePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="settings"
          element={
            <ProtectedRoute>
              <SettingsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="edit-child/:childId"
          element={
            <ProtectedRoute>
              <EditChild />
            </ProtectedRoute>
          }
        />
        <Route path="" element={<Navigate to="/lessons/login" replace />} />
      </Route>
    </Routes>
  );
};