import React from 'react';
import styles from './Clients.module.scss';

export const Clients: React.FC = () => {
    return (
      <div>
        {/* Управление клиентами:
            - База клиентов
            - Анализ активности
            - Программа лояльности
            - Работа с отзывами */}
      </div>
    );
  };