import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input } from '../../components/atoms/Input/Input';
import { Button } from '../../components/atoms/Button/Button';
import { ReactComponent as GreetingSVG } from '../../content/greeting.svg';
import styles from './AuthPages.module.scss';

export const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handlePhoneChange = (value: string) => {
    setPhone(value);
    if (error) setError(null);
  };

  const handlePasswordChange = (value: string) => {
    setPassword(value);
    if (error) setError(null);
  };

  const handleLogin = async () => {
    try {
      setIsLoading(true);
      setError(null);

      const normalizedPhone = '8' + phone.replace(/\D/g, '');

      const response = await fetch('/api/lessons/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          phone: normalizedPhone,
          password 
        }),
      });

      const data = await response.json();

      if (data.success) {
        localStorage.setItem('lessonsAuthToken', data.token);
        localStorage.setItem('lessonsUserData', JSON.stringify(data.user)); // Сохраняем данные пользователя
        navigate('/lessons/home');
      }
    } catch (error) {
      console.error('Login error:', error);
      setError('Произошла ошибка при входе. Попробуйте позже.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && !isLoading) {
      handleLogin();
    }
  };

  return (
    <div className={styles.authCard}>
      <GreetingSVG className={styles.greetingIcon} />
      <h1>Вход в личный кабинет</h1>
      
      {error && (
        <div className={styles.errorMessage}>
          {error}
        </div>
      )}

      <div className={styles.inputGroup}>
        <Input
          type="phone"
          value={phone}
          onChange={handlePhoneChange}
          state={error ? "error" : "inactive"}
          onKeyPress={handleKeyPress}
          disabled={isLoading}
        />
      </div>

      <div className={styles.inputGroup}>
        <Input
          type="password"
          value={password}
          onChange={handlePasswordChange}
          state={error ? "error" : "inactive"}
          label="Пароль"
          onKeyPress={handleKeyPress}
          disabled={isLoading}
        />
      </div>

      <div className={styles.buttons}>
        <Button 
          className={styles.button}
          state="active"
          label={isLoading ? "Вход..." : "Войти"}
          onClick={handleLogin}
          disabled={isLoading || !phone || !password}
        />
        <Button 
          className={styles.button}
          state="secondary"
          label="Регистрация"
          onClick={() => navigate('/lessons/register')}
          disabled={isLoading}
        />
      </div>
    </div>
  );
};