import React from 'react';
import styles from './Analytics.module.scss';

export const Analytics: React.FC = () => {
    return (
      <div>
        {/* Аналитика:
            - Общая статистика центра
            - Анализ эффективности занятий
            - Анализ работы преподавателей
            - Прогнозы и тренды */}
      </div>
    );
  };