import React from 'react';
import { Analytics } from '../../roles/director/Analytics';
import { Finances } from '../../roles/director/Finances';
import { Clients } from '../../roles/director/Clients';
import { Control } from '../../roles/director/Control';
import { Employees } from '../../roles/director/Employees';
import { Settings } from '../../roles/director/Settings';

interface DirectorContentProps {
  activeTab: string;
}

export const DirectorContent: React.FC<DirectorContentProps> = ({ activeTab }) => {
  switch (activeTab) {
    case 'analytics':
      return <Analytics />;
    case 'finances':
      return <Finances />;
    case 'clients':
      return <Clients />;
    case 'control':
      return <Control />;
    case 'employees':
      return <Employees />;
    case 'settings':
      return <Settings />;
    default:
      return null;
  }
};