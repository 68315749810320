import React from 'react';
import styles from './Accounting.module.scss';

export const Accounting: React.FC = () => {
    return (
      <div>
        {/* Доходы/Расходы:
            - Учёт доходов
            - Учёт расходов
            - Формирование отчётов
            - Экспорт данных */}
      </div>
    );
  };