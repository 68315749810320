import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Lessons } from '../../roles/admin/Lessons';
import { Enrollments } from '../../roles/admin/Enrollments';
import { Groups } from '../../roles/admin/Groups';
import { Documents } from '../../roles/admin/Documents';
import { Students } from '../../roles/admin/Students';
import { Accounting } from '../../roles/admin/Accounting';
import { Clients } from '../../roles/admin/Clients';

interface AdminContentProps {
  activeTab: string;
}

export const AdminContent: React.FC<AdminContentProps> = ({ activeTab }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  
  // Handle URL parameters for navigation between components
  useEffect(() => {
    // If we have a childId parameter but we're not on the students tab,
    // we should redirect to the students tab
    const childId = searchParams.get('childId');
    if (childId && activeTab !== 'students') {
      setSearchParams({ tab: 'students', childId });
    }
    
    // If we have a parentId parameter but we're not on the clients tab,
    // we should redirect to the clients tab
    const parentId = searchParams.get('parentId');
    if (parentId && activeTab !== 'clients') {
      setSearchParams({ tab: 'clients', parentId });
    }
  }, [activeTab, searchParams, setSearchParams]);
  
  switch (activeTab) {
    case 'lessons':
      return <Lessons />;
    case 'enrollments':
      return <Enrollments />;
    case 'groups':
      return <Groups />;
    case 'documents':
      return <Documents />;
    case 'students':
      return <Students />;
    case 'accounting':
      return <Accounting />;
    case 'clients':
      return <Clients />;
    default:
      return null;
  }
};