import React from 'react';
import { TabButton } from '../../atoms/TabButton/TabButton';
import { getRoleTabsConfig } from '../../../config/tabs';

interface TabsNavigationProps {
  role: 'parent' | 'teacher' | 'admin' | 'director';
  activeTab: string;
  onTabChange: (tab: string) => void;
  children?: Array<{id: string; name: string}>; // Для родительского интерфейса
}

export const TabsNavigation: React.FC<TabsNavigationProps> = ({ 
  role, 
  activeTab, 
  onTabChange,
  children 
}) => {
  const tabs = getRoleTabsConfig(role);

  // Специальная обработка для родительского интерфейса
  if (role === 'parent') {
    return (
      <div className="tabs">
        {/* Вкладки для каждого ребенка */}
        {children?.map((child) => (
          <TabButton
            key={child.id}
            state={activeTab === child.id ? 'active-tab' : 'inactive-tab'}
            label={child.name}
            onClick={() => onTabChange(child.id)}
          />
        ))}
        
        {/* Кнопка добавления ребенка */}
        <TabButton
          state="new-kid"
          label="Добавить ребёнка +"
          onClick={() => onTabChange('add-child')}
        />

        {/* Вкладка истории */}
        <TabButton
          state={activeTab === 'history' ? 'active-tab' : 'inactive-tab'}
          label="История"
          onClick={() => onTabChange('history')}
        />
      </div>
    );
  }

  // Для остальных ролей стандартное отображение вкладок
  return (
    <div className="tabs">
      {tabs.map((tab) => (
        <TabButton
          key={tab.id}
          state={activeTab === tab.id ? 'active-tab' : 'inactive-tab'}
          label={tab.label}
          onClick={() => onTabChange(tab.id)}
        />
      ))}
    </div>
  );
};