import React, { useState, useEffect } from 'react';
import '../styles/UserBookings.scss';

interface TimeSlot {
  start: string;
  end: string;
}

interface UserBookingsProps {
  userId: string;
  user: { name: string };
}

const timeToMinutes = (time: string): number => {
  const [hours, minutes] = time.split(':').map(Number);
  return hours * 60 + minutes;
};

const normalizeTime = (time: string): string => {
  const [hours, minutes] = time.split(':').map(Number);
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
};

const formatTimeSlots = (timeSlots: TimeSlot[]): string => {
  if (!Array.isArray(timeSlots) || timeSlots.length === 0) {
    return '';
  }

  // Сортируем слоты по времени начала
  const sortedSlots = [...timeSlots].sort((a, b) => 
    timeToMinutes(a.start) - timeToMinutes(b.start)
  );

  const mergedSlots: { start: string; end: string }[] = [];
  let currentSlot = { ...sortedSlots[0] };

  for (let i = 1; i < sortedSlots.length; i++) {
    if (timeToMinutes(currentSlot.end) === timeToMinutes(sortedSlots[i].start)) {
      // Слоты можно объединить
      currentSlot.end = sortedSlots[i].end;
    } else {
      // Слоты нельзя объединить
      mergedSlots.push({ ...currentSlot });
      currentSlot = { ...sortedSlots[i] };
    }
  }
  mergedSlots.push(currentSlot);

  // Форматируем результат с нормализацией времени
  return mergedSlots
    .map(slot => `${normalizeTime(slot.start)}-${normalizeTime(slot.end)}`)
    .join(', ');
};

interface Booking {
  _id: string;
  roomId: {
    name: string;
  };
  date: string;
  timeSlots: TimeSlot[];
  type: 'individual' | 'group';
  numberOfPeople: number;
  eventType: string;
  totalPrice?: number;
  status: 'pending' | 'confirmed';
}

const UserBookings: React.FC<UserBookingsProps> = ({ userId, user }) => {
  const [bookings, setBookings] = useState<Booking[]>([]);
  const [loading, setLoading] = useState(true);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [bookingToCancel, setBookingToCancel] = useState<Booking | null>(null);

  useEffect(() => {
    fetchUserBookings();
  }, [userId]);

  const fetchUserBookings = async () => {
    try {
      const response = await fetch(`/api/user-bookings/${userId}`);
      const data = await response.json();
      if (data.success) {
        // Проверим каждую бронь
        data.bookings.forEach((booking: Booking) => {
          //console.log('Booking ID:', booking._id, 'Status:', booking.status);
        });
        setBookings(data.bookings);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching user bookings:', error);
      setLoading(false);
    }
  };

  const handleCancelAttempt = (booking: Booking) => {
    setBookingToCancel(booking);
    setShowCancelModal(true);
  };

  const handleCancelBooking = async (bookingId: string) => {
    try {
      const response = await fetch(`/api/bookings/${bookingId}/cancel`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          userName: user.name
        }),
      });

      const data = await response.json();
      if (data.success) {
        fetchUserBookings();
      }
    } catch (error) {
      console.error('Error cancelling booking:', error);
    }
  };

  const formatDateKey = (dateString: string) => {
    const date = new Date(dateString);
    // Создаем новую дату в UTC
    const utcDate = new Date(Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate()
    ));
    
    const day = utcDate.getUTCDate().toString().padStart(2, '0');
    const months = [
      'ЯНВ.', 'ФЕВ.', 'МАР.', 'АПР.', 'МАЯ', 'ИЮН.', 
      'ИЮЛ.', 'АВГ.', 'СЕНТ.', 'ОКТ.', 'НОЯБ.', 'ДЕК.'
    ];
    const month = months[utcDate.getUTCMonth()];
    const year = utcDate.getUTCFullYear();
    const weekday = ['ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ'][utcDate.getUTCDay()];
    
    return `${day} ${month} ${year} (${weekday})`;
  };

  const groupBookingsByDate = (bookings: Booking[]) => {
    const grouped = bookings.reduce((acc, booking) => {
      const dateKey = formatDateKey(booking.date);
      if (!acc[dateKey]) {
        acc[dateKey] = [];
      }
      acc[dateKey].push(booking);
      return acc;
    }, {} as { [key: string]: Booking[] });

    // Сортируем даты
    return Object.entries(grouped)
      .sort((a, b) => new Date(a[1][0].date).getTime() - new Date(b[1][0].date).getTime());
  };

  if (loading) {
    return (
      <div className="loading">
        Загрузка броней...
      </div>
    );
  }

  const confirmedBookings = bookings.filter(booking => booking.status === 'confirmed');
  const pendingBookings = bookings.filter(booking => booking.status === 'pending');

  if (bookings.length === 0) {
    return (
      <div className="no-bookings">
        У вас пока нет броней.
      </div>
    );
  }

  return (
    <div className="user-bookings">
      {confirmedBookings.length > 0 && (
        <div className="confirmed-bookings">
          <h2>Подтверждённые брони</h2>
          {groupBookingsByDate(confirmedBookings).map(([dateKey, dateBookings]) => (
            <div key={dateKey} className="date-group">
              <h3 className="date-header">{dateKey}</h3>
              {dateBookings.map((booking) => (
                <div key={booking._id} className="booking-card">
                  <div className="booking-info">
                    <div className="room-info">
                      <h3>{booking.roomId.name}</h3>
                      <span className="status confirmed">Подтверждено</span>
                    </div>
                    
                    <div className="booking-details">
                      <span className="time">
                        {formatTimeSlots(booking.timeSlots)}
                      </span>
                      <span className="type">
                        {booking.type === 'group' ? 'Групповое' : 'Индивидуальное'} занятие
                      </span>
                      <span className="people">{booking.numberOfPeople} чел.</span>
                      {booking.eventType && (
                        <span className="event-type">{booking.eventType}</span>
                      )}
                      {booking.totalPrice && (
                        <span className="price">{booking.totalPrice} ₽</span>
                      )}
                    </div>
                    <div className="booking-actions">
                    <button 
                      className="cancel-button"
                      onClick={() => handleCancelAttempt(booking)}
                    >
                      Отменить
                    </button>
                  </div>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      )}

      {pendingBookings.length > 0 && (
        <div className="pending-bookings">
          <h2>Ожидают подтверждения</h2>
          {groupBookingsByDate(pendingBookings).map(([dateKey, dateBookings]) => (
            <div key={dateKey} className="date-group">
              <h3 className="date-header">{dateKey}</h3>
              {dateBookings.map((booking) => (
                <div key={booking._id} className="booking-card">
                  <div className="booking-info">
                    <div className="room-info">
                      <h3>{booking.roomId.name}</h3>
                      <span className="status pending">Ожидает подтверждения</span>
                    </div>
                    
                    <div className="booking-details">
                      <span className="time">
                        {formatTimeSlots(booking.timeSlots)}
                      </span>
                      <span className="type">
                        {booking.type === 'group' ? 'Групповое' : 'Индивидуальное'} занятие
                      </span>
                      <span className="people">{booking.numberOfPeople} чел.</span>
                      {booking.eventType && (
                        <span className="event-type">{booking.eventType}</span>
                      )}
                    </div>
                    <div className="booking-actions">
                      <button 
                        className="cancel-button"
                        onClick={() => handleCancelAttempt(booking)}
                      >
                        Отменить
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      )}

      {showCancelModal && bookingToCancel && (
        <div className="cancel-modal-overlay">
          <div className="cancel-modal">
            <p>Вы точно хотите отменить бронь на {formatDateKey(bookingToCancel.date)}?</p>
            <div className="cancel-modal-actions">
              <button 
                className="confirm-cancel-button"
                onClick={() => {
                  handleCancelBooking(bookingToCancel._id);
                  setShowCancelModal(false);
                  setBookingToCancel(null);
                }}
              >
                Подтвердить
              </button>
              <button 
                className="cancel-cancel-button"
                onClick={() => {
                  setShowCancelModal(false);
                  setBookingToCancel(null);
                }}
              >
                Отмена
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserBookings;