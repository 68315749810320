import React from 'react';
import classNames from 'classnames';
import styles from './Button.module.scss';

export type ButtonState = 'inactive' | 'active' | 'secondary' | 'unnoticeable' | 'agressive';

interface ButtonProps {
  state: ButtonState;
  className?: string;
  icon?: React.ReactNode;
  label?: string;
  onClick?: () => void;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  fullWidth?: boolean;
}

export const Button: React.FC<ButtonProps> = ({
  state,
  className,
  icon,
  label,
  onClick,
  disabled = false,
  type = 'button',
  fullWidth = false,
}) => {
  const getDefaultLabel = () => {
    switch (state) {
      case 'active':
      case 'inactive':
      case 'secondary':
        return label || 'Сделать заказ';
      case 'agressive':
        return label || 'Отменить';
      case 'unnoticeable':
        return label || 'Выйти';
      default:
        return label;
    }
  };

  return (
    <button
      className={classNames(
        styles.button,
        styles[state],
        {
          [styles.withIcon]: !!icon,
          [styles.disabled]: disabled,
          [styles.fullWidth]: fullWidth
        },
        className
      )}
      onClick={onClick}
      disabled={disabled}
      type={type}
    >
      {icon && <span className={styles.icon}>{icon}</span>}
      <span className={styles.label}>{getDefaultLabel()}</span>
    </button>
  );
};