import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import styles from './Clients.module.scss';
import { Button } from '../../../atoms/Button/Button';
import { Input } from '../../../atoms/Input/Input';
import { Switch } from '../../../atoms/Switch/Switch';
import { EditPopup } from '../EditPopup/EditPopup';

interface SecondParent {
  name?: string;
  surname?: string;
  phone?: string;
  email?: string;
}

interface Child {
  _id: string;
  firstName: string;
  lastName: string;
  birthDate: string;
  phoneNumber?: string;
  status: string;
}

interface Parent {
  _id: string;
  name: string;
  surname: string;
  phone: string;
  email?: string;
  balance: number;
  status: string;
  address: string;
  communication: string;
  telegram?: string;
  secondParent?: SecondParent;
  children: Child[];
}

type EditType = 'balance' | 'status' | 'resetPassword' | null;

interface EditState {
  isOpen: boolean;
  type: EditType;
  parentId: string;
  currentValue: string | number | boolean;
  newValue: string | number | boolean;
}

export const Clients: React.FC = () => {
  const [searchParams] = useSearchParams();
  const highlightedParentId = searchParams.get('parentId');
  
  const [parents, setParents] = useState<Parent[]>([]);
  const [expandedParents, setExpandedParents] = useState<Set<string>>(new Set());
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [statusFilter, setStatusFilter] = useState<'all' | 'active' | 'inactive'>('all');
  
  // Состояние для редактирования
  const [editState, setEditState] = useState<EditState>({
    isOpen: false,
    type: null,
    parentId: '',
    currentValue: '',
    newValue: ''
  });
  
  // Загрузка данных родителей с сервера
  useEffect(() => {
    const fetchParents = async () => {
      try {
        setIsLoading(true);
        const response = await fetch('/api/lessons/parents');
        
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        
        const data = await response.json();
        
        if (data.success) {
          setParents(data.parents);
          
          // Если в URL указан ID родителя, раскрываем его строку
          if (highlightedParentId) {
            setExpandedParents(new Set([highlightedParentId]));
            
            // Прокручиваем к выделенному родителю
            setTimeout(() => {
              const element = document.getElementById(`parent-${highlightedParentId}`);
              if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'center' });
              }
            }, 500);
          }
        } else {
          console.error('Failed to fetch parents:', data.error);
        }
      } catch (error) {
        console.error('Error fetching parents:', error);
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchParents();
  }, [highlightedParentId]);
  
  // Переключение раскрытия строки родителя
  const toggleParentExpansion = (parentId: string) => {
    setExpandedParents(prev => {
      const newSet = new Set(prev);
      if (newSet.has(parentId)) {
        newSet.delete(parentId);
      } else {
        newSet.add(parentId);
      }
      return newSet;
    });
  };
  
  // Форматирование даты рождения
  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    return date.toLocaleDateString('ru-RU', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  };
  
  // Расчет возраста
  const calculateAge = (birthDateString: string): string => {
    const birthDate = new Date(birthDateString);
    const today = new Date();
    
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    
    // Склонение слова "год"
    const lastDigit = age % 10;
    const lastTwoDigits = age % 100;
    
    let years = 'лет';
    if (lastDigit === 1 && lastTwoDigits !== 11) {
      years = 'год';
    } else if ([2, 3, 4].includes(lastDigit) && ![12, 13, 14].includes(lastTwoDigits)) {
      years = 'года';
    }
    
    return `${age} ${years}`;
  };
  
  // Переход к детальной странице ребенка
  const navigateToChild = (childId: string) => {
    // Переходим на вкладку со студентами и передаем ID выбранного ребенка
    window.location.href = `/lessons/home?tab=students&childId=${childId}`;
  };

  // Сортировка родителей по приоритетам
  const sortParents = (parentsArray: Parent[]) => {
    return [...parentsArray].sort((a, b) => {
      // Функция определения приоритета:
      // 0: Выделенный родитель
      // 1: активные с детьми
      // 2: активные без детей
      // 3: неактивные с детьми
      // 4: неактивные без детей
      const getPriority = (parent: Parent): number => {
        if (parent._id === highlightedParentId) return 0; // Высший приоритет
        const isActive = parent.status === 'active';
        const hasChildren = parent.children && parent.children.length > 0;
        
        if (isActive && hasChildren) return 1;
        if (isActive && !hasChildren) return 2;
        if (!isActive && hasChildren) return 3;
        return 4; // неактивные без детей
      };
      
      return getPriority(a) - getPriority(b);
    });
  };

  // Фильтрация родителей по поисковому запросу и статусу
  const filteredParents = sortParents(parents).filter(parent => {
    // Фильтр по статусу
    if (statusFilter !== 'all' && parent.status !== statusFilter) {
      return false;
    }
    
    // Поисковый фильтр
    if (searchQuery) {
      const query = searchQuery.toLowerCase();
      return (
        parent.name.toLowerCase().includes(query) ||
        parent.surname.toLowerCase().includes(query) ||
        parent.phone.toLowerCase().includes(query) ||
        parent.address.toLowerCase().includes(query) ||
        (parent.email && parent.email.toLowerCase().includes(query)) ||
        (parent.telegram && parent.telegram.toLowerCase().includes(query)) ||
        (parent.secondParent?.name && parent.secondParent.name.toLowerCase().includes(query)) ||
        (parent.secondParent?.surname && parent.secondParent.surname.toLowerCase().includes(query)) ||
        (parent.secondParent?.phone && parent.secondParent.phone.toLowerCase().includes(query))
      );
    }
    
    return true;
  });
  
  // Получение текста статуса
  const getStatusText = (status: string): string => {
    switch (status) {
      case 'active': return 'Активен';
      case 'inactive': return 'Неактивен';
      case 'pending': return 'Ожидание';
      default: return status;
    }
  };
  
  // Обработчики открытия попапов редактирования
  const openBalanceEdit = (parentId: string, currentBalance: number) => {
    setEditState({
      isOpen: true,
      type: 'balance',
      parentId,
      currentValue: currentBalance,
      newValue: currentBalance
    });
  };
  
  const openStatusEdit = (parentId: string, currentStatus: string) => {
    setEditState({
      isOpen: true,
      type: 'status',
      parentId,
      currentValue: currentStatus,
      newValue: currentStatus
    });
  };

  // Обработчик открытия попапа сброса пароля
  const openResetPasswordEdit = (parentId: string) => {
    setEditState({
      isOpen: true,
      type: 'resetPassword',
      parentId,
      currentValue: '',
      newValue: ''
    });
  };
  
  // Закрытие попапа редактирования
  const closeEditPopup = () => {
    setEditState({
      isOpen: false,
      type: null,
      parentId: '',
      currentValue: '',
      newValue: ''
    });
  };
  
  // Обработка сохранения изменений
  const handleSaveEdit = async (reason: string) => {
    if (!editState.type || !editState.parentId) return;
    
    try {
      if (editState.type === 'resetPassword') {
        // Обработка сброса пароля
        const response = await fetch(`/api/lessons/parents/${editState.parentId}/reset-password`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('lessonsAuthToken')}`
          },
          body: JSON.stringify({ reason })
        });

        if (!response.ok) {
          throw new Error('Failed to reset password');
        }

        const result = await response.json();
        
        if (result.success) {
          closeEditPopup();
          alert('Попросите пользователя ещё раз зарегистрироваться по тому же номеру телефона');
        }
      } else {
        // Обработка других типов изменений (баланс, статус)
        const url = `/api/lessons/parents/${editState.parentId}/${editState.type}`;
        const response = await fetch(url, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('lessonsAuthToken')}`
          },
          body: JSON.stringify({
            [editState.type]: editState.newValue,
            reason
          })
        });
        
        if (!response.ok) {
          throw new Error('Failed to update');
        }
        
        const result = await response.json();
        
        if (result.success) {
          // Обновление локального состояния
          setParents(prev => 
            prev.map(parent => 
              parent._id === editState.parentId 
                ? { 
                    ...parent, 
                    [editState.type as string]: editState.newValue
                  } 
                : parent
            )
          );
          
          closeEditPopup();
        }
      }
    } catch (error) {
      console.error(`Error updating ${editState.type}:`, error);
      // Добавить обработку ошибок в UI
    }
  };
  
  // Обработка изменения значений в попапе
  const handleEditValueChange = (value: string | number | boolean) => {
    setEditState(prev => ({
      ...prev,
      newValue: value
    }));
  };
  
  // Рендер содержимого попапа в зависимости от типа редактирования
  const renderEditPopupContent = () => {
    switch (editState.type) {
      case 'balance':
        return (
          <div className={styles.balanceEditContent}>
            <p className={styles.currentValueLabel}>Текущий баланс: {editState.currentValue}</p>
            <Input
              type="text"
              value={editState.newValue.toString()}
              onChange={(value) => handleEditValueChange(Number(value))}
              label="Новый баланс"
              upperLabel="Новый баланс"
            />
          </div>
        );
      
      case 'status':
        return (
          <div className={styles.statusEditContent}>
            <p className={styles.currentValueLabel}>Текущий статус: {getStatusText(editState.currentValue as string)}</p>
            <div className={styles.statusSwitch}>
              <Switch
                options={[
                  { id: 'inactive', label: 'Неактивен' },
                  { id: 'active', label: 'Активен' }
                ]}
                value={editState.newValue as string}
                onChange={(value) => handleEditValueChange(value)}
              />
            </div>
          </div>
        );
      
      case 'resetPassword':
        return (
          <div className={styles.resetPasswordContent}>
            <p className={styles.resetPasswordWarning}>
              Вы собираетесь сбросить пароль для пользователя.
              После сброса пользователю потребуется повторно зарегистрироваться с тем же номером телефона.
            </p>
          </div>
        );
      
      default:
        return null;
    }
  };
  
  if (isLoading) {
    return (
      <div className={styles.loadingContainer}>
        <div className={styles.loadingSpinner}></div>
        <p>Загрузка данных о клиентах...</p>
      </div>
    );
  }
  
  return (
    <div className={styles.clientsContainer}>
      <div className={styles.controls}>
        <div className={styles.searchBox}>
          <Input
            type="text"
            value={searchQuery}
            onChange={setSearchQuery}
            label="Поиск по имени, телефону или адресу..."
            state="inactive"
          />
        </div>
        
        <div className={styles.filterButtons}>
          <button 
            className={`${styles.filterButton} ${statusFilter === 'all' ? styles.active : ''}`}
            onClick={() => setStatusFilter('all')}
          >
            Все
          </button>
          <button 
            className={`${styles.filterButton} ${statusFilter === 'active' ? styles.active : ''}`}
            onClick={() => setStatusFilter('active')}
          >
            Активные
          </button>
          <button 
            className={`${styles.filterButton} ${statusFilter === 'inactive' ? styles.active : ''}`}
            onClick={() => setStatusFilter('inactive')}
          >
            Неактивные
          </button>
        </div>
      </div>
      
      <div className={styles.tableContainer}>
        <table className={styles.clientsTable}>
          <thead>
            <tr>
              <th className={styles.expandColumn}></th>
              <th className={styles.nameColumn}>ФИО</th>
              <th className={styles.balanceColumn}>Баланс ₽</th>
              <th className={styles.statusColumn}>Статус</th>
              <th className={styles.actionsColumn}>Действия</th>
            </tr>
          </thead>
          <tbody>
            {filteredParents.length === 0 ? (
              <tr>
                <td colSpan={6} className={styles.noData}>
                  Нет данных для отображения
                </td>
              </tr>
            ) : (
              filteredParents.map(parent => (
                <React.Fragment key={parent._id}>
                  {/* Строка родителя */}
                  <tr 
                    id={`parent-${parent._id}`}
                    className={styles.parentRow}
                    style={parent._id === highlightedParentId ? {backgroundColor: 'rgba(126, 211, 33, 0.1)'} : undefined}
                  >
                    <td className={styles.expandCell}>
                      <button 
                        className={styles.expandButton}
                        onClick={() => toggleParentExpansion(parent._id)}
                        aria-label={expandedParents.has(parent._id) ? "Свернуть" : "Развернуть"}
                      >
                        {expandedParents.has(parent._id) ? '−' : '+'}
                      </button>
                    </td>
                    <td className={styles.nameCell}>
                      <div className={styles.parentName}>
                        {parent.surname} {parent.name}
                      </div>
                      <div className={styles.parentPhone}>{parent.phone}</div>
                    </td>
                    <td 
                      className={styles.balanceCell}
                      onClick={() => openBalanceEdit(parent._id, parent.balance)}
                    >
                      <span className={parent.balance > 0 ? styles.positiveBalance : styles.negativeBalance}>
                        {parent.balance}
                      </span>
                    </td>
                    <td 
                      className={styles.statusCell}
                      onClick={() => openStatusEdit(parent._id, parent.status)}
                    >
                      <span className={`${styles.statusBadge} ${styles[parent.status]}`}>
                        {getStatusText(parent.status)}
                      </span>
                    </td>
                    <td>
                      <div className={styles.actionButtons}>
                        <button className={styles.editButton}>
                          Редактировать
                        </button>
                        <button 
                          className={`${styles.editButton} ${styles.resetPasswordButton}`}
                          onClick={() => openResetPasswordEdit(parent._id)}
                        >
                          Сбросить пароль
                        </button>
                      </div>
                    </td>
                  </tr>
                  
                  {/* Строка с детьми (раскрывающаяся) */}
                  {expandedParents.has(parent._id) && (
                    <tr className={styles.childrenRow}>
                      <td colSpan={5}>
                        <div className={styles.childrenContainer}>
                          {/* Дополнительная информация о родителе */}
                          <div className={styles.extraParentInfo}>
                            <div className={styles.infoGroup}>
                              <h4>Контактная информация:</h4>
                              <p><strong>Адрес:</strong> {parent.address}</p>
                              <p><strong>Средство связи:</strong> {parent.communication}</p>
                              {parent.email && <p><strong>Email:</strong> {parent.email}</p>}
                              {parent.telegram && <p><strong>Telegram:</strong> {parent.telegram}</p>}
                            </div>
                            
                            {parent.secondParent && (parent.secondParent.name || parent.secondParent.surname || parent.secondParent.phone) && (
                              <div className={styles.infoGroup}>
                                <h4>Второй родитель:</h4>
                                {(parent.secondParent.name || parent.secondParent.surname) && (
                                  <p><strong>ФИО:</strong> {parent.secondParent.surname || ''} {parent.secondParent.name || ''}</p>
                                )}
                                {parent.secondParent.phone && (
                                  <p><strong>Телефон:</strong> {parent.secondParent.phone}</p>
                                )}
                              </div>
                            )}
                          </div>
                          
                          <h4 className={styles.childrenHeader}>Дети:</h4>
                          
                          {parent.children.length === 0 ? (
                            <p className={styles.noChildren}>У этого родителя нет детей</p>
                          ) : (
                            <table className={styles.childrenTable}>
                              <thead>
                                <tr>
                                  <th>ФИО</th>
                                  <th>Возраст</th>
                                  <th>Дата рождения</th>
                                  <th>Телефон</th>
                                  <th>Статус</th>
                                  <th>Действия</th>
                                </tr>
                              </thead>
                              <tbody>
                                {parent.children.map(child => (
                                  <tr key={child._id} className={styles.childRow}>
                                    <td className={styles.childName}>
                                      {child.lastName} {child.firstName}
                                    </td>
                                    <td className={styles.childAge}>
                                      {calculateAge(child.birthDate)}
                                    </td>
                                    <td className={styles.childBirthDate}>
                                      {formatDate(child.birthDate)}
                                    </td>
                                    <td className={styles.childPhone}>
                                      {child.phoneNumber || '—'}
                                    </td>
                                    <td className={styles.childStatus}>
                                      <span className={`${styles.statusBadge} ${styles[child.status]}`}>
                                        {getStatusText(child.status)}
                                      </span>
                                    </td>
                                    <td className={styles.childActions}>
                                      <Button
                                        state="secondary"
                                        label="Подробнее"
                                        onClick={() => navigateToChild(child._id)}
                                      />
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          )}
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))
            )}
          </tbody>
        </table>
      </div>
      
      {/* Попап для редактирования */}
      <EditPopup
        title={
          editState.type === 'balance'
            ? "Изменение баланса"
            : editState.type === 'status'
            ? "Изменение статуса"
            : editState.type === 'resetPassword'
            ? "Сброс пароля"
            : "Редактирование"
        }
        isOpen={editState.isOpen}
        onClose={closeEditPopup}
        onSave={handleSaveEdit}
      >
        {renderEditPopupContent()}
      </EditPopup>
    </div>
  );
};