// Валидация телефона
export const validatePhone = (phone: string): string | null => {
  console.log('Validating phone:', phone);
  // Нормализуем телефон для проверки (убираем все кроме цифр)
  const normalizedPhone = phone.replace(/\D/g, '');
  console.log('Normalized phone:', normalizedPhone);
  
  // Проверяем длину после нормализации
  if (normalizedPhone.length !== 10) {
      console.log('Invalid length. Expected 10, got:', normalizedPhone.length);
      return 'Неверный формат телефона';
  }
  return null;
};

// Валидация пароля
export const validatePassword = (password: string): string | null => {
  if (!password) return 'Введите пароль';
  if (password.length < 6) return 'Пароль должен быть не менее 6 символов';
  return null;
};

// Add validatePasswordConfirm function
export const validatePasswordMatch = (password: string, confirmPassword: string): string | null => {
  if (!confirmPassword) return 'Подтвердите пароль';
  if (password !== confirmPassword) return 'Пароли не совпадают';
  return null;
};

// Проверка совпадения паролей
export const validatePasswordConfirmation = (password: string, confirmation: string): string | null => {
  if (!confirmation) return 'Подтвердите пароль';
  if (password !== confirmation) return 'Пароли не совпадают';
  return null;
};

export const hasErrors = (errors: Record<string, string | null>): boolean => {
  return Object.values(errors).some(error => error !== null);
};
  
  // Валидация имени и фамилии
  export const validateName = (name: string): string | null => {
    if (!name) return 'Это поле обязательно';
    if (name.length < 2) return 'Минимум 2 символа';
    if (!/^[а-яА-ЯёЁ\s-]+$/i.test(name)) return 'Только русские буквы, пробел и дефис';
    return null;
  };
  
  // Валидация возраста
  export const validateAge = (age: string): string | null => {
    if (!age) return 'Введите возраст';
    const ageNum = parseInt(age);
    if (isNaN(ageNum)) return 'Возраст должен быть числом';
    if (ageNum < 18 || ageNum > 100) return 'Возраст должен быть от 18 до 100 лет';
    return null;
  };
  
  // Функция валидации всей формы регистрации
  export interface RegisterFormData {
    phone: string;
    password: string;
    confirmPassword: string;
    parentType: 'mother' | 'father';
    name: string;
    surname: string;
    age: string;
  }
  
  export const validateRegisterForm = (data: RegisterFormData): Record<keyof RegisterFormData, string | null> => {
    return {
      phone: validatePhone(data.phone),
      password: validatePassword(data.password),
      confirmPassword: validatePasswordMatch(data.password, data.confirmPassword),
      parentType: null,
      name: validateName(data.name),
      surname: validateName(data.surname),
      age: validateAge(data.age)
    };
  };