import React from 'react';
import styles from './Finances.module.scss';

export const Finances: React.FC = () => {
    return (
      <div>
        {/* Финансовая аналитика:
            - Общие доходы/расходы
            - Анализ по направлениям
            - Зарплатная ведомость
            - Финансовое планирование */}
      </div>
    );
  };