import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input } from '../../../components/atoms/Input/Input';
import { Button } from '../../../components/atoms/Button/Button';
import { UpperButton } from '../../../components/atoms/UpperButton/UpperButton';
import styles from './Settings.module.scss';

interface SettingsFormData {
  name: string;
  surname: string;
  phone: string;
  email: string;
  address: string;
  communication: string;
  telegram: string;
  secondParentName: string;
  secondParentSurname: string;
  secondParentPhone: string;
  secondParentEmail: string;
}

export const SettingsPage: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState<SettingsFormData>({
    name: '',
    surname: '',
    phone: '',
    email: '',
    address: '',
    communication: '',
    telegram: '',
    secondParentName: '',
    secondParentSurname: '',
    secondParentPhone: '',
    secondParentEmail: ''
  });

  useEffect(() => {
    const loadUserData = async () => {
      try {
        const userDataStr = localStorage.getItem('lessonsUserData');
        if (!userDataStr) {
          navigate('/lessons/login');
          return;
        }

        const userData = JSON.parse(userDataStr);
        const response = await fetch(`/api/lessons/profile/${userData.id}`);
        const data = await response.json();

        if (data.success) {
          setFormData({
            name: data.user.name || '',
            surname: data.user.surname || '',
            phone: data.user.phone || '',
            email: data.email || '',
            address: data.profile.address || '',
            communication: data.profile.communication || '',
            telegram: data.profile.telegram || '',
            secondParentName: data.profile.secondParent?.name || '',
            secondParentSurname: data.profile.secondParent?.surname || '',
            secondParentPhone: data.profile.secondParent?.phone || '',
            secondParentEmail: data.profile.secondParent?.email || ''
          });
        }
      } catch (error) {
        console.error('Error loading user data:', error);
      }
    };

    loadUserData();
  }, [navigate]);

  const handleSave = async () => {
    setIsLoading(true);
    try {
      const userDataStr = localStorage.getItem('lessonsUserData');
      if (!userDataStr) {
        navigate('/lessons/login');
        return;
      }

      const userData = JSON.parse(userDataStr);
      const response = await fetch(`/api/lessons/profile/${userData.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: formData.name,
          surname: formData.surname,
          address: formData.address,
          communication: formData.communication,
          telegram: formData.telegram,
          secondParent: {
            name: formData.secondParentName,
            surname: formData.secondParentSurname,
            phone: formData.secondParentPhone,
          }
        }),
      });

      const data = await response.json();
      if (data.success) {
        navigate('/lessons/home');
      }
    } catch (error) {
      console.error('Error saving profile:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('lessonsAuthToken');
    localStorage.removeItem('lessonsUserData');
    navigate('/lessons/login');
  };

  return (
    <div className={styles.settingsPage}>
      <div className={styles.header}>
        <UpperButton
          className={styles.backButton}
          label="Назад"
          iconSrc="../content/icons/back-icon-24px.svg"
          variant="white"
          onClick={() => navigate('/lessons/home')}
        />
        <h1>Настройки профиля</h1>
      </div>

      <section className={styles.section}>
        <h2>Основные настройки</h2>
        <div className={styles.inputGroup}>
          <Input
            type="text"
            value={formData.name}
            onChange={(value) => setFormData({ ...formData, name: value })}
            label="Ваше имя"
          />
        </div>
        <div className={styles.inputGroup}>
          <Input
            type="text"
            value={formData.surname}
            onChange={(value) => setFormData({ ...formData, surname: value })}
            label="Ваша фамилия"
          />
        </div>
        <div className={styles.inputGroup}>
          <Input
            type="phone"
            value={formData.phone}
            onChange={(value) => setFormData({ ...formData, phone: value })}
            label="Ваш телефон"
            className={styles.disabledInput}
            readOnly
          />
        </div>
      </section>

      <section className={styles.section}>
        <h2>Настройки родителя</h2>
        <div className={styles.inputGroup}>
          <Input
            type="text"
            value={formData.address}
            onChange={(value) => setFormData({ ...formData, address: value })}
            label="Ваш домашний адрес"
            upperLabel="Ваш домашний адрес"
          />
        </div>
        <div className={styles.inputGroup}>
          <Input
            type="text"
            value={formData.communication}
            onChange={(value) => setFormData({ ...formData, communication: value })}
            label="Средство связи"
            upperLabel="Средство связи"
          />
        </div>
        <div className={styles.inputGroup}>
          <Input
            type="text"
            value={formData.telegram}
            onChange={(value) => setFormData({ ...formData, telegram: value })}
            label="Ник или ссылка в Telegram"
            upperLabel="Telegram"
          />
        </div>
      </section>

      <section className={styles.section}>
        <h2>Второй родитель</h2>
        <div className={styles.inputGroup}>
          <Input
            type="text"
            value={formData.secondParentName}
            onChange={(value) => setFormData({ ...formData, secondParentName: value })}
            label="Имя (необязательно)"
          />
        </div>
        <div className={styles.inputGroup}>
          <Input
            type="text"
            value={formData.secondParentSurname}
            onChange={(value) => setFormData({ ...formData, secondParentSurname: value })}
            label="Фамилия (необязательно)"
          />
        </div>
        <div className={styles.inputGroup}>
          <Input
            type="phone"
            value={formData.secondParentPhone}
            onChange={(value) => setFormData({ ...formData, secondParentPhone: value })}
            label="Телефон (необязательно)"
          />
        </div>
      </section>

      <div className={styles.buttons}>
        <Button
          state="active"
          label={isLoading ? "Сохранение..." : "Сохранить"}
          onClick={handleSave}
          disabled={isLoading}
        />
        <Button
          state="agressive"
          label="Выйти из профиля"
          onClick={handleLogout}
          disabled={isLoading}
        />
      </div>
    </div>
  );
};