import React, { useState, useEffect } from 'react';
import { Button } from '../../../atoms/Button/Button';
import { GroupHeader } from '../../../molecules/GroupHeader/GroupHeader';
import { CreateGroupForm } from './CreateGroupForm';
import { EditGroupForm } from './EditGroupForm';
import { GroupDetailView } from './GroupDetailView';
import styles from './Groups.module.scss';

interface Group {
  _id: string;
  name: string;
  description: string;
  photo: string[];
  ageFrom: number;
  ageTo: number;
  participantsLimit: number;
  studentsIds: Record<string, any>;
  teacherId: string;
  active: boolean;
}

interface GroupFormData {
  name: string;
  description: string;
  photo: string[];
  ageFrom: string;
  ageTo: string;
  participantsLimit: number;
  teacherId: string;
  active?: boolean;
}

export const Groups: React.FC = () => {
  const [groups, setGroups] = useState<Group[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [editingGroupId, setEditingGroupId] = useState<string | null>(null);
  const [viewingGroupId, setViewingGroupId] = useState<string | null>(null);
  const [confirmationMessage, setConfirmationMessage] = useState<string | null>(null);

  const fetchGroups = async () => {
    try {
      setIsLoading(true);
      setError(null);
      
      const response = await fetch('/api/lessons/admin/groups');
      
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      
      const data = await response.json();
      
      if (data.success) {
        setGroups(data.groups);
      } else {
        throw new Error(data.error || 'Не удалось загрузить группы');
      }
    } catch (error) {
      console.error('Error fetching groups:', error);
      setError('Не удалось загрузить группы. Пожалуйста, попробуйте позже.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchGroups();
  }, []);

  const handleCreateGroup = () => {
    setShowCreateForm(true);
  };

  const handleCancelCreate = () => {
    setShowCreateForm(false);
  };

  const handleEditGroup = (groupId: string) => {
    setEditingGroupId(groupId);
  };

  const handleCancelEdit = () => {
    setEditingGroupId(null);
  };

  const handleViewGroupDetails = (groupId: string) => {
    setViewingGroupId(groupId);
  };
  
  // Handle the submission of the create group form
  const handleCreateGroupSubmit = async (groupData: GroupFormData) => {
    try {
      const response = await fetch('/api/lessons/admin/groups', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(groupData),
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      
      const data = await response.json();
      
      if (data.success) {
        // Refresh the groups list after successful creation
        await fetchGroups();
        setShowCreateForm(false);
        setConfirmationMessage('Группа успешно создана!');
        
        // Hide confirmation message after 3 seconds
        setTimeout(() => setConfirmationMessage(null), 3000);
      } else {
        throw new Error(data.error || 'Не удалось создать группу');
      }
    } catch (error) {
      console.error('Error creating group:', error);
      alert('Ошибка при создании группы. Пожалуйста, попробуйте снова.');
    }
  };
  
  // Handle the submission of the edit group form
  const handleEditGroupSubmit = async (groupId: string, groupData: GroupFormData) => {
    try {
      const response = await fetch(`/api/lessons/admin/groups/${groupId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(groupData),
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      
      const data = await response.json();
      
      if (data.success) {
        // Refresh the groups list after successful update
        await fetchGroups();
        setEditingGroupId(null);
        setConfirmationMessage('Группа успешно обновлена!');
        
        // Hide confirmation message after 3 seconds
        setTimeout(() => setConfirmationMessage(null), 3000);
      } else {
        throw new Error(data.error || 'Не удалось обновить группу');
      }
    } catch (error) {
      console.error('Error updating group:', error);
      alert('Ошибка при обновлении группы. Пожалуйста, попробуйте снова.');
    }
  };
  
  // Handle group deletion
  const handleDeleteGroup = async (groupId: string) => {
    try {
      const response = await fetch(`/api/lessons/admin/groups/${groupId}?permanently=true`, {
        method: 'DELETE',
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      
      const data = await response.json();
      
      if (data.success) {
        // Refresh the groups list after successful deletion
        await fetchGroups();
        setEditingGroupId(null);
        setConfirmationMessage('Группа успешно удалена!');
        
        // Hide confirmation message after 3 seconds
        setTimeout(() => setConfirmationMessage(null), 3000);
      } else {
        throw new Error(data.error || 'Не удалось удалить группу');
      }
    } catch (error) {
      console.error('Error deleting group:', error);
      alert('Ошибка при удалении группы. Пожалуйста, попробуйте снова.');
    }
  };

  if (isLoading) {
    return (
      <div className={styles.groupsContainer}>
        <div className={styles.loadingContainer}>
          <div className={styles.loadingSpinner}></div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.groupsContainer}>
        <div className={styles.errorContainer}>
          <p className={styles.errorMessage}>{error}</p>
          <Button
            state="secondary"
            label="Попробовать снова"
            onClick={fetchGroups}
          />
        </div>
      </div>
    );
  }

  if (showCreateForm) {
    return (
      <div className={styles.groupsContainer}>
        <CreateGroupForm 
          onSubmit={handleCreateGroupSubmit} 
          onCancel={handleCancelCreate}
        />
      </div>
    );
  }

  if (editingGroupId) {
    return (
      <div className={styles.groupsContainer}>
        <EditGroupForm 
          groupId={editingGroupId}
          onSubmit={handleEditGroupSubmit}
          onCancel={handleCancelEdit}
          onDelete={handleDeleteGroup}
        />
      </div>
    );
  }

  if (viewingGroupId) {
    return (
      <div className={styles.groupsContainer}>
        <GroupDetailView 
          groupId={viewingGroupId}
          onBack={() => setViewingGroupId(null)}
          onEdit={handleEditGroup}
        />
      </div>
    );
  }

  return (
    <div className={styles.groupsContainer}>
      <div className={styles.buttonsContainer}>
        <Button
          state="active"
          label="Создать группу"
          onClick={handleCreateGroup}
        />
      </div>

      {confirmationMessage && (
        <div className={styles.confirmationMessage}>
          {confirmationMessage}
        </div>
      )}

      <h2 className={styles.sectionTitle}>Активные группы</h2>
      
      {groups.length === 0 ? (
        <div className={styles.emptyState}>
          <h3 className={styles.emptyStateTitle}>Нет активных групп</h3>
          <p className={styles.emptyStateText}>
            Создайте первую группу, чтобы начать формирование расписания и записи учеников.
          </p>
          <Button
            state="active"
            label="Создать группу"
            onClick={handleCreateGroup}
          />
        </div>
      ) : (
        <div className={styles.groupsList}>
          {groups.filter(group => group.active).map(group => (
            <GroupHeader
              key={group._id}
              group={group}
              onDetailsClick={handleViewGroupDetails}
              onEditClick={handleEditGroup}
            />
          ))}
        </div>
      )}

      {/* If there are inactive groups, show them in a separate section */}
      {groups.some(group => !group.active) && (
        <>
          <h2 className={styles.sectionTitle}>Неактивные группы</h2>
          <div className={styles.groupsList}>
            {groups.filter(group => !group.active).map(group => (
              <GroupHeader
                key={group._id}
                group={group}
                onDetailsClick={handleViewGroupDetails}
                onEditClick={handleEditGroup}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};