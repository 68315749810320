import React from 'react';
import styles from './Schedule.module.scss';

export const Schedule: React.FC = () => {
    return (
      <div>
        {/* Расписание занятий учителя:
            - Календарь занятий
            - Список ближайших занятий
            - Возможность отмены занятий
            - Информация о группах и учениках */}
      </div>
    );
  };