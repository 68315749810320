import React from 'react';
import { Schedule } from '../../roles/teacher/Schedule';
import { Students } from '../../roles/teacher/Students';
import { Statistics } from '../../roles/teacher/Statistics';
import { Homework } from '../../roles/teacher/Homework';
import { Profile } from '../../roles/teacher/Profile';

interface TeacherContentProps {
  activeTab: string;
}

export const TeacherContent: React.FC<TeacherContentProps> = ({ activeTab }) => {
  switch (activeTab) {
    case 'schedule':
      return <Schedule />;
    case 'students':
      return <Students />;
    case 'statistics':
      return <Statistics />;
    case 'homework':
      return <Homework />;
    case 'profile':
      return <Profile />;
    default:
      return null;
  }
};