import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { User } from '../types';
import '../styles/AdminScreen.scss';
import EarningsDisplay from '../components/molecules/EarningsDisplay';
import UserSwitcher from '../components/molecules/UserSwitcher';

import excelIcon from '../icons/excel-icon.svg';
import roomsPreview from '../icons/rooms-preview.png';
import controlPreview from '../icons/control-preview.png';
import autoPreview from '../icons/auto-preview.png';
import allBookingsPreview from '../icons/all-bookings-preview.png';

interface AdminScreenProps {
  user: User;
  onLogout: () => void;
}

const AdminScreen: React.FC<AdminScreenProps> = ({ user, onLogout }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isExpanded, setIsExpanded] = useState(location.pathname !== '/admin');

  const handleExportBookings = async () => {
    try {
      console.log('Starting export request...');
      const response = await fetch('/api/export-bookings');
      
      if (!response.ok) {
        console.error('Export failed:', response.status, response.statusText);
        throw new Error('Export failed');
      }
      
      const data = await response.json();
      console.log('Export response:', data);
      
      if (data.success) {
        console.log('Successfully sent to Telegram');
      } else {
        throw new Error(data.error || 'Export failed');
      }
    } catch (error) {
      console.error('Error exporting bookings:', error);
    }
  };

  const handleUserSwitch = async (phone: string) => {
    try {
      const response = await fetch('/api/auth', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ phone }),
      });

      const data = await response.json();

      if (data.success) {
        localStorage.setItem('userPhone', phone);
        navigate('/profile');
        window.location.reload(); // Перезагружаем страницу для обновления состояния
      }
    } catch (error) {
      console.error('Error switching user:', error);
    }
  };

  const renderMainScreen = () => (
    <>
      <div className="admin-grid">
        <button className="admin-button2 download-button" onClick={handleExportBookings}>
          <img src={excelIcon} alt="Excel" className="excel-icon" />
          Скачать
        </button>
        
        <button className="admin-button2 logout-button" onClick={onLogout}>
          <span className="logouttext">Выйти</span>
        </button>
        
        <button 
          className="admin-button2 cards-button" 
          onClick={() => {
            setIsExpanded(true);
            navigate('/admin/rooms');
          }}
        >
          <img src={roomsPreview} alt="Залы" className="preview-image" />
          <span>Карточки</span>
        </button>
        
        <button 
          className="admin-button2 control-button" 
          onClick={() => {
            setIsExpanded(true);
            navigate('/admin/requests');
          }}
        >
          <img src={controlPreview} alt="Контроль броней" className="preview-image" />
          <span>Контроль</span>
        </button>
        
        <button 
          className="admin-button2 auto-button" 
          onClick={() => {
            setIsExpanded(true);
            navigate('/admin/automatic');
          }}
        >
          <img src={autoPreview} alt="Автоматические брони" className="preview-image" />
          <span>Автоброни</span>
        </button>
        
        <button 
          className="admin-button2 all-bookings-button" 
          onClick={() => {
            setIsExpanded(true);
            navigate('/admin/all');
          }}
        >
          <img src={allBookingsPreview} alt="Все брони" className="preview-image" />
          <span>Все брони</span>
        </button>
      </div>
      <UserSwitcher onUserSwitch={handleUserSwitch} />
    </>
  );

  return (
    <div className={`auth-card ${isExpanded ? 'expanded' : ''}`}>
      {location.pathname !== '/admin' && (
        <div className="admin-header">
          <button className="admin-button back" onClick={() => navigate('/admin')}>
            Вернуться назад
          </button>
        </div>
      )}
      {location.pathname === '/admin' ? renderMainScreen() : null}
      <EarningsDisplay />
    </div>
  );
};

export default AdminScreen;