import React, { ReactNode, useState } from 'react';
import { Button } from '../../../atoms/Button/Button';
import { Input } from '../../../atoms/Input/Input';
import styles from './EditPopup.module.scss';

interface EditPopupProps {
  title: string;
  onClose: () => void;
  onSave: (reason: string) => void;
  children: ReactNode;
  isOpen: boolean;
}

export const EditPopup: React.FC<EditPopupProps> = ({
  title,
  onClose,
  onSave,
  children,
  isOpen
}) => {
  const [reason, setReason] = useState('');
  const [error, setError] = useState<string | null>(null);

  if (!isOpen) return null;

  const handleSave = () => {
    if (!reason.trim()) {
      setError('Необходимо указать причину изменения');
      return;
    }
    onSave(reason);
  };

  return (
    <div className={styles.overlay}>
      <div className={styles.popup}>
        <div className={styles.header}>
          <h3>{title}</h3>
          <button className={styles.closeButton} onClick={onClose}>×</button>
        </div>
        
        <div className={styles.content}>
          {children}
        </div>
        
        <div className={styles.reasonSection}>
          <Input
            type="text"
            value={reason}
            onChange={setReason}
            label="Причина изменения"
            upperLabel="Причина изменения"
            state={error ? "error" : "inactive"}
            error={error}
          />
        </div>
        
        <div className={styles.actions}>
          <Button 
            state="secondary"
            label="Отменить"
            onClick={onClose}
          />
          <Button 
            state="active"
            label="Сохранить"
            onClick={handleSave}
          />
        </div>
      </div>
    </div>
  );
};