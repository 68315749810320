import React, { useState, useEffect } from 'react';
import { Input } from '../../../../components/atoms/Input/Input';
import { Button } from '../../../../components/atoms/Button/Button';
import { ImageUploader } from '../../../molecules/ImageUploader/ImageUploader';
import styles from '../Lessons/Lessons.module.scss'; // Reusing styles from Lessons

interface Teacher {
  _id: string;
  name: string;
  surname: string;
  specializations?: string[];
}

interface GroupFormData {
  name: string;
  description: string;
  photo: string[];
  ageFrom: string;
  ageTo: string;
  participantsLimit: number;
  teacherId: string;
}

interface CreateGroupFormProps {
  onSubmit: (groupData: GroupFormData) => void;
  onCancel: () => void;
}

export const CreateGroupForm: React.FC<CreateGroupFormProps> = ({ onSubmit, onCancel }) => {
  const [teachers, setTeachers] = useState<Teacher[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingTeachers, setLoadingTeachers] = useState(true);
  const [fetchError, setFetchError] = useState<string | null>(null);
  const [errors, setErrors] = useState<Record<string, string | null>>({});
  
  const [formData, setFormData] = useState<GroupFormData>({
    name: '',
    description: '',
    photo: ['https://placehold.co/200x200?text=Нет+фото'], // Default placeholder
    ageFrom: '',
    ageTo: '',
    participantsLimit: 8, // Default value
    teacherId: ''
  });

  // Fetch teachers list on component mount
  useEffect(() => {
    const fetchTeachers = async () => {
      try {
        setLoadingTeachers(true);
        setFetchError(null);
        
        const response = await fetch('/api/lessons/teachers');
        
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        
        const data = await response.json();
        
        if (data.success) {
          setTeachers(data.teachers);
        } else {
          throw new Error(data.error || 'Не удалось загрузить список преподавателей');
        }
      } catch (error) {
        console.error('Error fetching teachers:', error);
        setFetchError('Не удалось загрузить список преподавателей');
      } finally {
        setLoadingTeachers(false);
      }
    };

    fetchTeachers();
  }, []);

  const handleInputChange = (field: keyof GroupFormData, value: any) => {
    setFormData({ ...formData, [field]: value });
    // Clear error when editing a field
    if (errors[field]) {
      setErrors({ ...errors, [field]: null });
    }
  };

  // Handle image upload
  const handleImageUpload = (imageUrl: string) => {
    setFormData({
      ...formData,
      photo: [imageUrl]
    });
  };

  const validateForm = (): boolean => {
    const newErrors: Record<string, string | null> = {};
    
    // Validate name
    if (!formData.name.trim()) {
      newErrors.name = 'Введите название группы';
    }
    
    // Validate description
    if (!formData.description.trim()) {
      newErrors.description = 'Введите описание группы';
    }
    
    // Validate age range
    if (!formData.ageFrom) {
      newErrors.ageFrom = 'Укажите минимальный возраст';
    } else if (isNaN(parseFloat(formData.ageFrom))) {
      newErrors.ageFrom = 'Возраст должен быть числом';
    } else if (parseFloat(formData.ageFrom) < 0) {
      newErrors.ageFrom = 'Возраст не может быть отрицательным';
    }
    
    if (!formData.ageTo) {
      newErrors.ageTo = 'Укажите максимальный возраст';
    } else if (isNaN(parseFloat(formData.ageTo))) {
      newErrors.ageTo = 'Возраст должен быть числом';
    } else if (parseFloat(formData.ageTo) < parseFloat(formData.ageFrom)) {
      newErrors.ageTo = 'Максимальный возраст должен быть больше минимального';
    }
    
    // Validate teacher
    if (!formData.teacherId) {
      newErrors.teacherId = 'Выберите преподавателя';
    }
    
    // Validate participants limit
    if (formData.participantsLimit <= 0) {
      newErrors.participantsLimit = 'Укажите лимит участников';
    }
    
    setErrors(newErrors);
    return !Object.values(newErrors).some(error => error !== null);
  };

  const handleSubmit = () => {
    if (!validateForm()) {
      return;
    }
    
    setIsLoading(true);
    onSubmit(formData);
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && !isLoading) {
      handleSubmit();
    }
  };

  return (
    <div className={styles.createLessonForm}>
      <h2 className={styles.formTitle}>Создание новой группы</h2>
      
      {fetchError && (
        <div className={styles.formError}>
          {fetchError}
          <Button
            state="secondary"
            label="Обновить страницу"
            onClick={() => window.location.reload()}
            className={styles.refreshButton}
          />
        </div>
      )}
      
      <div className={styles.formMainSection}>
        <div className={styles.formRow}>
          <ImageUploader
            onImageUpload={handleImageUpload}
            currentImage={formData.photo[0]}
            className={styles.imageUploader}
          />
        </div>
        
        <div className={styles.formColumn}>
          <h3 className={styles.sectionTitle}>Основная информация</h3>
          
          <div className={styles.formRow}>
            <div className={styles.formColumn}>
              <Input
                type="text"
                value={formData.name}
                onChange={(value) => handleInputChange('name', value)}
                label="Название группы"
                upperLabel="Название группы"
                state={errors.name ? 'error' : 'inactive'}
                error={errors.name}
                disabled={isLoading}
                onKeyPress={handleKeyPress}
              />
            </div>
          </div>

          <div className={styles.formRow}>
            <div className={styles.formColumn}>
              <textarea
                className={styles.textarea}
                value={formData.description}
                onChange={(e) => handleInputChange('description', e.target.value)}
                placeholder="Описание группы"
                disabled={isLoading}
              />
              {errors.description && (
                <div className={styles.errorMessage}>{errors.description}</div>
              )}
            </div>
          </div>
        </div>
      </div>
      
      <div className={styles.formMainSection}>
        <div className={styles.formHalfColumn}>
          <h3 className={styles.sectionTitle}>Параметры группы</h3>
          
          <div className={styles.formRow}>
            <div className={styles.formColumn}>
              <Input
                type="text"
                value={formData.ageFrom}
                onChange={(value) => handleInputChange('ageFrom', value)}
                label="Возраст от"
                upperLabel="Возраст от"
                state={errors.ageFrom ? 'error' : 'inactive'}
                error={errors.ageFrom}
                disabled={isLoading}
                onKeyPress={handleKeyPress}
              />
            </div>
            <div className={styles.formColumn}>
              <Input
                type="text"
                value={formData.ageTo}
                onChange={(value) => handleInputChange('ageTo', value)}
                label="Возраст до"
                upperLabel="Возраст до"
                state={errors.ageTo ? 'error' : 'inactive'}
                error={errors.ageTo}
                disabled={isLoading}
                onKeyPress={handleKeyPress}
              />
            </div>
          </div>
          
          <div className={styles.formRow}>
            <div className={styles.formColumn}>
              <Input
                type="text"
                value={formData.participantsLimit.toString()}
                onChange={(value) => handleInputChange('participantsLimit', parseInt(value) || 0)}
                label="Лимит участников"
                upperLabel="Лимит участников"
                state={errors.participantsLimit ? 'error' : 'inactive'}
                error={errors.participantsLimit}
                disabled={isLoading}
                onKeyPress={handleKeyPress}
              />
            </div>
          </div>
        </div>

        <div className={styles.formHalfColumn}>
          <h3 className={styles.sectionTitle}>Преподаватель</h3>
          
          <div className={styles.formRow}>
            <div className={styles.formColumn}>
              <label className={styles.selectLabel}>Преподаватель</label>
              <select
                className={styles.select}
                value={formData.teacherId}
                onChange={(e) => handleInputChange('teacherId', e.target.value)}
                disabled={isLoading || loadingTeachers}
              >
                <option value="">
                  {loadingTeachers ? 'Загрузка преподавателей...' : 'Выберите преподавателя'}
                </option>
                {teachers.map((teacher) => (
                  <option key={teacher._id} value={teacher._id}>
                    {teacher.surname} {teacher.name} {teacher.specializations?.length ? `(${teacher.specializations.join(', ')})` : ''}
                  </option>
                ))}
              </select>
              {errors.teacherId && (
                <div className={styles.errorMessage}>{errors.teacherId}</div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.formButtons}>
        <Button
          state="secondary"
          label="Отменить"
          onClick={onCancel}
          disabled={isLoading}
        />
        <Button
          state="active"
          label={isLoading ? "Создание..." : "Создать"}
          onClick={handleSubmit}
          disabled={isLoading}
        />
      </div>
    </div>
  );
};