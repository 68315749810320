export const normalizePhone = (phone: string): string => {
    // Убираем все нецифровые символы
    const digits = phone.replace(/\D/g, '');
    
    // Если номер начинается с 7 или 8, заменяем на 8
    if (digits.startsWith('7') || digits.startsWith('8')) {
      return '8' + digits.slice(1);
    }
    
    // Если номер начинается без 7/8, добавляем 8
    return '8' + digits;
  };
  
  export const formatPhoneForDisplay = (phone: string): string => {
    const normalized = normalizePhone(phone);
    if (normalized.length !== 11) return phone; // Возвращаем как есть, если формат неверный
    
    return `+7 (${normalized.slice(1, 4)}) ${normalized.slice(4, 7)}-${normalized.slice(7, 9)}-${normalized.slice(9)}`;
  };