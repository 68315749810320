import React from 'react';
import { Helmet } from 'react-helmet-async';
import './Landing.scss';

const LandingPage = () => {
  return (
    <>
      <Helmet>
        <title>Центр Знаний и Успеха в Брянске | Центр развития детей и взрослых</title>
        <meta 
          name="description" 
          content="Центр развития детей и взрослых в Брянске: подготовка к школе, раннее развитие УМКА, йога, шахматы, гитара, ИЗО студия, продлёнка. Индивидуальный подход к каждому ученику." 
        />
      </Helmet>
      
      <main className="landing-page">
        <header className="header">
          <h1>Центр Знаний и Успеха</h1>
          <h2>в Брянске</h2>
        </header>

        <nav className="nav-buttons">
          <a 
            href="https://vk.com/smile32" 
            target="_blank" 
            rel="noopener noreferrer"
            className="vk-button"
          >
            <img src="/vk.svg" alt="" aria-hidden="true" />
            Наша группа ВК
          </a>

          <a href="/login" className="booking-button">
            Бронирование
          </a>
        </nav>

        <footer className="footer">
          <address>
            <p>г. Брянск, ул. Плеханова, д. 20</p>
            <p><a href="tel:+79308200243">+7 (930) 820-02-43</a></p>
          </address>
          
          <div className="services-list">
            <h2>Наши услуги:</h2>
            <ul>
              <li>Раннее развитие УМКА (3-4 года, 4-5 лет)</li>
              <li>Подготовка к школе (6-7 лет)</li>
              <li>Мини сад</li>
              <li>Продлёнка (1-5 класс)</li>
              <li>Шахматы</li>
              <li>Гитара</li>
              <li>ИЗОстудия "Волшебная кисточка"</li>
              <li>Ритмика</li>
              <li>Йога для взрослых</li>
              <li>Правка души и тела</li>
              <li>Медитация</li>
              <li>Подготовка к ВПР</li>
              <li>Занятия "Почемучки" (5-6 лет)</li>
              <li>ФАНТАЗЁРЫ</li>
              <li>Волшебный песок</li>
            </ul>
          </div>
        </footer>
      </main>
    </>
  );
};

export default LandingPage;