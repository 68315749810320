import React from 'react';
import styles from './Documents.module.scss';

export const Documents: React.FC = () => {
    return (
      <div>
        {/* Управление документами:
            - Создание согласий/договоров
            - Загрузка документов
            - Управление разрешениями
            - Архив документов */}
      </div>
    );
  };