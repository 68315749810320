import React from 'react';
import styles from './Settings.module.scss';

export const Settings: React.FC = () => {
    return (
      <div>
        {/* Настройки системы:
            - Общие настройки центра
            - Настройки уведомлений
            - Конфигурация системы
            - Резервное копирование */}
      </div>
    );
  };