import React, { useState, useRef } from 'react';
import styles from './ImageUploader.module.scss';

interface ImageUploaderProps {
  onImageUpload: (imageUrl: string) => void;
  currentImage?: string;
  className?: string;
}

export const ImageUploader: React.FC<ImageUploaderProps> = ({ 
  onImageUpload, 
  currentImage = 'https://placehold.co/200x200?text=Нет+фото',
  className
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  
  const handleClick = () => {
    fileInputRef.current?.click();
  };
  
  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;
    
    const allowedTypes = ['image/jpeg', 'image/png', 'image/heic', 'image/jpg'];
    
    if (!allowedTypes.includes(file.type)) {
      setError('Поддерживаются только JPG, PNG и HEIC форматы');
      return;
    }
    
    setIsUploading(true);
    setError(null);
    
    try {
      const formData = new FormData();
      formData.append('image', file);
      
      console.log('Uploading file:', file.name, file.type);
      
      const response = await fetch('/api/lessons/upload-image', {
        method: 'POST',
        body: formData
      });
      
      if (!response.ok) {
        throw new Error(`Upload failed: ${response.status} ${response.statusText}`);
      }
      
      const result = await response.json();
      
      if (result.success) {
        console.log('Upload success:', result.imageUrl);
        onImageUpload(result.imageUrl);
      } else {
        throw new Error(result.error || 'Ошибка загрузки');
      }
    } catch (error) {
      console.error('Upload error:', error);
      setError('Не удалось загрузить изображение. Проверьте формат файла.');
    } finally {
      setIsUploading(false);
    }
  };
  
  return (
    <div className={`${styles.uploader} ${className || ''}`}>
      <div 
        className={styles.imageContainer}
        onClick={handleClick}
        style={{ backgroundImage: `url(${currentImage})` }}
      >
        {isUploading ? (
          <div className={styles.overlay}>
            <span>Загрузка...</span>
          </div>
        ) : (
          <div className={styles.overlay}>
            <span>Нажмите для выбора фото</span>
          </div>
        )}
      </div>
      
      <input
        ref={fileInputRef}
        type="file"
        accept=".jpg,.jpeg,.png,.heic"
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
      
      {error && <div className={styles.error}>{error}</div>}
    </div>
  );
};