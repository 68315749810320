import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { TabsNavigation } from '../components/layout/TabsNavigation/TabsNavigation';
import { ParentContent } from '../components/layout/RoleContent/ParentContent';
import { TeacherContent } from '../components/layout/RoleContent/TeacherContent';
import { AdminContent } from '../components/layout/RoleContent/AdminContent';
import { DirectorContent } from '../components/layout/RoleContent/DirectorContent';
import { getRoleTabsConfig } from '../config/tabs';
import { UpperButton } from '../components/atoms/UpperButton/UpperButton';
import nightBg from '../content/bgs/night.webp';
import dayBg from '../content/bgs/day.webp';
import eveningBg from '../content/bgs/evening.webp';
import './HomePage.scss';

interface Child {
  id: string;
  name: string;
}

interface UserData {
  id: string;
  name: string;
  role: 'parent' | 'teacher' | 'admin' | 'director';
  balance?: number;
}

const HomePage: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  
  // Получаем параметры из URL
  const tabFromURL = searchParams.get('tab');
  
  const [activeTab, setActiveTab] = useState<string>('');
  const [children, setChildren] = useState<Child[]>([]);
  const [timeOfDay, setTimeOfDay] = useState<'day' | 'evening' | 'night'>('day');
  const [greeting, setGreeting] = useState('');
  const [background, setBackground] = useState(dayBg);
  const [userData, setUserData] = useState<UserData | null>(null);

  // Загрузка данных пользователя
  useEffect(() => {
    const userDataStr = localStorage.getItem('lessonsUserData');
    const token = localStorage.getItem('lessonsAuthToken');
    
    if (!token || !userDataStr) {
      navigate('/lessons/login');
      return;
    }

    try {
      const parsedUserData = JSON.parse(userDataStr);
      setUserData(parsedUserData);

      // Если это родитель, загружаем список его детей
      if (parsedUserData.role === 'parent') {
        fetchChildren(parsedUserData.id)
          .then(fetchedChildren => {
            setChildren(fetchedChildren);
            
            // Устанавливаем активную вкладку с учетом URL параметров
            if (tabFromURL) {
              setActiveTab(tabFromURL);
            } else if (fetchedChildren.length > 0) {
              setActiveTab(fetchedChildren[0].id);
            }
          })
          .catch(error => {
            console.error('Error fetching children:', error);
          });
      } else {
        // Для других ролей устанавливаем первую вкладку из конфигурации
        // или используем вкладку из URL
        if (tabFromURL) {
          setActiveTab(tabFromURL);
        } else {
          const tabs = getRoleTabsConfig(parsedUserData.role);
          if (tabs.length > 0) {
            setActiveTab(tabs[0].id);
          }
        }
      }
    } catch (error) {
      console.error('Error parsing user data:', error);
      navigate('/lessons/login');
    }
  }, [navigate, tabFromURL]);

  // Обновление времени суток и приветствия
  useEffect(() => {
    const updateTimeBasedContent = () => {
      const hour = new Date().getHours();
      
      if (hour >= 6 && hour < 16) {
        setTimeOfDay('day');
        setGreeting('Доброго дня');
        setBackground(dayBg);
      } else if (hour >= 16 && hour < 22) {
        setTimeOfDay('evening');
        setGreeting('Доброго вечера');
        setBackground(eveningBg);
      } else {
        setTimeOfDay('night');
        setGreeting('Доброй ночи');
        setBackground(nightBg);
      }
    };

    updateTimeBasedContent();
    const interval = setInterval(updateTimeBasedContent, 60000);
    
    return () => clearInterval(interval);
  }, []);

  // Функция для получения списка детей пользователя
  const fetchChildren = async (userId: string): Promise<Child[]> => {
    try {
      const response = await fetch(`/api/lessons/children/${userId}`);
      if (!response.ok) {
        throw new Error('Failed to fetch children');
      }
      const data = await response.json();
      return data.children;
    } catch (error) {
      console.error('Error:', error);
      return [];
    }
  };

  // Обработчик смены вкладки с обновлением URL параметров
  const handleTabChange = (tabId: string) => {
    setActiveTab(tabId);
    
    // Обновляем URL параметры
    const newParams = new URLSearchParams(searchParams);
    newParams.set('tab', tabId);
    
    // Удаляем временные параметры навигации, если они есть
    if (newParams.has('childId')) {
      newParams.delete('childId');
    }
    if (newParams.has('parentId')) {
      newParams.delete('parentId');
    }
    
    setSearchParams(newParams);
  };

  const handleLogout = () => {
    localStorage.removeItem('lessonsAuthToken');
    localStorage.removeItem('lessonsUserData');
    navigate('/lessons/login');
  };

  const renderContent = () => {
    if (!userData) return null;

    switch (userData.role) {
      case 'parent':
        return (
          <ParentContent 
            activeTab={activeTab}
            children={children}
          />
        );
      case 'teacher':
        return <TeacherContent activeTab={activeTab} />;
      case 'admin':
        return <AdminContent activeTab={activeTab} />;
      case 'director':
        return <DirectorContent activeTab={activeTab} />;
      default:
        return null;
    }
  };

  if (!userData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="home-screen">
      <header className="header">
        <div className="greeting-container">
          {userData.role === 'parent' && (
            <UpperButton
              className="balanceButton"
              label={`Баланс: ${userData.balance || 0} ₽`}
              iconSrc="../content/icons/coin-icon-24px.svg"
              variant="dark"
            />
          )}
          
          <UpperButton
            className="settingsButton"
            label="Настройки"
            iconSrc="../content/icons/settings-icon-24px.svg"
            variant="dark"
            onClick={() => navigate('/lessons/settings')}
          />
          
          <div className="greeting-text">{greeting},</div>
          <div className="user-name">{userData.name}!</div>
        </div>
      </header>

      <div className="main-block">
        <TabsNavigation
          role={userData.role}
          activeTab={activeTab}
          onTabChange={handleTabChange}
          children={userData.role === 'parent' ? children : undefined}
        />
        
        <div className="content">
          {renderContent()}
        </div>
      </div>

      <div 
        className="background" 
        style={{ backgroundImage: `url(${background})` }} 
      />
    </div>
  );
};

export default HomePage;