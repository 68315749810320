import { Outlet, useLocation } from 'react-router-dom';
import { User } from '../types';
import BackButton from '../components/molecules/BackButton';
import BookingRequestsScreen from '../pages/BookingRequestsScreen';
import AdminScreen from '../pages/AdminScreen';

interface AdminLayoutProps {
  user: User;
  onLogout: () => void;
  children: React.ReactNode;
}

const AdminLayout: React.FC<AdminLayoutProps> = ({ user, onLogout, children }) => {
  const location = useLocation();
  const isMainAdminPage = location.pathname === '/admin';

  if (isMainAdminPage) {
    return <>{children}</>;
  }

  return (
    <>
      {location.pathname === '/admin/requests' ? (
        <BookingRequestsScreen user={user} />
      ) : (
        <Outlet />
      )}
      <BackButton />
    </>
  );
};

export default AdminLayout;