import React from 'react';
import classNames from 'classnames';
import styles from './TabButton.module.scss';

export type TabButtonState = 'new-kid' | 'inactive-tab' | 'active-tab';

interface TabButtonProps {
  state: TabButtonState;
  className?: string;
  onClick?: () => void;
  label?: string;
  disabled?: boolean;
}

export const TabButton: React.FC<TabButtonProps> = ({
  state,
  className,
  onClick,
  label,
  disabled = false
}) => {
  const getDefaultLabel = () => {
    switch (state) {
      case 'active-tab':
        return label || 'Выбранный ребёнок';
      case 'inactive-tab':
        return label || 'Ребёнок';
      case 'new-kid':
        return 'Добавить ребёнка +';
      default:
        return label;
    }
  };

  return (
    <button
      className={classNames(
        styles.tabButton,
        styles[state],
        className,
        {
          [styles.disabled]: disabled
        }
      )}
      onClick={onClick}
      disabled={disabled}
      type="button"
    >
      <span className={styles.label}>
        {getDefaultLabel()}
      </span>
    </button>
  );
};