import React from 'react';
import styles from './Statistics.module.scss';

export const Statistics: React.FC = () => {
  return (
    <div>
      {/* Статистика преподавателя:
          - Количество проведённых занятий
          - Посещаемость занятий
          - Финансовая статистика
          - Эффективность работы */}
    </div>
  );
};