import React from 'react';
import { useNavigate } from 'react-router-dom';
import { User } from '../types';
import PhoneInput from '../components/molecules/PhoneInput';
import { ReactComponent as LogoSVG } from '../content/center.svg';

interface LoginScreenProps {
  onLogin: (user: User, phone: string) => void;
  onRegistrationNeeded: (phone: string) => void;
  initialPhone: string;
}

const LoginScreen: React.FC<LoginScreenProps> = ({ 
  onLogin, 
  onRegistrationNeeded
}) => {
  const navigate = useNavigate();

  return (
    <div className="auth-card" style={{ boxSizing: "border-box" ,display: "flex", flexDirection: "column" , alignItems: "center", paddingBottom: "100px"}}>
      <LogoSVG style={{ width: "140px", height: "140px" }}></LogoSVG>
      <PhoneInput
        onLogin={(user) => {
          onLogin(user, user.phone);
          navigate(user.role === 'admin' ? '/admin' : '/profile');
        }}
        onRegistrationNeeded={(phone) => {
          onRegistrationNeeded(phone);
          navigate('/register');
        }}
      />
    </div>
  );
};

export default LoginScreen;