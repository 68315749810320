import React, { useState, useEffect } from 'react';

const EarningsDisplay = () => {
  const [earnings, setEarnings] = useState<{
    totalEarnings: number;
    roomEarnings: Record<string, number>;
  }>({ totalEarnings: 0, roomEarnings: {} });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchEarnings = async () => {
      try {
        const response = await fetch('/api/earnings');
        const result = await response.json();
        if (result.success) {
          setEarnings(result.data);
        }
      } catch (error) {
        console.error('Error fetching earnings:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchEarnings();
  }, []);

  if (isLoading) {
    return <div className="mini-loader">Загрузка...</div>;
  }

  return (
    <div className="earnings-block">
      <div className="mini-module">
        <div className="earn-date">ЗА МЕСЯЦ</div>
        <div className="earn-sum">
          {earnings.totalEarnings} руб.
        </div>
      </div>
      
      {Object.entries(earnings.roomEarnings).map(([roomName, amount]) => (
          <div key={roomName} className="mini-module">
            <div className="earn-date">{roomName.toUpperCase()}</div>
            <div className="earn-sum">
              {amount} руб.
            </div>
          </div>
        ))}
    </div>
  );
};

export default EarningsDisplay;