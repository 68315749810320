import React, { useState, useEffect } from 'react';
import { Button } from '../../../../components/atoms/Button/Button';
import { LessonCard } from '../../../../components/molecules/LessonCard/LessonCard';
import styles from './GroupDetail.module.scss';

interface Student {
  _id: string;
  firstName: string;
  lastName: string;
  birthDate: string;
  status: string;
}

interface Teacher {
  _id: string;
  name: string;
  surname: string;
  specializations?: string[];
}

interface Lesson {
  _id: string;
  name: string;
  photo: string[];
  description: string;
  date: string;
  ageFrom: number | string;
  ageTo: number | string;
  startTime?: string;
  endTime?: string;
  startTimeHours?: number;
  startTimeMinutes?: number;
  endTimeHours?: number;
  endTimeMinutes?: number;
  participantsLimit: number;
  price: number;
  studentsIds?: Record<string, any>;
  status: string;
  groupId?: string;
}

interface Group {
  _id: string;
  name: string;
  description: string;
  photo: string[];
  ageFrom: number;
  ageTo: number;
  lessonsIds: Record<string, any>;
  participantsLimit: number;
  studentsIds: Record<string, any>;
  teacherId: Teacher;
  active: boolean;
  createdAt: string;
  updatedAt: string;
}

interface GroupDetailProps {
  groupId: string;
  onBack: () => void;
  onEdit: (groupId: string) => void;
}

export const GroupDetailView: React.FC<GroupDetailProps> = ({ 
  groupId, 
  onBack,
  onEdit
}) => {
  const [group, setGroup] = useState<Group | null>(null);
  const [lessons, setLessons] = useState<Lesson[]>([]);
  const [students, setStudents] = useState<Student[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState<'lessons' | 'students'>('lessons');

  // Fetch group data
  useEffect(() => {
    const fetchGroupData = async () => {
      try {
        setIsLoading(true);
        setError(null);
        
        const response = await fetch(`/api/lessons/admin/groups/${groupId}`);
        
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        
        const data = await response.json();
        
        if (data.success) {
          console.log('Group data:', data.group);
          setGroup(data.group);
          
          // После получения группы, запрашиваем занятия и студентов
          await Promise.all([
            fetchGroupLessons(),
            fetchGroupStudents(data.group.studentsIds ? Object.keys(data.group.studentsIds) : [])
          ]);
        } else {
          throw new Error(data.error || 'Не удалось загрузить данные группы');
        }
      } catch (error) {
        console.error('Error fetching group data:', error);
        setError('Не удалось загрузить данные группы. Пожалуйста, попробуйте позже.');
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchGroupData();
  }, [groupId]);

  // Fetch lessons for the group with a direct query
  const fetchGroupLessons = async () => {
    try {
      console.log('Fetching lessons for group ID:', groupId);
      
      // Запрашиваем все занятия с фильтром по groupId
      const response = await fetch(`/api/lessons/admin/lessons?groupId=${groupId}`);
      
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      
      const data = await response.json();
      
      if (data.success) {
        console.log('Lessons data:', data.lessons);
        setLessons(data.lessons || []);
      } else {
        console.error('Failed to fetch lessons:', data.error);
      }
    } catch (error) {
      console.error('Error fetching group lessons:', error);
    }
  };

  // Fetch students for the group
  const fetchGroupStudents = async (studentIds: string[]) => {
    try {
      if (!studentIds.length) {
        setStudents([]);
        return;
      }
      
      // Create promises for fetching each student
      const studentPromises = studentIds.map(studentId => 
        fetch(`/api/lessons/students/${studentId}`)
          .then(response => {
            if (!response.ok) throw new Error(`Error fetching student ${studentId}`);
            return response.json();
          })
          .then(data => data.success ? data.student : null)
          .catch(err => {
            console.error(`Error fetching student ${studentId}:`, err);
            return null;
          })
      );
      
      // Wait for all promises to resolve
      const studentResults = await Promise.all(studentPromises);
      
      // Filter out null results and set students
      const validStudents = studentResults.filter(student => student !== null);
      setStudents(validStudents);
    } catch (error) {
      console.error('Error fetching group students:', error);
    }
  };

  // Navigate to lesson details
  const handleLessonClick = (lessonId: string) => {
    console.log('Navigate to lesson details:', lessonId);
    // You would typically use history.push or similar here
  };

  // Add a lesson to the group
  const handleAddLesson = () => {
    console.log('Add lesson to group');
    // You would typically open a modal or navigate to a form
  };

  // Add a student to the group
  const handleAddStudent = () => {
    console.log('Add student to group');
    // You would typically open a modal or navigate to a form
  };

  // Calculate the number of enrolled students
  const enrolledStudents = group?.studentsIds ? Object.keys(group.studentsIds).length : 0;
  
  // Calculate the number of scheduled lessons
  const scheduledLessons = lessons.filter(lesson => lesson.status === 'scheduled').length;

  if (isLoading) {
    return (
      <div className={styles.groupDetailContainer}>
        <div className={styles.loadingContainer}>
          <div className={styles.loadingSpinner}></div>
          <p>Загрузка данных группы...</p>
        </div>
      </div>
    );
  }

  if (error || !group) {
    return (
      <div className={styles.groupDetailContainer}>
        <div className={styles.errorContainer}>
          <p className={styles.errorMessage}>{error || 'Не удалось загрузить данные группы'}</p>
          <Button
            state="secondary"
            label="Вернуться"
            onClick={onBack}
          />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.groupDetailContainer}>
      <div className={styles.header}>
        <Button
          state="secondary"
          label="Назад к списку"
          onClick={onBack}
        />
        <Button
          state="secondary"
          label="Редактировать"
          onClick={() => onEdit(groupId)}
        />
      </div>
      
      <div className={styles.groupHero}>
        <div className={styles.imageContainer}>
          {group.photo && group.photo.length > 0 ? (
            <img 
              src={group.photo[0]} 
              alt={group.name} 
              className={styles.groupImage}
            />
          ) : (
            <div className={styles.imagePlaceholder}>
              Нет фото
            </div>
          )}
        </div>
        
        <div className={styles.groupInfo}>
          <h1 className={styles.groupName}>{group.name}</h1>
          
          <div className={styles.infoRows}>
            <div className={styles.infoRow}>
              <span className={styles.infoLabel}>Возрастная группа:</span>
              <span className={styles.infoValue}>от {group.ageFrom} до {group.ageTo} лет</span>
            </div>
            
            <div className={styles.infoRow}>
              <span className={styles.infoLabel}>Преподаватель:</span>
              <span className={styles.infoValue}>
                {group.teacherId.surname} {group.teacherId.name}
              </span>
            </div>
            
            <div className={styles.infoRow}>
              <span className={styles.infoLabel}>Ученики:</span>
              <span className={styles.infoValue}>
                {enrolledStudents} из {group.participantsLimit} 
                ({Math.round((enrolledStudents / group.participantsLimit) * 100)}% заполнено)
              </span>
            </div>
            
            <div className={styles.infoRow}>
              <span className={styles.infoLabel}>Статус:</span>
              <span className={`${styles.infoValue} ${styles.statusValue} ${group.active ? styles.activeStatus : styles.inactiveStatus}`}>
                {group.active ? 'Активная' : 'Неактивная'}
              </span>
            </div>
            
            <div className={styles.infoRow}>
              <span className={styles.infoLabel}>Запланировано занятий:</span>
              <span className={styles.infoValue}>{scheduledLessons}</span>
            </div>
          </div>
        </div>
      </div>
      
      <div className={styles.groupDescription}>
        <h2 className={styles.sectionTitle}>Описание группы</h2>
        <p className={styles.descriptionText}>{group.description}</p>
      </div>
      
      <div className={styles.tabSection}>
        <div className={styles.tabs}>
          <button 
            className={`${styles.tabButton} ${activeTab === 'lessons' ? styles.activeTab : ''}`}
            onClick={() => setActiveTab('lessons')}
          >
            Занятия
          </button>
          <button 
            className={`${styles.tabButton} ${activeTab === 'students' ? styles.activeTab : ''}`}
            onClick={() => setActiveTab('students')}
          >
            Ученики
          </button>
        </div>
        
        <div className={styles.tabContent}>
          {activeTab === 'lessons' ? (
            <>
              <div className={styles.tabHeader}>
                <h2 className={styles.contentTitle}>Занятия группы</h2>
                <Button
                  state="secondary"
                  label="Добавить занятие"
                  onClick={handleAddLesson}
                />
              </div>
              
              {lessons.length > 0 ? (
                <div className={styles.lessonsGrid}>
                  {lessons.map(lesson => (
                    <div key={lesson._id} className={styles.lessonCardWrapper}>
                      <LessonCard
                        lesson={lesson}
                        showAgeRange={false}
                        buttonText="Подробнее"
                        onButtonClick={() => handleLessonClick(lesson._id)}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <div className={styles.emptyState}>
                  <p>В этой группе пока нет занятий</p>
                </div>
              )}
            </>
          ) : (
            <>
              <div className={styles.tabHeader}>
                <h2 className={styles.contentTitle}>Ученики группы</h2>
                <Button
                  state="secondary"
                  label="Добавить ученика"
                  onClick={handleAddStudent}
                />
              </div>
              
              {students.length > 0 ? (
                <div className={styles.studentsTable}>
                  <table>
                    <thead>
                      <tr>
                        <th>ФИО</th>
                        <th>Дата рождения</th>
                        <th>Статус</th>
                        <th>Действия</th>
                      </tr>
                    </thead>
                    <tbody>
                      {students.map(student => (
                        <tr key={student._id}>
                          <td>{student.lastName} {student.firstName}</td>
                          <td>{new Date(student.birthDate).toLocaleDateString('ru-RU')}</td>
                          <td>
                            <span className={`${styles.statusBadge} ${styles[student.status]}`}>
                              {student.status === 'active' ? 'Активен' : 
                               student.status === 'inactive' ? 'Неактивен' : 'Ожидание'}
                            </span>
                          </td>
                          <td>
                            <Button
                              state="secondary"
                              label="Подробнее"
                              onClick={() => console.log('Navigate to student details:', student._id)}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className={styles.emptyState}>
                  <p>В этой группе пока нет учеников</p>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};