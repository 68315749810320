// src/lessons/components/roles/admin/Lessons/index.tsx
import React, { useState, useEffect } from 'react';
import { Button } from '../../../../components/atoms/Button/Button';
import { LessonCard } from '../../../../components/molecules/LessonCard/LessonCard';
import { CreateLessonForm } from './CreateLessonForm';
import { EditLessonForm } from './EditLessonForm';
import { TemplateForm } from './TemplateForm';
import { MultipleLessonsForm } from './MultipleLessonsForm';
import { LessonData, LessonFormData } from './LessonTypes';
import styles from './Lessons.module.scss';

// Интерфейс для шаблона занятия
interface TemplateData {
  _id: string;
  name: string;
  photo: string[];
  description: string;
  groupId?: string;
  ageFrom: string | number;
  ageTo: string | number;
  startTimeHours: number;
  startTimeMinutes: number;
  endTimeHours: number;
  endTimeMinutes: number;
  teacherId: string;
  participantsLimit: number;
  price: number;
  teacherPrice?: number;
}

export const Lessons: React.FC = () => {
  const [lessons, setLessons] = useState<LessonData[]>([]);
  const [templates, setTemplates] = useState<TemplateData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isTemplatesLoading, setIsTemplatesLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [templatesError, setTemplatesError] = useState<string | null>(null);
  
  // Состояния для отображения различных форм
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showCreateTemplateForm, setShowCreateTemplateForm] = useState(false);
  const [editingLessonId, setEditingLessonId] = useState<string | null>(null);
  const [editingTemplateId, setEditingTemplateId] = useState<string | null>(null);
  const [creatingFromTemplateId, setCreatingFromTemplateId] = useState<string | null>(null);
  
  const [confirmationMessage, setConfirmationMessage] = useState<string | null>(null);

  // Фильтруем занятия по статусу
  const scheduledLessons = lessons.filter(lesson => lesson.status === 'scheduled');
  const cancelledLessons = lessons.filter(lesson => lesson.status === 'cancelled');

  // Загрузка занятий
  const fetchLessons = async () => {
    try {
      setIsLoading(true);
      setError(null);
      
      const response = await fetch('/api/lessons/admin/lessons');
      
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      
      const data = await response.json();
      
      if (data.success) {
        setLessons(data.lessons);
      } else {
        throw new Error(data.error || 'Не удалось загрузить занятия');
      }
    } catch (error) {
      console.error('Error fetching lessons:', error);
      setError('Не удалось загрузить занятия. Пожалуйста, попробуйте позже.');
    } finally {
      setIsLoading(false);
    }
  };

  // Загрузка шаблонов
  const fetchTemplates = async () => {
    try {
      setIsTemplatesLoading(true);
      setTemplatesError(null);
      
      const response = await fetch('/api/lessons/admin/templates');
      
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      
      const data = await response.json();
      
      if (data.success) {
        setTemplates(data.templates);
      } else {
        throw new Error(data.error || 'Не удалось загрузить шаблоны');
      }
    } catch (error) {
      console.error('Error fetching templates:', error);
      setTemplatesError('Не удалось загрузить шаблоны. Пожалуйста, попробуйте позже.');
    } finally {
      setIsTemplatesLoading(false);
    }
  };

  // Загружаем данные при монтировании компонента
  useEffect(() => {
    fetchLessons();
    fetchTemplates();
  }, []);

  // Обработчики для занятий
  const handleCreateLesson = async (lessonFormData: LessonFormData) => {
    try {
      setIsLoading(true);
      
      // Combine hours and minutes into formatted time strings for display
      const startTime = `${String(lessonFormData.startTimeHours).padStart(2, '0')}:${String(lessonFormData.startTimeMinutes).padStart(2, '0')}`;
      const endTime = `${String(lessonFormData.endTimeHours).padStart(2, '0')}:${String(lessonFormData.endTimeMinutes).padStart(2, '0')}`;
      
      // Create the API-compatible lesson object
      const newLesson = {
        name: lessonFormData.name,
        photo: lessonFormData.photo,
        description: lessonFormData.description,
        date: lessonFormData.date, // Already in ISO format from the form
        ageFrom: lessonFormData.ageFrom,
        ageTo: lessonFormData.ageTo,
        startTime,
        endTime,
        // Include the separate hour and minute fields required by the schema
        startTimeHours: lessonFormData.startTimeHours,
        startTimeMinutes: lessonFormData.startTimeMinutes,
        endTimeHours: lessonFormData.endTimeHours,
        endTimeMinutes: lessonFormData.endTimeMinutes,
        teacherId: lessonFormData.teacherId,
        participantsLimit: lessonFormData.participantsLimit,
        price: lessonFormData.price,
        teacherPrice: lessonFormData.teacherPrice,
        studentsIds: {}, // Initialize empty object
        status: lessonFormData.status, // Используем status из формы
        ...(lessonFormData.groupId && { groupId: lessonFormData.groupId }) // Add groupId if it exists
      };
      
      const response = await fetch('/api/lessons/admin/lessons', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newLesson),
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      
      const data = await response.json();
      
      if (data.success) {
        // Обновляем список занятий после успешного создания
        await fetchLessons();
        setShowCreateForm(false);
        setConfirmationMessage('Занятие успешно создано!');
        
        // Автоматически скрываем сообщение через 3 секунды
        setTimeout(() => setConfirmationMessage(null), 3000);
      } else {
        throw new Error(data.error || 'Не удалось создать занятие');
      }
    } catch (error) {
      console.error('Error creating lesson:', error);
      alert('Ошибка при создании занятия. Пожалуйста, попробуйте снова.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateLesson = async (lessonId: string, lessonFormData: LessonFormData) => {
    try {
      setIsLoading(true);
      
      // Combine hours and minutes into formatted time strings for display
      const startTime = `${String(lessonFormData.startTimeHours).padStart(2, '0')}:${String(lessonFormData.startTimeMinutes).padStart(2, '0')}`;
      const endTime = `${String(lessonFormData.endTimeHours).padStart(2, '0')}:${String(lessonFormData.endTimeMinutes).padStart(2, '0')}`;
      
      // Create the API-compatible lesson object
      const updatedLesson = {
        name: lessonFormData.name,
        photo: lessonFormData.photo,
        description: lessonFormData.description,
        date: lessonFormData.date, // Already in ISO format from the form
        ageFrom: lessonFormData.ageFrom,
        ageTo: lessonFormData.ageTo,
        startTime,
        endTime,
        // Include the separate hour and minute fields required by the schema
        startTimeHours: lessonFormData.startTimeHours,
        startTimeMinutes: lessonFormData.startTimeMinutes,
        endTimeHours: lessonFormData.endTimeHours,
        endTimeMinutes: lessonFormData.endTimeMinutes,
        teacherId: lessonFormData.teacherId,
        participantsLimit: lessonFormData.participantsLimit,
        price: lessonFormData.price,
        teacherPrice: lessonFormData.teacherPrice,
        status: lessonFormData.status,
        ...(lessonFormData.groupId && { groupId: lessonFormData.groupId }) // Add groupId if it exists
      };
      
      const response = await fetch(`/api/lessons/admin/lessons/${lessonId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedLesson),
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      
      const data = await response.json();
      
      if (data.success) {
        // Обновляем список занятий после успешного обновления
        await fetchLessons();
        setEditingLessonId(null);
        setConfirmationMessage('Занятие успешно обновлено!');
        
        // Автоматически скрываем сообщение через 3 секунды
        setTimeout(() => setConfirmationMessage(null), 3000);
      } else {
        throw new Error(data.error || 'Не удалось обновить занятие');
      }
    } catch (error) {
      console.error('Error updating lesson:', error);
      alert('Ошибка при обновлении занятия. Пожалуйста, попробуйте снова.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteLesson = async (lessonId: string) => {
    try {
      const response = await fetch(`/api/lessons/admin/lessons/${lessonId}?permanently=true`, {
        method: 'DELETE',
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      
      const data = await response.json();
      
      if (data.success) {
        // Обновляем список занятий после удаления
        await fetchLessons();
        setEditingLessonId(null);
        setConfirmationMessage('Занятие успешно удалено!');
        
        // Автоматически скрываем сообщение через 3 секунды
        setTimeout(() => setConfirmationMessage(null), 3000);
      } else {
        throw new Error(data.error || 'Не удалось удалить занятие');
      }
    } catch (error) {
      console.error('Error deleting lesson:', error);
      alert('Ошибка при удалении занятия. Пожалуйста, попробуйте снова.');
    }
  };

  const handleCancelLesson = async (lessonId: string) => {
    try {
      const response = await fetch(`/api/lessons/admin/lessons/${lessonId}`, {
        method: 'DELETE', // Используется DELETE без параметра permanently=true
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      
      const data = await response.json();
      
      if (data.success) {
        // Обновляем список занятий после отмены
        await fetchLessons();
        setEditingLessonId(null);
        setConfirmationMessage('Занятие успешно отменено!');
        
        // Автоматически скрываем сообщение через 3 секунды
        setTimeout(() => setConfirmationMessage(null), 3000);
      } else {
        throw new Error(data.error || 'Не удалось отменить занятие');
      }
    } catch (error) {
      console.error('Error cancelling lesson:', error);
      alert('Ошибка при отмене занятия. Пожалуйста, попробуйте снова.');
    }
  };

  // Обработчики для шаблонов
  const handleCreateTemplate = async (templateData: any) => {
    try {
      setIsLoading(true);
      
      const response = await fetch('/api/lessons/admin/templates', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(templateData),
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      
      const data = await response.json();
      
      if (data.success) {
        // Обновляем список шаблонов после успешного создания
        await fetchTemplates();
        setShowCreateTemplateForm(false);
        setConfirmationMessage('Шаблон успешно создан!');
        
        // Автоматически скрываем сообщение через 3 секунды
        setTimeout(() => setConfirmationMessage(null), 3000);
      } else {
        throw new Error(data.error || 'Не удалось создать шаблон');
      }
    } catch (error) {
      console.error('Error creating template:', error);
      alert('Ошибка при создании шаблона. Пожалуйста, попробуйте снова.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateTemplate = async (templateId: string, templateData: any) => {
    try {
      setIsLoading(true);
      
      const response = await fetch(`/api/lessons/admin/templates/${templateId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(templateData),
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      
      const data = await response.json();
      
      if (data.success) {
        // Обновляем список шаблонов после успешного обновления
        await fetchTemplates();
        setEditingTemplateId(null);
        setConfirmationMessage('Шаблон успешно обновлен!');
        
        // Автоматически скрываем сообщение через 3 секунды
        setTimeout(() => setConfirmationMessage(null), 3000);
      } else {
        throw new Error(data.error || 'Не удалось обновить шаблон');
      }
    } catch (error) {
      console.error('Error updating template:', error);
      alert('Ошибка при обновлении шаблона. Пожалуйста, попробуйте снова.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteTemplate = async (templateId: string) => {
    try {
      const response = await fetch(`/api/lessons/admin/templates/${templateId}`, {
        method: 'DELETE',
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      
      const data = await response.json();
      
      if (data.success) {
        // Обновляем список шаблонов после удаления
        await fetchTemplates();
        setEditingTemplateId(null);
        setConfirmationMessage('Шаблон успешно удален!');
        
        // Автоматически скрываем сообщение через 3 секунды
        setTimeout(() => setConfirmationMessage(null), 3000);
      } else {
        throw new Error(data.error || 'Не удалось удалить шаблон');
      }
    } catch (error) {
      console.error('Error deleting template:', error);
      alert('Ошибка при удалении шаблона. Пожалуйста, попробуйте снова.');
    }
  };

  // Обработчик для создания множества занятий на основе шаблона
  const handleCreateMultipleLessons = async (templateId: string, count: number, selectedDays: number[]) => {
    try {
      setIsLoading(true);
      
      const response = await fetch(`/api/lessons/admin/templates/${templateId}/create-lessons`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          count,
          selectedDays
        }),
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      
      const data = await response.json();
      
      if (data.success) {
        // Обновляем список занятий после успешного создания
        await fetchLessons();
        setCreatingFromTemplateId(null);
        setConfirmationMessage(`Успешно создано ${data.lessons.length} занятий!`);
        
        // Автоматически скрываем сообщение через 3 секунды
        setTimeout(() => setConfirmationMessage(null), 3000);
      } else {
        throw new Error(data.error || 'Не удалось создать занятия');
      }
    } catch (error) {
      console.error('Error creating lessons from template:', error);
      alert('Ошибка при создании занятий из шаблона. Пожалуйста, попробуйте снова.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancelCreate = () => {
    setShowCreateForm(false);
  };

  const handleCancelCreateTemplate = () => {
    setShowCreateTemplateForm(false);
  };

  const handleCancelEdit = () => {
    setEditingLessonId(null);
  };

  const handleCancelEditTemplate = () => {
    setEditingTemplateId(null);
  };

  const handleCancelMultipleLessons = () => {
    setCreatingFromTemplateId(null);
  };

  const handleLessonDetails = (lessonId: string) => {
    setEditingLessonId(lessonId);
  };

  // Отрисовка различных форм
  if (showCreateForm) {
    return (
      <CreateLessonForm 
        onSubmit={handleCreateLesson} 
        onCancel={handleCancelCreate}
      />
    );
  }

  if (showCreateTemplateForm) {
    return (
      <TemplateForm
        onSubmit={handleCreateTemplate}
        onCancel={handleCancelCreateTemplate}
      />
    );
  }

  if (editingLessonId) {
    return (
      <EditLessonForm 
        lessonId={editingLessonId}
        onSubmit={handleUpdateLesson}
        onCancel={handleCancelEdit}
        onDelete={handleDeleteLesson}
        onCancelLesson={handleCancelLesson}
      />
    );
  }

  if (editingTemplateId) {
    return (
      <TemplateForm
        templateId={editingTemplateId}
        onSubmit={(data) => handleUpdateTemplate(editingTemplateId, data)}
        onCancel={handleCancelEditTemplate}
      />
    );
  }

  if (creatingFromTemplateId) {
    return (
      <MultipleLessonsForm
        templateId={creatingFromTemplateId}
        onSubmit={handleCreateMultipleLessons}
        onCancel={handleCancelMultipleLessons}
        onDelete={handleDeleteTemplate}
      />
    );
  }

  return (
    <div className={styles.lessonsContainer}>
      <div className={styles.buttonsContainer}>
        <Button
          state="secondary"
          label="Создать шаблон"
          onClick={() => setShowCreateTemplateForm(true)}
        />
        <Button
          state="active"
          label="Создать занятие"
          onClick={() => setShowCreateForm(true)}
        />
      </div>

      {confirmationMessage && (
        <div className={styles.confirmationMessage}>
          {confirmationMessage}
        </div>
      )}

      <h2 className={styles.sectionTitle}>Ближайшие занятия</h2>
      
      {isLoading ? (
        <div className={styles.loading}>Загрузка занятий...</div>
      ) : error ? (
        <div className={styles.error}>
          {error}
          <Button
            state="secondary"
            label="Попробовать снова"
            onClick={fetchLessons}
            className={styles.retryButton}
          />
        </div>
      ) : scheduledLessons.length === 0 ? (
        <div className={styles.noLessons}>Нет запланированных занятий</div>
      ) : (
        <div className={styles.lessonsScroll}>
          {scheduledLessons.map(lesson => (
            <div key={lesson._id} className={styles.lessonCardWrapper}>
              <LessonCard
                lesson={lesson}
                onButtonClick={() => handleLessonDetails(lesson._id)}
              />
            </div>
          ))}
        </div>
      )}

      {/* Секция отмененных занятий */}
      {cancelledLessons.length > 0 && (
        <>
          <h2 className={styles.sectionTitle}>Отмененные занятия</h2>
          <div className={styles.lessonsScroll}>
            {cancelledLessons.map(lesson => (
              <div key={lesson._id} className={styles.lessonCardWrapper}>
                <LessonCard
                  lesson={lesson}
                  onButtonClick={() => handleLessonDetails(lesson._id)}
                />
              </div>
            ))}
          </div>
        </>
      )}

      {/* Секция шаблонов занятий */}
      <h2 className={styles.sectionTitle}>Шаблоны занятий</h2>
      {isTemplatesLoading ? (
        <div className={styles.loading}>Загрузка шаблонов...</div>
      ) : templatesError ? (
        <div className={styles.error}>
          {templatesError}
          <Button
            state="secondary"
            label="Попробовать снова"
            onClick={fetchTemplates}
            className={styles.retryButton}
          />
        </div>
      ) : templates.length === 0 ? (
        <div className={styles.noLessons}>
          Нет созданных шаблонов. Создайте шаблон для быстрого создания серии занятий.
        </div>
      ) : (
        <div className={styles.lessonsScroll}>
          {templates.map(template => (
            <div key={template._id} className={styles.lessonCardWrapper}>
              <LessonCard
                lesson={{
                  ...template,
                  date: new Date().toISOString(), // Используем текущую дату для отображения
                  studentsIds: {}
                }}
                buttonText="Создать"
                onButtonClick={() => setCreatingFromTemplateId(template._id)}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};