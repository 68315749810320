import { Routes, Route, Navigate } from 'react-router-dom';
import LoginScreen from '../pages/LoginScreen';
import RegistrationScreen from '../pages/RegistrationScreen';
import ProfileScreen from '../pages/ProfileScreen';
import AdminScreen from '../pages/AdminScreen';
import RoomManagementScreen from '../pages/RoomManagementScreen';
import AutomaticBookingScreen from '../pages/AutomaticBookingScreen';
import BookingRequestsScreen from '../pages/BookingRequestsScreen';
import AllBookingsScreen from '../pages/AllBookingsScreen';
import ProtectedLayout from '../layouts/ProtectedLayout';
import AdminLayout from '../layouts/AdminLayout';
import NotFound from '../components/errors/NotFound';
import LandingPage from '../../landing/LandingPage';
import { User } from '../types';
import PinInput from '../components/molecules/PinInput';

interface RoutesProps {
  user: User | null;
  onLogin: (user: User, phone: string) => void;
  onLogout: () => void;
  onRegistrationNeeded: (phone: string) => void;
  currentPhone: string;
  needsPin: boolean;
  tempUser: User | null;
  onPinVerified: () => void;
}

const BookingRoutes: React.FC<RoutesProps> = ({
  user,
  onLogin,
  onLogout,
  onRegistrationNeeded,
  currentPhone,
  needsPin,
  tempUser,
  onPinVerified
}) => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          window.location.hostname === 'centerbryansk.ru' || 
          new URLSearchParams(window.location.search).get('mode') === 'landing' ? (
            <LandingPage />
          ) : (
            <Navigate 
              to={
                user 
                  ? user.role === 'admin' 
                    ? '/admin' 
                    : '/profile'
                  : '/login'
              }
              replace
            />
          )
        }
      />
      <Route
        path="/login"
        element={
          user ? (
            <Navigate to={user.role === 'admin' ? '/admin' : '/profile'} replace />
          ) : needsPin && tempUser ? (
            <div className="auth-card">
              <PinInput 
                onPinSubmit={onPinVerified}
                onPinError={() => {
                  // Можно добавить дополнительную обработку ошибки
                }}
                phone={tempUser.phone}
              />
            </div>
          ) : (
            <LoginScreen 
              onLogin={onLogin} 
              onRegistrationNeeded={onRegistrationNeeded}
              initialPhone={currentPhone}
            />
          )
        }
      />
      <Route
        path="/register"
        element={
          user ? (
            <Navigate to={user.role === 'admin' ? '/admin' : '/profile'} replace />
          ) : (
            <RegistrationScreen
              phone={currentPhone}
              onRegister={(user) => onLogin(user, currentPhone)}
            />
          )
        }
      />
      <Route
        path="/profile"
        element={
          <ProtectedLayout user={user} allowedRoles={['user']}>
            <ProfileScreen user={user!} onLogout={onLogout} />
          </ProtectedLayout>
        }
      />
      <Route
        path="/admin"
        element={
          <ProtectedLayout user={user} allowedRoles={['admin']}>
            <AdminLayout user={user!} onLogout={onLogout}>
              <AdminScreen user={user!} onLogout={onLogout} />
            </AdminLayout>
          </ProtectedLayout>
        }
      >
        <Route path="rooms" element={<RoomManagementScreen />} />
        <Route path="automatic" element={<AutomaticBookingScreen />} />
        <Route path="requests" element={<BookingRequestsScreen user={user!} />} />
        <Route path="all" element={<AllBookingsScreen />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default BookingRoutes;