// src/lessons/components/roles/admin/Lessons/TemplateForm.tsx
import React, { useState, useEffect, useCallback } from 'react';
import { Input } from '../../../../components/atoms/Input/Input';
import { Button } from '../../../../components/atoms/Button/Button';
import { ImageUploader } from '../../../molecules/ImageUploader/ImageUploader';
import styles from './Lessons.module.scss';

interface Teacher {
  _id: string;
  name: string;
  surname: string;
  specializations?: string[];
}

interface Group {
  _id: string;
  name: string;
}

interface TemplateFormData {
  name: string;
  photo: string[];
  description: string;
  groupId?: string;
  ageFrom: string;
  ageTo: string;
  startTimeHours: number;
  startTimeMinutes: number;
  endTimeHours: number;
  endTimeMinutes: number;
  teacherId: string;
  participantsLimit: number;
  price: number;
  teacherPrice?: number;
}

interface TemplateFormProps {
  templateId?: string; // If provided, we're editing an existing template
  onSubmit: (templateData: TemplateFormData) => void;
  onCancel: () => void;
}

export const TemplateForm: React.FC<TemplateFormProps> = ({
  templateId,
  onSubmit,
  onCancel
}) => {
  const [teachers, setTeachers] = useState<Teacher[]>([]);
  const [groups, setGroups] = useState<Group[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingTeachers, setLoadingTeachers] = useState(true);
  const [loadingGroups, setLoadingGroups] = useState(true);
  const [fetchError, setFetchError] = useState<string | null>(null);
  const [errors, setErrors] = useState<Record<string, string | null>>({});
  
  // State for time display
  const [startTimeInput, setStartTimeInput] = useState('');
  const [endTimeInput, setEndTimeInput] = useState('');
  
  const [formData, setFormData] = useState<TemplateFormData>({
    name: '',
    photo: ['https://placehold.co/200x200?text=Нет+фото'], // Default placeholder
    description: '',
    ageFrom: '',
    ageTo: '',
    startTimeHours: 0,
    startTimeMinutes: 0,
    endTimeHours: 0,
    endTimeMinutes: 0,
    teacherId: '',
    participantsLimit: 8, // Default value
    price: 300, // Default price
  });

  // Fetch teachers list
  const fetchTeachers = async () => {
    try {
      setLoadingTeachers(true);
      setFetchError(null);
      
      const response = await fetch('/api/lessons/teachers');
      
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      
      const data = await response.json();
      
      if (data.success) {
        setTeachers(data.teachers);
      } else {
        throw new Error(data.error || 'Не удалось загрузить список преподавателей');
      }
    } catch (error) {
      console.error('Error fetching teachers:', error);
      setFetchError('Не удалось загрузить список преподавателей');
    } finally {
      setLoadingTeachers(false);
    }
  };

  // Fetch groups list
  const fetchGroups = async () => {
    try {
      setLoadingGroups(true);
      
      const response = await fetch('/api/lessons/admin/groups?active=true');
      
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      
      const data = await response.json();
      
      if (data.success) {
        setGroups(data.groups);
      } else {
        throw new Error(data.error || 'Не удалось загрузить группы');
      }
    } catch (error) {
      console.error('Error fetching groups:', error);
    } finally {
      setLoadingGroups(false);
    }
  };

  // Fetch template data if we're editing
  const fetchTemplateData = useCallback(async () => {
    if (!templateId) return;
    
    try {
      setIsLoading(true);
      
      const response = await fetch(`/api/lessons/admin/templates/${templateId}`);
      
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      
      const data = await response.json();
      
      if (data.success) {
        const template = data.template;
        
        // Format time inputs
        setStartTimeInput(`${String(template.startTimeHours).padStart(2, '0')}:${String(template.startTimeMinutes).padStart(2, '0')}`);
        setEndTimeInput(`${String(template.endTimeHours).padStart(2, '0')}:${String(template.endTimeMinutes).padStart(2, '0')}`);
        
        setFormData({
          name: template.name,
          photo: template.photo,
          description: template.description,
          groupId: template.groupId,
          ageFrom: String(template.ageFrom),
          ageTo: String(template.ageTo),
          startTimeHours: template.startTimeHours,
          startTimeMinutes: template.startTimeMinutes,
          endTimeHours: template.endTimeHours,
          endTimeMinutes: template.endTimeMinutes,
          teacherId: template.teacherId,
          participantsLimit: template.participantsLimit,
          price: template.price,
          teacherPrice: template.teacherPrice
        });
      } else {
        throw new Error(data.error || 'Ошибка загрузки данных шаблона');
      }
    } catch (error) {
      console.error('Error fetching template data:', error);
      setFetchError('Не удалось загрузить данные шаблона');
    } finally {
      setIsLoading(false);
    }
  }, [templateId]);

  useEffect(() => {
    fetchTeachers();
    fetchGroups();
    fetchTemplateData();
  }, [fetchTemplateData]);

  // Format time inputs
  const formatTimeInput = (value: string): string => {
    // Remove all non-numeric characters
    const digits = value.replace(/\D/g, '');
    
    // Limit to 4 digits
    const truncated = digits.slice(0, 4);
    
    // If less than or equal to 2 digits, just return
    if (truncated.length <= 2) {
      return truncated;
    }
    
    // Insert colon between hours and minutes
    return `${truncated.slice(0, 2)}:${truncated.slice(2)}`;
  };

  // Handle time input change
  const handleTimeInputChange = (field: 'start' | 'end', value: string) => {
    const formattedValue = formatTimeInput(value);
    
    if (field === 'start') {
      setStartTimeInput(formattedValue);
      
      // Update hours and minutes in form data
      if (formattedValue.includes(':')) {
        const [hours, minutes] = formattedValue.split(':').map(Number);
        setFormData(prev => ({
          ...prev,
          startTimeHours: hours,
          startTimeMinutes: minutes
        }));
      } else if (formattedValue.length > 0) {
        const hours = parseInt(formattedValue);
        setFormData(prev => ({
          ...prev,
          startTimeHours: hours,
          startTimeMinutes: 0
        }));
      }

      if (errors.startTimeHours || errors.startTimeMinutes) {
        setErrors({
          ...errors,
          startTimeHours: null,
          startTimeMinutes: null
        });
      }
    } else {
      setEndTimeInput(formattedValue);
      
      // Update hours and minutes in form data
      if (formattedValue.includes(':')) {
        const [hours, minutes] = formattedValue.split(':').map(Number);
        setFormData(prev => ({
          ...prev,
          endTimeHours: hours,
          endTimeMinutes: minutes
        }));
      } else if (formattedValue.length > 0) {
        const hours = parseInt(formattedValue);
        setFormData(prev => ({
          ...prev,
          endTimeHours: hours,
          endTimeMinutes: 0
        }));
      }

      if (errors.endTimeHours || errors.endTimeMinutes) {
        setErrors({
          ...errors,
          endTimeHours: null,
          endTimeMinutes: null
        });
      }
    }
  };

  // Check if end time is later than start time
  const validateEndTime = (): boolean => {
    const { startTimeHours, startTimeMinutes, endTimeHours, endTimeMinutes } = formData;
    
    const startTotalMinutes = startTimeHours * 60 + startTimeMinutes;
    const endTotalMinutes = endTimeHours * 60 + endTimeMinutes;
    
    // If end time is less than start time, assume the lesson goes to the next day
    if (endTotalMinutes < startTotalMinutes) {
      return true;
    }
    
    return endTotalMinutes > startTotalMinutes;
  };

  const handleInputChange = (field: keyof TemplateFormData, value: any) => {
    setFormData({ ...formData, [field]: value });
    // Clear error when editing a field
    if (errors[field]) {
      setErrors({ ...errors, [field]: null });
    }
  };

  // Handle image upload
  const handleImageUpload = (imageUrl: string) => {
    setFormData({
      ...formData,
      photo: [imageUrl]
    });
  };

  const validateForm = (): boolean => {
    const newErrors: Record<string, string | null> = {};
    
    // Validate name
    if (!formData.name.trim()) {
      newErrors.name = 'Введите название шаблона';
    }
    
    // Validate description
    if (!formData.description.trim()) {
      newErrors.description = 'Введите описание шаблона';
    }
    
    // Validate time
    if (formData.startTimeHours < 0 || formData.startTimeHours > 23) {
      newErrors.startTimeHours = 'Часы должны быть от 0 до 23';
    }
    
    if (formData.startTimeMinutes < 0 || formData.startTimeMinutes > 59) {
      newErrors.startTimeMinutes = 'Минуты должны быть от 0 до 59';
    }
    
    if (formData.endTimeHours < 0 || formData.endTimeHours > 23) {
      newErrors.endTimeHours = 'Часы должны быть от 0 до 23';
    }
    
    if (formData.endTimeMinutes < 0 || formData.endTimeMinutes > 59) {
      newErrors.endTimeMinutes = 'Минуты должны быть от 0 до 59';
    }
    
    if (!validateEndTime()) {
      newErrors.endTimeHours = 'Время окончания должно быть позже времени начала';
    }
    
    // Validate age range
    if (!formData.ageFrom) {
      newErrors.ageFrom = 'Укажите минимальный возраст';
    } else if (isNaN(parseFloat(formData.ageFrom))) {
      newErrors.ageFrom = 'Возраст должен быть числом';
    } else if (parseFloat(formData.ageFrom) < 0) {
      newErrors.ageFrom = 'Возраст не может быть отрицательным';
    }
    
    if (!formData.ageTo) {
      newErrors.ageTo = 'Укажите максимальный возраст';
    } else if (isNaN(parseFloat(formData.ageTo))) {
      newErrors.ageTo = 'Возраст должен быть числом';
    } else if (parseFloat(formData.ageTo) < parseFloat(formData.ageFrom)) {
      newErrors.ageTo = 'Максимальный возраст должен быть больше минимального';
    }
    
    // Validate teacher
    if (!formData.teacherId) {
      newErrors.teacherId = 'Выберите преподавателя';
    }
    
    // Validate participants limit
    if (formData.participantsLimit <= 0) {
      newErrors.participantsLimit = 'Укажите лимит участников';
    }
    
    // Validate price
    if (formData.price <= 0) {
      newErrors.price = 'Укажите цену занятия';
    }
    
    setErrors(newErrors);
    return !Object.values(newErrors).some(error => error !== null);
  };

  const handleSubmit = () => {
    if (!validateForm()) {
      return;
    }
    
    setIsLoading(true);
    onSubmit(formData);
  };

  const pageTitle = templateId ? 'Редактирование шаблона занятий' : 'Создание шаблона занятий';

  return (
    <div className={styles.createLessonForm}>
      <h2 className={styles.formTitle}>{pageTitle}</h2>
      
      {fetchError && (
        <div className={styles.formError}>
          {fetchError}
          <Button
            state="secondary"
            label="Обновить страницу"
            onClick={() => window.location.reload()}
            className={styles.refreshButton}
          />
        </div>
      )}
      
      <div className={styles.formMainSection}>
        <div className={styles.formRow}>
          <ImageUploader
            onImageUpload={handleImageUpload}
            currentImage={formData.photo[0]}
            className={styles.imageUploader}
          />
        </div>
        
        <div className={styles.formColumn}>
          <h3 className={styles.sectionTitle}>Основная информация</h3>
          
          <div className={styles.formRow}>
            <div className={styles.formColumn}>
              <Input
                type="text"
                value={formData.name}
                onChange={(value) => handleInputChange('name', value)}
                label="Название шаблона"
                upperLabel="Название шаблона"
                state={errors.name ? 'error' : 'inactive'}
                error={errors.name}
                disabled={isLoading}
              />
            </div>
          </div>

          <div className={styles.formRow}>
            <div className={styles.formColumn}>
              <textarea
                className={styles.textarea}
                value={formData.description}
                onChange={(e) => handleInputChange('description', e.target.value)}
                placeholder="Описание шаблона"
                disabled={isLoading}
              />
              {errors.description && (
                <div className={styles.errorMessage}>{errors.description}</div>
              )}
            </div>
          </div>
        </div>
      </div>
      
      <div className={styles.formMainSection}>
        <div className={styles.formHalfColumn}>
          <h3 className={styles.sectionTitle}>Параметры занятия</h3>
          
          <div className={styles.formRow}>
            <div className={styles.formColumn}>
              <Input
                type="text"
                value={startTimeInput}
                onChange={(value) => handleTimeInputChange('start', value)}
                label="Начало (ЧЧ:ММ)"
                upperLabel="Время начала"
                state={errors.startTimeHours || errors.startTimeMinutes ? 'error' : 'inactive'}
                error={errors.startTimeHours || errors.startTimeMinutes}
                disabled={isLoading}
              />
            </div>
            <div className={styles.formColumn}>
              <Input
                type="text"
                value={endTimeInput}
                onChange={(value) => handleTimeInputChange('end', value)}
                label="Конец (ЧЧ:ММ)"
                upperLabel="Время окончания"
                state={errors.endTimeHours || errors.endTimeMinutes ? 'error' : 'inactive'}
                error={errors.endTimeHours || errors.endTimeMinutes}
                disabled={isLoading}
              />
            </div>
          </div>

          <div className={styles.formRow}>
            <div className={styles.formColumn}>
              <Input
                type="text"
                value={formData.ageFrom}
                onChange={(value) => handleInputChange('ageFrom', value)}
                label="Возраст от"
                upperLabel="Возраст от"
                state={errors.ageFrom ? 'error' : 'inactive'}
                error={errors.ageFrom}
                disabled={isLoading}
              />
            </div>
            <div className={styles.formColumn}>
              <Input
                type="text"
                value={formData.ageTo}
                onChange={(value) => handleInputChange('ageTo', value)}
                label="Возраст до"
                upperLabel="Возраст до"
                state={errors.ageTo ? 'error' : 'inactive'}
                error={errors.ageTo}
                disabled={isLoading}
              />
            </div>
          </div>
        </div>

        <div className={styles.formHalfColumn}>
          <h3 className={styles.sectionTitle}>Организация</h3>
          
          <div className={styles.formRow}>
            <div className={styles.formColumn}>
              <label className={styles.selectLabel}>Группа (необязательно)</label>
              <select
                className={styles.select}
                value={formData.groupId || ''}
                onChange={(e) => handleInputChange('groupId', e.target.value)}
                disabled={isLoading || loadingGroups}
              >
                <option value="">
                  {loadingGroups ? 'Загрузка групп...' : 'Выберите группу'}
                </option>
                {groups.map((group) => (
                  <option key={group._id} value={group._id}>
                    {group.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          
          <div className={styles.formRow}>
            <div className={styles.formColumn}>
              <label className={styles.selectLabel}>Преподаватель</label>
              <select
                className={styles.select}
                value={formData.teacherId}
                onChange={(e) => handleInputChange('teacherId', e.target.value)}
                disabled={isLoading || loadingTeachers}
              >
                <option value="">
                  {loadingTeachers ? 'Загрузка преподавателей...' : 'Выберите преподавателя'}
                </option>
                {teachers.map((teacher) => (
                  <option key={teacher._id} value={teacher._id}>
                    {teacher.surname} {teacher.name} {teacher.specializations?.length ? `(${teacher.specializations.join(', ')})` : ''}
                  </option>
                ))}
              </select>
              {errors.teacherId && (
                <div className={styles.errorMessage}>{errors.teacherId}</div>
              )}
            </div>
          </div>

          <div className={styles.formRow}>
            <div className={styles.formColumn}>
              <Input
                type="text"
                value={formData.participantsLimit.toString()}
                onChange={(value) => handleInputChange('participantsLimit', parseInt(value) || 0)}
                label="Лимит участников"
                upperLabel="Лимит участников"
                state={errors.participantsLimit ? 'error' : 'inactive'}
                error={errors.participantsLimit}
                disabled={isLoading}
              />
            </div>
            <div className={styles.formColumn}>
              <Input
                type="text"
                value={formData.price.toString()}
                onChange={(value) => handleInputChange('price', parseInt(value) || 0)}
                label="Цена занятия (руб.)"
                upperLabel="Цена занятия"
                state={errors.price ? 'error' : 'inactive'}
                error={errors.price}
                disabled={isLoading}
              />
            </div>
          </div>

          <div className={styles.formRow}>
            <div className={styles.formColumn}>
              <Input
                type="text"
                value={formData.teacherPrice?.toString() || ''}
                onChange={(value) => handleInputChange('teacherPrice', parseInt(value) || 0)}
                label="Выплата преподавателю (руб.)"
                upperLabel="Выплата преподавателю"
                disabled={isLoading}
              />
            </div>
          </div>
        </div>
      </div>

      <div className={styles.formButtons}>
        <Button
          state="secondary"
          label="Отменить"
          onClick={onCancel}
          disabled={isLoading}
        />
        <Button
          state="active"
          label={isLoading ? "Сохранение..." : "Сохранить"}
          onClick={handleSubmit}
          disabled={isLoading}
        />
      </div>
    </div>
  );
};